import {UserPermissions} from '@modules/permissions';
import {SeazoneIcon} from '@modules/seazone-icons';
import {translate} from '@ngneat/transloco';

import {
  BoatEquipmentMileageDateColor,
  BoatEquipmentMileageDateItem,
  BoatSystemsFilterFilters,
  BoatSystemsFilters,
  BoatSystemsFilterSystemType,
  EquipmentSort,
  EquipmentSortItem,
  WarrantyStatusCode,
  TaskStatusType,
} from '@models/boat-maintenance';
import {EquipmentLevel} from '@models/boat-settings';
import {BasicNavItem} from '@models/general';

export const BOATS_FLEET_EQUIPMENT_FILTERS: BoatSystemsFilterFilters[] = [
  {id: BoatSystemsFilters.UnderWarranty, name: 'underWarranty'},
  {id: BoatSystemsFilters.NoWarranty, name: 'noWarranty'},
  {id: BoatSystemsFilters.RunningHours, name: 'runningHours'},
  // {id: BoatSystemsFilters.RunningMiles, name: 'runningMiles'},
  {id: BoatSystemsFilters.Hidden, name: 'hidden'},
  {id: BoatSystemsFilters.NotUpdated, name: 'notUpdated'},
  {id: BoatSystemsFilters.NotUpdatedMonth, name: 'notUpdatedMonth'},
];

export const BOATS_FLEET_EQUIPMENT_SYSTEM_TYPES: BoatSystemsFilterSystemType[] = [
  {id: EquipmentLevel.GROUP, name: 'groups'},
  {id: EquipmentLevel.SYSTEM, name: 'systems'},
  {id: EquipmentLevel.SUB_SYSTEM, name: 'subSystems'},
  {id: EquipmentLevel.PART, name: 'parts'},
];

export const BOATS_FLEET_EQUIPMENT_SEARCH_TYPES: BoatSystemsFilterSystemType[] = [
  {id: EquipmentLevel.ALL, name: 'all'},
  {id: EquipmentLevel.GROUP, name: 'groups'},
  {id: EquipmentLevel.SYSTEM, name: 'systems'},
  {id: EquipmentLevel.SUB_SYSTEM, name: 'subSystems'},
  {id: EquipmentLevel.PART, name: 'parts'},
];

export const BOAT_EQUIPMENT_SORT: EquipmentSortItem[] = [
  {id: EquipmentSort.NameAsc, name: 'sort.nameAsc'},
  {id: EquipmentSort.NameDesc, name: 'sort.nameDesc'},
  {id: EquipmentSort.DateDesc, name: 'sort.dateDesc'},
  {id: EquipmentSort.DateAsc, name: 'sort.dateAsc'},
];

export const BOAT_EQUIPMENT_DEFAULT_SORT = EquipmentSort.NameAsc;

export enum BoatMaintenanceRoutes {
  Systems = 'systems',
  Mileage = 'mileage',
  SystemsSettings = 'system-settings',
  DefaultAssigneeSettings = 'default-assignee',
  SystemsSettingsSystems = 'systems',
  Tasks = 'tasks',
  TasksLog = 'tasks-log',
  TasksLogInfo = 'tasks-log-info',
  Dashboard = 'dashboard',
  WorkList = 'work-list',
  Inventory = 'inventory',
  InventorySettings = 'inventory-settings',
  InventorySettingsLocation = 'location',
  InventorySettingsCategories = 'categories',
  CheckLists = 'checklists',
}

export enum BoatMaintenanceChecklistsRoutes {
  Checklists = 'checklists',
  Templates = 'templates',
}

export enum BoatMaintenanceSystemItemRoutes {
  List = 'list',
  Notes = 'notes',
  Tasks = 'tasks',
}

export const BOAT_EQUIPMENT_MILEAGE_DATE_COLORS: BoatEquipmentMileageDateItem[] = [
  {value: BoatEquipmentMileageDateColor.Green, cssClass: 'seaz-success'},
  {value: BoatEquipmentMileageDateColor.Black, cssClass: 'seaz-text'},
  {value: BoatEquipmentMileageDateColor.Red, cssClass: 'seaz-alarm'},
];

export class BoatMaintenanceNavItems extends BasicNavItem {
  constructor(
    public readonly url: string,
    public readonly title: string,
    public readonly permissions: UserPermissions | UserPermissions[] | null = null,
    public readonly queryParams: object | null = null,
  ) {
    super(url, title);
  }
}

const getSystemsDefaultNavItems = (taskCount: number): BoatMaintenanceNavItems[] => [
  new BoatMaintenanceNavItems(
    BoatMaintenanceSystemItemRoutes.Tasks,
    translate('maintenance.tasksCount', {value: taskCount}),
    ['perm_boat_task_see_and_manage_all_tasks', 'perm_boat_task_see_and_manage_assigned_tasks'],
    {statuses: TaskStatusType.Open},
  ),
  new BoatMaintenanceNavItems(BoatMaintenanceSystemItemRoutes.Notes, translate('maintenance.notes.title')),
];

export function getSystemsNavItems(level: EquipmentLevel, tasksCount = 0): BoatMaintenanceNavItems[] | null {
  const defaultNavItems = getSystemsDefaultNavItems(tasksCount);
  if (level === EquipmentLevel.GROUP) {
    return [
      new BoatMaintenanceNavItems(BoatMaintenanceSystemItemRoutes.List, translate('maintenance.systems')),
      ...defaultNavItems,
    ];
  }
  if (level === EquipmentLevel.SYSTEM) {
    return [
      new BoatMaintenanceNavItems(BoatMaintenanceSystemItemRoutes.List, translate('maintenance.subSystems')),
      ...defaultNavItems,
    ];
  }
  if (level === EquipmentLevel.SUB_SYSTEM) {
    return [
      new BoatMaintenanceNavItems(BoatMaintenanceSystemItemRoutes.List, translate('maintenance.parts')),
      ...defaultNavItems,
    ];
  }
  if (level === EquipmentLevel.PART) {
    return defaultNavItems;
  }
  return null;
}

export const getSystemWarrantyIcon = (warrantyStatus: WarrantyStatusCode | null): SeazoneIcon | null => {
  if (!warrantyStatus) {
    return null;
  }
  return warrantyStatus === WarrantyStatusCode.Ok || warrantyStatus === WarrantyStatusCode.Warning ?
    'protected' : null;
};
