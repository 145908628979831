import {Params} from '@angular/router';

import {cleanObject} from '@helpers/clean-object';

import {CurrencyDto} from '../directories';
import {PaginationDto} from '../response';

export enum InvitationAcceptType {
  Accept = 'accept',
  Reject = 'reject'
}

export enum FleetBoatLengthType {
  Less17 = 1,
  From17to24,
  From25to34,
  From35to44,
  From45to54,
  More55
}

export class FleetsListDto {
  constructor(
    public readonly models: FleetsListModelsDto[] | null,
    public readonly pagination: PaginationDto,
  ) {
  }
}

export class FleetsListFilter {

  get params(): Params {
    const page = this.page;
    return cleanObject({page}, true);
  }

  constructor(
    public page: number = 1,
    public search: string = '',
  ) {
  }
}

export interface FleetsListModelsDto {
  readonly fleet: FleetDto;
  readonly boats: FleetBoatShortItemDto[];
  readonly users: UsersDto[];
  readonly default: boolean;
  readonly invitation: number | null;
}

export class FleetsListsModel<T> {
  constructor(
    public list: T[],
    public listLength: number,
  ) {
  }
}

export interface FleetDto {
  readonly id: number;
  readonly currency: CurrencyDto;
  readonly name: string;
  readonly description: string;
  readonly image: string;
}

export interface FleetBoatShortItemDto {
  readonly id: number;
  readonly name: string;
  readonly image: string;
}

export interface UsersDto {
  readonly id: number;
  readonly username: string;
  readonly image: string;
}

export interface FleetInvitationStatusPayload {
  readonly type: InvitationAcceptType;
  readonly invitationId: number;
}

export interface FleetShortItemDto {
  readonly id: number;
  readonly name: string;
  readonly image: string;
  readonly currency: CurrencyDto;
}

export interface FleetItemSelectDto extends FleetShortItemDto{
  readonly displayValue: string;
}
