import {inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import {PermissionsService} from '@modules/permissions';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

import {AppRouterService} from '@core/services';

@Injectable()
export class PermissionGuard  {

  private readonly permissionsService = inject(PermissionsService);
  private readonly appRouterService = inject(AppRouterService);

  constructor() {
  }

  canActivate(
    snapshot: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const {data} = snapshot;
    const permission = data && data.permission;
    const id = this.getBoatId(snapshot);
    if (!id || !permission) {
      return true;
    }
    return this.permissionsService.checkBoatPermissions(id, permission).pipe(
      tap(isHavePermissions => isHavePermissions ? true : this.appRouterService.navigateToBoatsList()),
    );
  }

  private getBoatId(snapshot: ActivatedRouteSnapshot): number | null {
    const parent = snapshot.parent;
    if (!parent) {
      return null;
    }
    const params = parent.params;
    const id = params && params.boatId;
    return !!id && Number(id) ? +id : this.getBoatId(parent);
  }

}
