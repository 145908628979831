import {Inject, Injectable} from '@angular/core';
import {UserPermissions} from '@modules/permissions';
import {Observable, of, Subject} from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs/operators';

import {AppStateService, UniversalStorageService} from '@core/modules';
import {PermissionsDto} from '@models/permissions';
import {UserService} from '@services/user/user.service';

@Injectable()
export class PermissionsService {

  private loadPermissions$: Subject<number[]>;
  private readonly permissionsStateKey = '_boatPermissions';
  private readonly isBrowser = this.appStateService.isBrowser;

  boatsPermissions$: Observable<PermissionsDto[]>;

  constructor(
    private readonly userService: UserService,
    @Inject(UniversalStorageService) private readonly universalStorageService: UniversalStorageService,
    private readonly appStateService: AppStateService,
  ) {
  }

  init(): void {
    this.loadPermissions$ = new Subject<number[]>();
    this.getPermissions();
  }

  destroy(): void {
    this.loadPermissions$.complete();
  }

  onLoadPermissions(boats: number[]): void {
    if (this.loadPermissions$.isStopped || !boats?.length) {
      return;
    }
    this.loadPermissions$.next(boats);
  }

  private boatPermissions(boatId: number): PermissionsDto | null {
    const permissions = this.appStateService.permissions;
    if (!permissions || permissions.length < 1) {
      return null;
    }
    return permissions.find(permission => permission.boatId === boatId) || null;
  }

  isAllPermissionsLoaded(boats: number[]): Observable<boolean> {
    return this.appStateService.permissions$.pipe(
      map(permissions =>
        boats.reduce((_, val) =>
          permissions.findIndex(({boatId}) => boatId === val) > -1
        , true),
      ),
    );
  }

  setPermissionsInStorage(boatId: number): void {
    if (!this.isBrowser) {
      return;
    }
    const permissions = this.boatPermissions(boatId);
    this.universalStorageService.setItem(this.permissionsStateKey, JSON.stringify(permissions));
  }

  getBoatPermissions(boatId: number): UserPermissions[] {
    const boatPermissions = this.isBrowser ? this.boatPermissions(boatId) : this.getPermissionsFromStorage();
    if (!boatPermissions || boatPermissions.boatId !== boatId) {
      return [];
    }
    return boatPermissions.permissions;
  }

  private getPermissionsFromStorage(): PermissionsDto | null {
    const item = this.universalStorageService.getItem(this.permissionsStateKey);
    const permissions = item && JSON.parse(item);
    return permissions || null;
  }

  checkPermissions(boatId: number, userPermissions: UserPermissions[] | UserPermissions): boolean {
    const permissions = this.getBoatPermissions(boatId);
    return this.isHavePermission(permissions, userPermissions);
  }

  checkBoatPermissions(boatId: number, userPermissions: UserPermissions[] | UserPermissions): Observable<boolean> {
    const permissions = this.getBoatPermissions(boatId);
    if (permissions.length) {
      return of(this.isHavePermission(permissions, userPermissions));
    }
    return this.userService.loadBoatsPermissions({boats: [boatId]}).pipe(
      map(boatsPermissions => {
        const boatPermissions = boatsPermissions.find(({boatId: id}) => id === boatId);
        if (!boatPermissions) {
          return false;
        }
        this.updatePermissionsState(boatsPermissions);
        return this.isHavePermission(boatPermissions.permissions, userPermissions);
      }),
    );
  }

  isHavePermission(boatPermissions: UserPermissions[], userPermissions: UserPermissions[] | UserPermissions): boolean {
    if (Array.isArray(userPermissions)) {
      return boatPermissions.some(permission => userPermissions.includes(permission));
    }
    return boatPermissions.indexOf(userPermissions as UserPermissions) >= 0;
  }

  private getPermissions(): void {
    this.boatsPermissions$ = this.loadPermissions$.pipe(
      distinctUntilChanged(),
      filter(boats => !!boats.length),
      switchMap(boats => this.userService.loadBoatsPermissions({boats})),
      tap(permissions => this.updatePermissionsState(permissions)),
      shareReplay(1),
    );
  }

  private updatePermissionsState(boatPermissions: PermissionsDto[]): void {
    const permissions = [...this.appStateService.permissions || []];
    boatPermissions.forEach(permission => {
      const permissionIndex = permissions.findIndex(({boatId}) => boatId === permission.boatId);
      if (permissionIndex > -1) {
        return permissions[permissionIndex] = permission;
      }
      return permissions.push(permission);
    });
    this.appStateService.permissions = permissions;
  }
}
