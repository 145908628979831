export interface ServerError {
  // @ts-ignore
  statusOptions?: StatusOptions | NotFoundStatusOptions | BadRequestStatusOptions;
  // @ts-ignore
  payloadData?: {[key: string]: any};

  [key: string]: string;
}

export enum StatusOptions {
  ACCESS_DENIED,
  USER_BANNED,
  USER_DELETED,
  EMAIL_VERIFICATION,
  PHONE_VERIFICATION,
  NEED_CHANGE_PASSWORD,
  USER_FORCED_LOGOUT,
  BOAT_PERMISSION,
  NEED_PAY_FOR_USER,
  UNPAID_BOAT_SUBSCIPTION,
  BOAT_WITHOUT_SUBSCIPTION,
  UNPAID_TRIAL_SUBSCRIPTION,
  JUMP_TO_TOP_EXPIRE,
  SHARE_JOB_OFFER_EXPIRED,
  SHARE_CV_EXPIRED,
  USER_COMPANY_NOT_VERIFIED,
  CREW_AGENCY_ACCESS_DENIED,
  EMAIL_ALREADY_USED,
  FLEET_PERMISSION,
  SHARED_APA_REPORT_EXPIRED,
  FLEET_GUEST_DELETE_WARNING,
}

export enum NotFoundStatusOptions {
  INVALID_PROMOCODE = 1,
  STATUS_OPTION_JOB_OFFER_UNPUBLISHED,
  CANNOT_GET_LOCATION_DATA,
}

export class ErrorDto {
  showModal = false;

  constructor(
    public readonly success: boolean,
    public readonly message: string,
    public readonly errors: ServerError,
  ) {
  }
}

export enum BadRequestStatusOptions {
  FormError = 1,
  STATUS_OPTION_SHOW_ERROR_IN_MODAL,
  CANNOT_GET_LOCATION_DATA,
  INVALID_REPEAT_FORM,
}
