import {Injectable} from '@angular/core';
import {ParamMap} from '@angular/router';
import {loader} from '@modules/custom-loader/models/decorators';
import {PermissionsService, UserPermissions} from '@modules/permissions';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  merge,
  Observable,
  of,
  Subject,
} from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  startWith,
  switchMap,
  tap,
  throttleTime,
} from 'rxjs/operators';

import {AppStateService} from '@core/modules';
import {isCompanyCrewAgency} from '@helpers/is-company-crew-agency';
import {CompanyDto} from '@models/auth';
import {
  BoatActivityType,
  BoatAisNotifyDto,
  BoatExpenseDto,
  BoatShortInfoCharterListDto,
  BoatShortInfoDto,
  BoatTimezoneDto,
} from '@models/boat';
import {BoatUser} from '@models/boat-settings';
import {BankAccountStatementsItem, CreditCardStatementsItem} from '@models/credit-cards/credit-cards';
import {AppChartType} from '@models/dashboard';
import {CurrencyDto} from '@models/directories/currency.model';
import {
  CrewStatuses,
  ExpenseCategoryDto,
  ExpenseCategoryType,
  ExpenseCrewDto,
  ExpenseDepartmentDto,
} from '@models/expense';
import {FinancialDataByChartDto, FinancialDataDto, FinancialDataRouteParams} from '@models/financial-data';
import {ReportViewType} from '@models/financial-report';
import {HeaderNavItem} from '@models/general';
import {ResponseDto} from '@models/response';
import {SortDirection, SortItem} from '@models/shared';
import {BoatService} from '@services/boat/boat.service';
import {FinancialDataService} from '@services/financial-data/financial-data.service';
import {BOAT_CREW_AGENCY_NAV_ITEMS, BOAT_NAV_ITEMS} from '@static/boat-nav-items';

import {BoatSettingsService} from '../boat-settings/boat-settings.service';
import {FinancialReportService} from '../financial-report/financial-report.service';
import {UserService} from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class BoatDetailsService {
  boatDateUpdate: number | null = null;
  private boatDetailsId$: BehaviorSubject<number | null>;
  private updateBoatShortInfo$: Subject<void>;
  boatId$: Observable<number>;
  boatShortInfo$: Observable<BoatShortInfoDto>;
  boatActivityType$: Observable<BoatActivityType>;
  categories$: Observable<ExpenseCategoryDto[]>;
  fullCategories$: Observable<ExpenseCategoryDto[]>;
  charterApaCategories$: Observable<ExpenseCategoryDto[]>;
  crewList$: Observable<ExpenseCrewDto[]>;
  myCrewList$: Observable<ExpenseCrewDto[]>;
  defaultCreatorAssignee$: Observable<boolean>;
  departments$: Observable<ExpenseDepartmentDto[]>;
  currency$: Observable<CurrencyDto[]>;
  creditCards$: Observable<CreditCardStatementsItem[]>;
  permissions$: Observable<UserPermissions[]>;
  boatCurrency$: Observable<CurrencyDto | null>;
  expenses$: Observable<BoatExpenseDto | null>;
  supplierEnabled$: Observable<boolean>;
  isCrewAgency$: Observable<boolean>;
  boatNavItems$: Observable<HeaderNavItem[]>;
  isBoatAdmin$: Observable<boolean>;
  boatUsers$: Observable<BoatUser[]>;
  boatCompany$: Observable<CompanyDto | null>;
  charterList$: Observable<BoatShortInfoCharterListDto>;
  boatTimeZone$: Observable<BoatTimezoneDto>;
  bankAccounts$: Observable<BankAccountStatementsItem[]>;

  private boatDetailsId: number | null;

  get boatId(): number | null {
    return this.boatDetailsId;
  }

  set boatId(id: number | null) {
    this.boatDetailsId = id;
  }

  constructor(
    private readonly boatsService: BoatService,
    private readonly financialDataService: FinancialDataService,
    private readonly userService: UserService,
    private readonly appStateService: AppStateService,
    private readonly permissionsService: PermissionsService,
    private readonly financialReportService: FinancialReportService,
    private readonly boatSettingService: BoatSettingsService,
  ) {
  }

  init(): void {
    this.boatDetailsId$ = new BehaviorSubject<number | null>(null);
    this.updateBoatShortInfo$ = new Subject<void>();

    this.boatId$ = this.getBoatId();
    this.boatShortInfo$ = this.getBoatShortInfo();
    this.boatActivityType$ = this.boatShortInfo$.pipe(map(data => data?.boatActivityType));
    this.fullCategories$ = this.boatShortInfo$.pipe(map(data => data.category));
    this.categories$ = this.getBoatCategories();
    this.charterApaCategories$ = this.getCharterApaCategories();
    this.crewList$ = this.boatShortInfo$.pipe(map(data => data.myCrew));
    this.myCrewList$ = this.boatShortInfo$.pipe(map(data => data.crewList));
    this.defaultCreatorAssignee$ = this.boatShortInfo$.pipe(map(data => data.defaultBoatTaskAssigneeCreator));
    this.departments$ = this.boatShortInfo$.pipe(map(data => data.department));
    this.currency$ = this.getCurrencyList();
    this.boatCurrency$ = this.getBoatCurrency();
    this.creditCards$ = this.boatShortInfo$.pipe(
      map(data => data?.creditCardStatements?.list || []),
    );
    this.permissions$ = this.getBoatPermissions();
    this.expenses$ = this.boatShortInfo$.pipe(map(data => data.expenses));
    this.supplierEnabled$ = this.boatShortInfo$.pipe(
      map(data => data.supplierEnabled),
    );
    this.boatCompany$ = this.boatShortInfo$.pipe(map(info => info.company));
    this.isCrewAgency$ = this.getIsCrewAgency();
    this.boatNavItems$ = this.isCrewAgency$.pipe(
      map(isCrewAgency => this.getBoatNavItems(isCrewAgency)),
    );
    this.boatUsers$ = this.getBoatUsers();
    this.isBoatAdmin$ = this.isBoatAdmin();
    this.charterList$ = this.boatShortInfo$.pipe(map(data => data.charterList));
    this.boatTimeZone$ = this.boatShortInfo$.pipe(map(info => info.timezone));
    this.bankAccounts$ = this.boatShortInfo$.pipe(
      map(info => info.bankAccountStatements?.list || []),
      map(list => ([{
        id: null,
        name: ' ',
        status: true,
        value: null,
        currency: null,
        color: null,
      }, ...list])),
    );
  }

  setBoatId(boatId: number | null): void {
    if (this.boatDetailsId$.isStopped) {
      return;
    }
    this.boatDetailsId$.next(boatId);
  }

  financialDataByChart(
    params: FinancialDataRouteParams,
  ): Observable<FinancialDataDto> {
    return this.boatId$.pipe(
      switchMap(id => {
        const {
          id: payloadId,
          chartType,
          page,
          limit,
          sortBy,
          sortDir,
          month,
          year,
          currencyId,
          charterId,
          reportViewType,
        } = params;
        const payload = new FinancialDataByChartDto(
          id,
          chartType,
          payloadId,
          page,
          limit,
          sortBy,
          sortDir,
          year,
          month,
          currencyId,
          charterId,
        );
        return reportViewType === ReportViewType.FullView
          ? this.financialReportService.financeDataByChart(payload)
          : this.financialDataService.getFinancialDataByChart(payload);
      }),
    );
  }

  getFinancialChartRouteParams(
    params: ParamMap,
    defaultPageSize: number,
    defaultSort: SortItem,
  ): FinancialDataRouteParams {
    const chartType = params.get('chartType');
    if (!chartType) {
      throw new Error('No chart type');
    }

    const id = params.get('id');
    if (!id && +chartType !== AppChartType.ALL) {
      throw new Error('No id for chart');
    }

    const reportViewType = params.get('viewType') as ReportViewType;
    if (!reportViewType) {
      throw new Error('No view type for chart');
    }

    const page = params.get('page') || 1;
    const show = params.get('show');
    const month = params.get('month') || null;
    const year = params.get('year') || null;
    const currencyId = params.get('currencyId') || null;
    const charterId = params.get('charterId') || null;
    const limit = (show && +show) || defaultPageSize;
    const {name, direction} = defaultSort;
    const sortBy = params.get('sortBy') || name;
    const sortDir = (params.get('sortDir') as SortDirection) || direction;
    return new FinancialDataRouteParams(
      id,
      +chartType,
      reportViewType,
      page,
      limit,
      sortBy,
      sortDir,
      year,
      month,
      currencyId,
      charterId,
    );
  }

  uploadExpenseFile(
    file: File,
    charterId: number | null,
  ): Observable<ResponseDto> {
    return this.boatId$.pipe(
      switchMap(id => this.boatsService.uploadExpenseFile(id, file, charterId)),
    );
  }

  onUpdateBoatShortInfo(): void {
    if (this.updateBoatShortInfo$.isStopped) {
      return;
    }
    this.updateBoatShortInfo$.next();
  }

  @loader()
  onAffiliateBoat(companyId: number): Observable<ResponseDto> {
    return this.boatId$.pipe(
      switchMap(id => this.boatsService.affiliateBoat(companyId, id)),
    );
  }

  @loader()
  onUnaffiliateBoat(companyId: number): Observable<ResponseDto> {
    return this.boatId$.pipe(
      switchMap(id => this.boatsService.unaffiliateBoat(companyId, id)),
    );
  }

  destroySubscriptions(): void {
    this.boatDetailsId$.complete();
    this.updateBoatShortInfo$.complete();
    this.boatDateUpdate = null;
  }

  getBoatNavItems(isCrewAgency: boolean): HeaderNavItem[] {
    return isCrewAgency ? BOAT_CREW_AGENCY_NAV_ITEMS : BOAT_NAV_ITEMS;
  }

  private getBoatUsers(): Observable<BoatUser[]> {
    return this.boatId$.pipe(
      switchMap(boatId => this.boatSettingService.getBoatUsers(boatId)),
    );
  }

  private isBoatAdmin(): Observable<boolean> {
    return this.boatShortInfo$.pipe(
      map(info => info.isBoatAdmin),
      shareReplay(1),
    );
  }

  private getIsCrewAgency(): Observable<boolean> {
    return combineLatest([
      this.boatCompany$,
      this.appStateService.userCompany$,
    ]).pipe(
      map(([boatCompany, userCompany]) =>
        this.isBoatOrUserCompanyAgency(boatCompany, userCompany),
      ),
      shareReplay(1),
    );
  }

  isBoatOrUserCompanyAgency(
    boatCompany: CompanyDto | null,
    userCompany: CompanyDto | null,
  ): boolean {
    return isCompanyCrewAgency(boatCompany) || isCompanyCrewAgency(userCompany);
  }

  getBoatCrewListByPermissions(
    userPermissions: UserPermissions[] | null = null,
    statuses: CrewStatuses[] = [CrewStatuses.STATUS_ON_BOAT],
  ): Observable<ExpenseCrewDto[]> {
    return combineLatest([
      this.crewList$.pipe(
        map(data =>
          data.filter(item => statuses.some(st => item.status === st)),
        ),
      ),
      this.boatId$.pipe(
        map(boatId => {
          if (!userPermissions) {
            return true;
          }
          return this.permissionsService.checkPermissions(
            boatId,
            userPermissions,
          );
        }),
      ),
    ]).pipe(
      map(([crew, canSeeCrewList]) => {
        if (canSeeCrewList) {
          return crew;
        }
        const userId = this.appStateService.user?.id;
        return crew.filter(c => c.userId === userId);
      }),
    );
  }

  canUserChangeBoatLocation(): Observable<boolean> {
    const boatId = this.boatId;
    if (!boatId) {
      return EMPTY;
    }
    const permission: UserPermissions = 'perm_fin_edit_boat_profile';
    const hasPermissions = this.permissionsService.checkPermissions(
      boatId,
      permission,
    );
    if (!hasPermissions) {
      return of(false);
    }
    return this.boatsService
      .canUserChangeBoatLocation(boatId)
      .pipe(map(data => data.canChange));
  }

  private getBoatId(): Observable<number> {
    return this.boatDetailsId$.asObservable().pipe(
      distinctUntilChanged(),
      filter(boatId => !!boatId),
      tap((boatId: number) => {
        this.permissionsService.setPermissionsInStorage(boatId);
      }),
    );
  }

  private getBoatShortInfo(): Observable<BoatShortInfoDto> {
    return combineLatest([
      this.boatId$,
      this.updateBoatShortInfo$.pipe(startWith({}), throttleTime(1000)),
    ]).pipe(
      switchMap(([id]) => this.boatsService.getBoatShortInfo(id)),
      tap(data => {
        this.boatDateUpdate = data.boatDateUpdate;
      }),
      shareReplay(1),
    );
  }

  private getBoatPermissions(): Observable<UserPermissions[]> {
    return merge(
      this.boatId$,
      this.permissionsService.boatsPermissions$.pipe(
        switchMap(() => this.boatId$),
      ),
    ).pipe(
      map(id => this.permissionsService.getBoatPermissions(id)),
      shareReplay(1),
    );
  }

  private getBoatCurrency(): Observable<CurrencyDto | null> {
    return this.currency$.pipe(
      map(list => list.find(currency => currency.general) || null),
    );
  }

  private getCurrencyList(): Observable<CurrencyDto[]> {
    return this.boatShortInfo$.pipe(
      map(data => data.currency),
      shareReplay(1),
    );
  }

  private getBoatCategories(): Observable<ExpenseCategoryDto[]> {
    return this.fullCategories$.pipe(
      map(data =>
        data.filter(
          cat =>
            cat.categoryType === ExpenseCategoryType.Common ||
            cat.categoryType === ExpenseCategoryType.Charter,
        ),
      ),
    );
  }

  private getCharterApaCategories(): Observable<ExpenseCategoryDto[]> {
    return this.fullCategories$.pipe(
      map(data =>
        data.filter(cat => cat.categoryType === ExpenseCategoryType.CharterApa),
      ),
    );
  }

  getNotifyAisModal(): Observable<BoatAisNotifyDto> {
    return this.boatId$.pipe(
      filter(Boolean),
      distinctUntilChanged(),
      switchMap(boatId => combineLatest([this.permissions$, of(boatId)])),
      filter(([permissions]) => permissions.includes('perm_fin_change_boat_settings')),
      switchMap(([_, boatId]: [never, number]) => this.boatsService.getNotifyBoatLocation(boatId)),
    );
  }

  setNotifyAisModal(): Observable<ResponseDto> {
    const boatId = this.boatId;
    if (!boatId) {
      return EMPTY;
    }
    return this.boatsService.setNotifyBoatLocation(boatId);
  }
}
