import {Pipe, PipeTransform} from '@angular/core';

import {EXPENSE_NUMBER_PATTERN} from '@helpers/patterns';

@Pipe({
  name: 'financialNumber',
  standalone: true,
})
export class FinancialNumberPipe implements PipeTransform {

  transform(value: number | string | undefined | null,  separator = ' '): string {
    if (value === null || value === undefined) {
      return '';
    }

    const newVal = typeof value === 'string' ? parseFloat(value) : value;
    const parsed = newVal.toFixed(2);
    return parsed.replace(EXPENSE_NUMBER_PATTERN, `$1${separator}`) || value.toString();
  }
}
