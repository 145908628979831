import {Params} from '@angular/router';

import {parseParamsToArray} from '@helpers/parse-params-to-array';

import {SearchRegionItemDto} from '../directories';
import {LocationOriginPlaceDto, SearchLocationDirectories} from '../location';
import {
  LocationDistanceSearchForm,
  LocationSearchDistanceItem,
} from '../location-distance-search-form/location-distance-search-form';
import {SearchChipsItemDto} from '../search';

export class BoatInfoDto {
  constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly boatType: string,
    public readonly flag: string,
    public readonly boatLength: number,
    public readonly crew: number,
    public readonly location: LocationOriginPlaceDto | null,
  ) {
  }
}

export class FleetMapParams {
  readonly myLocationLat: number | null = null;
  readonly myLocationLng: number | null = null;
  readonly distance: number | null = null;
  readonly regions: number[] | null = null;

  constructor(
    params: Params,
  ) {
    this.myLocationLat = params.myLocationLat;
    this.myLocationLng = params.myLocationLng;
    this.distance = params.distance;
    this.regions = parseParamsToArray(params.regions, true);
  }
}

export class FleetSearchMapParamsPayload extends FleetMapParams {
  readonly fleet: number;
  readonly topLeftLng: number;
  readonly topLeftLat: number;
  readonly bottomRightLat: number;
  readonly bottomRightLng: number;
  readonly zoom: number;

  constructor(
    fleet: number,
    params: Params,
  ) {
    super(params);
    this.fleet = fleet;
    this.topLeftLng = params.topLeftLng;
    this.topLeftLat = params.topLeftLat;
    this.bottomRightLat = params.bottomRightLat;
    this.bottomRightLng = params.bottomRightLng;
    this.zoom = params.zoom;
  }
}

export class FleetSearchMapInfoWindowPayload {
  readonly fleet: number | null = null;
  readonly myLocationLat: number | null = null;
  readonly myLocationLng: number | null = null;
  readonly boats: number[] | null = null;

  constructor(fleet: number, boats: number[], params: Params) {
    this.fleet = fleet;
    this.boats = boats;

    const {myLocationLat, myLocationLng} = params;
    if (myLocationLat && myLocationLng) {
      this.myLocationLat = myLocationLat;
      this.myLocationLng = myLocationLng;
    }
  }
}

export class FleetLocationSearchDirectories {
  constructor(
    public readonly regionsChips: SearchRegionItemDto[],
    public readonly location: SearchLocationDirectories | null,
    public readonly distances: LocationSearchDistanceItem[] | null,
    public readonly nearMeChips: SearchChipsItemDto | null,
  ) {
  }
}

export class FleetSearchLocationForm {
  constructor(
    public readonly location: LocationDistanceSearchForm | null = null,
    public readonly searchRegion: number | null = null,
    public readonly nearMe: boolean | null = null,
  ) {
  }
}

export class FleetSearchLocationParams {
  readonly myLocationLat: number | null = null;
  readonly myLocationLng: number | null = null;
  readonly distance: number | null = null;
  readonly regions: number[] | null = null;

  constructor(
    form: FleetSearchLocationForm | null,
  ) {
    if (!form) {
      return;
    }
    const {
      location,
      searchRegion,
    } = form;
    if (location) {
      this.myLocationLat = location.lat;
      this.myLocationLng = location.lng;
      this.distance = location.distance;
    }
    this.regions = searchRegion ? [searchRegion] : null;
  }
}
