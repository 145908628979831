import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import {takeUntil} from 'rxjs/operators';

import {DestroySubscription} from '@helpers/destroy-subscription';
import {BoatCurrentCurrency} from '@models/directories/currency.model';
import {BoatDetailsService} from '@services/boat-details/boat-details.service';

@Directive({
  selector: '[appBoatCurrency]',
  exportAs: 'appBoatCurrency',
})
export class BoatCurrencyDirective extends DestroySubscription implements OnInit, OnDestroy {

  currency: BoatCurrentCurrency = null;

  @Input() addCurrency = true;
  @Output() currencyChange = new EventEmitter<BoatCurrentCurrency>();

  constructor(
    private readonly elRef: ElementRef<HTMLElement>,
    private readonly renderer: Renderer2,
    private readonly boatDetailsService: BoatDetailsService,
  ) {
    super();
  }

  ngOnInit() {
    this.getCurrentCurrency();
  }

  getCurrentCurrency(): void {
    const currency$ = this.boatDetailsService.boatCurrency$;
    if (!currency$) {
      return;
    }
    currency$.pipe(
      takeUntil(this.destroyStream$),
    ).subscribe(boatCurrency => {
      this.currencyChange.emit(boatCurrency);
      if (this.addCurrency) {
        this.updateCurrency(boatCurrency);
      }
    });
  }

  private updateCurrency(boatCurrency: BoatCurrentCurrency): void {
    const currency = boatCurrency && boatCurrency.badge || '';
    this.renderer.setProperty(this.elRef.nativeElement, 'innerHTML', currency);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
