import {parseUploadFileName, parseUploadOriginalName} from '@helpers/parse-new-upload-file-names';

import {CurrencyDirectoriesDto} from '../directories';
import {booleanToConditionalStatus, ConditionalStatus} from '../general';
import {UploadedFile} from '../uploaded-file';

export interface BoatFleetManageForm {
  readonly fleet: number | null;
  readonly name: string;
  readonly currency: number;
  readonly description: string;
  readonly default: boolean;
  readonly image: UploadedFile | null;
  readonly imageFileRemove: ConditionalStatus.YES | null;
}

export class BoatFleetManageDirectories {
  constructor(
    public readonly currency: CurrencyDirectoriesDto[],
  ) {
  }
}

export class BoatFleetAddPayload {
  readonly name: string;
  readonly currency: number;
  readonly description: string;
  readonly default: ConditionalStatus;
  readonly imageFile: string | null;
  readonly imageOriginalFile: string | null;

  constructor(
    form: BoatFleetManageForm,
  ) {
    this.name = form.name;
    this.currency = form.currency;
    this.description = form.description;
    this.default = booleanToConditionalStatus(form.default);
    this.imageFile = parseUploadFileName(form.image);
    this.imageOriginalFile = parseUploadOriginalName(form.image);
  }
}

export class BoatFleetEditPayload extends BoatFleetAddPayload {
  readonly fleet: number;
  readonly imageFileRemove: ConditionalStatus.YES | null;

  constructor(
    form: BoatFleetManageForm,
    fleet: number,
  ) {
    super(form);
    this.fleet = fleet;
    this.imageFileRemove = form.imageFileRemove;
  }
}
