import {FinancialRoutes, HeaderNavItem} from '@models/general';

import {BOAT_INVENTORY_DEFAULT_FILTERS} from './boat-inventory';
import {BoatMaintenanceRoutes} from './boat-maintenance';
import {ALL_CHARTER_PERMISSIONS} from './permission-collections';
import {TASKS_DEFAULT_FILTERS} from './task-default-filters';

export const BOAT_NAV_ITEMS: HeaderNavItem[] = [
  new HeaderNavItem('shortcuts', 'shortcuts', 'compass', null, null, null, '_blank', []),
  new HeaderNavItem('dashboard', 'dashboard', 'stats', null, null, null, '_blank', [], false, null, 'boatDashboard'),
  new HeaderNavItem(
    'financial',
    'finModule',
    'cashRound',
    null,
    [
      'perm_fin_view_dashboard',
      'perm_fin_view_reports',
      'perm_fin_view_expenses_data_full',
      'perm_fin_view_expenses_data_by_user',
      'perm_fin_budget_edit',
      'perm_fin_budget_view',
      'perm_fin_card_statements_see_card_balance',
      'perm_fin_view_petty_cash_balance',
    ],
    null,
    '_self',
    [
      new HeaderNavItem(
        'report',
        'finReport',
        'note',
        null,
        ['perm_fin_view_dashboard', 'perm_fin_view_reports'],
      ),
      new HeaderNavItem(
        'data',
        'bfd',
        'cash',
        null,
        ['perm_fin_view_expenses_data_full', 'perm_fin_view_expenses_data_by_user'],
      ),
      new HeaderNavItem('budget', 'budget', 'cashRound', null, ['perm_fin_budget_edit', 'perm_fin_budget_view']),
      new HeaderNavItem(
        'card-statements',
        'cardStatements',
        'creditCard',
        null,
        'perm_fin_card_statements_see_statements',
        null,
        '_self',
        [],
        false,
        null,
        'cardStatements',
      ),
      new HeaderNavItem(
        'petty-cash-balance',
        'pcb',
        'newCash',
        null,
        'perm_fin_view_petty_cash_balance',
      ),
      new HeaderNavItem(
        'outstanding-payments',
        'outPayments',
        'time',
        null,
        ['perm_fin_view_expenses_data_full', 'perm_fin_view_expenses_data_by_user'],
        null,
        '_blank',
        [],
        false,
        null,
        'outPayments',
      ),
      new HeaderNavItem(
        FinancialRoutes.BankStatements,
        'bankStatements',
        'cashRound',
        null,
        [
          'perm_fin_boat_bank_account_see_statements',
          'perm_fin_boat_bank_account_download_report',
        ],
        null,
        '_blank',
        [],
        false,
        null,
      ),
    ],
    false,
    null,
    null,
    'menu__item_finance',
  ),
  new HeaderNavItem(
    'maintenance',
    'maintenance',
    'list',
    null,
    [
      'perm_boat_equipment_create_edit_delete_system_subsystem_part',
      'perm_boat_equipment_see_system_subsystem_part',
      'perm_boat_task_see_and_manage_all_tasks',
      'perm_boat_task_see_and_manage_assigned_tasks',
      'perm_boat_maintenance_see_dashboard',
    ],
    null,
    '_blank',
    [
      new HeaderNavItem(
        BoatMaintenanceRoutes.Dashboard,
        'dashboard',
        'stats',
        null,
        ['perm_boat_maintenance_see_dashboard'],
      ),
      new HeaderNavItem(
        BoatMaintenanceRoutes.Tasks,
        'tasks',
        'list',
        null,
        [
          'perm_boat_task_see_and_manage_all_tasks',
          'perm_boat_task_see_and_manage_assigned_tasks',
        ],
        null,
        '_blank',
        [],
        false,
        null,
        null,
        null,
        TASKS_DEFAULT_FILTERS,
      ),
      new HeaderNavItem(
        BoatMaintenanceRoutes.TasksLog,
        'tasksLog',
        'box',
        null,
        [
          'perm_boat_task_see_and_manage_all_tasks',
          'perm_boat_task_see_and_manage_assigned_tasks',
        ],
        null,
        '_blank',
        [],
        false,
        null,
        null,
        null,
      ),
      new HeaderNavItem(
        BoatMaintenanceRoutes.Systems,
        'systems',
        'document',
        null,
        [
          'perm_boat_equipment_create_edit_delete_system_subsystem_part',
          'perm_boat_equipment_see_system_subsystem_part',
        ],
      ),
      new HeaderNavItem(
        BoatMaintenanceRoutes.WorkList,
        'workList',
        'list',
        null,
        [
          'perm_boat_task_see_and_manage_all_tasks',
          'perm_boat_task_see_and_manage_assigned_tasks',
        ],
      ),
      new HeaderNavItem(
        BoatMaintenanceRoutes.Inventory,
        'inventory',
        'listChecked',
        null,
        [
          'perm_boat_inventory_see_create_update_delete',
          'perm_boat_inventory_only_see',
        ],
        null,
        '_self',
        [],
        false,
        null,
        null,
        null,
        BOAT_INVENTORY_DEFAULT_FILTERS,
      ),
      new HeaderNavItem(
        BoatMaintenanceRoutes.CheckLists,
        'checklists',
        'listChecked',
        null,
        ['perm_boat_check_list_create_and_check_items', 'perm_boat_check_list_just_check_items'],
      ),
    ],
    false,
    null,
    null,
    'menu__item_maintenance',
  ),
  new HeaderNavItem(
    'calendar',
    'calendar',
    'boatCalendar',
    null,
    [
      'perm_boat_calendar_see_calendar_and_events',
      'perm_boat_calendar_see_create_edit_calendar_and_events',
      'perm_boat_calendar_see_create_edit_delete_calendar_and_events',
    ],
    null,
    '_self',
    [],
    false,
    null,
    'boatCalendar',
  ),
  new HeaderNavItem(
    'profile',
    'profile',
    'boat',
    null,
    [
      'perm_fin_view_boat_profile',
      'perm_fin_change_boat_settings',
      'perm_fin_see_public_documents',
      'perm_fin_see_locked_documents',
    ],
    null,
    '_self',
    [
      new HeaderNavItem('general', 'general', 'info', null, 'perm_fin_view_boat_profile'),
      new HeaderNavItem('documents', 'documents', 'openFolder', null, [
        'perm_fin_see_public_documents',
        'perm_fin_see_locked_documents',
      ]),
      new HeaderNavItem('settings', 'settings', 'settings', null,
        [
          'perm_fin_change_boat_settings',
        ]),
    ],
  ),
  new HeaderNavItem('crew', 'myCrew', 'users', null, 'perm_fin_view_boat_profile'),
  new HeaderNavItem(
    'charters',
    'charters',
    'web',
    null,
    ALL_CHARTER_PERMISSIONS,
    null,
    '_self',
    [
      new HeaderNavItem(
        'overview',
        'charterOverview',
        'stats',
        null,
        ['perm_charter_overview'],
        null,
        '_self',
        [], false
        , null,
        'charterOverview',
      ),
      new HeaderNavItem('list', 'charterList', 'list', null, ALL_CHARTER_PERMISSIONS),
      new HeaderNavItem('calendar',
        'calendar',
        'newCalendar',
        null,
        'perm_charter_calendar',
        null,
        '_self', [],
        false,
        null,
        'charterCalendar',
      ),
      new HeaderNavItem('', 'guests', 'users', null, ALL_CHARTER_PERMISSIONS),
    ],
    false,
    null,
    'charters',
    'menu__item_charters',
  ),
  new HeaderNavItem(
    'search',
    'search',
    'search',
    null,
    null,
    null,
    '_self',
    [
      new HeaderNavItem('crew', 'searchCrew', 'search', null, 'perm_search_crew_search'),
      new HeaderNavItem('offers', 'offers', 'users', null, 'perm_search_job_offer_view'),
      new HeaderNavItem('suppliers', 'suppliers', 'user', null, null),
    ],
    false,
    null,
    'search-crew',
    'menu__item_search',
  ),
];

export const BOAT_NAV_EXTRAS_STATE = {boatHeader: true};

export const BOAT_CREW_AGENCY_NAV_ITEMS: HeaderNavItem[] = [
  new HeaderNavItem('dashboard', 'dashboard', 'stats', null, null, null, '_blank', [], false, null, 'boatDashboard'),
  new HeaderNavItem(
    'profile',
    'profile',
    'boat',
    null,
    ['perm_fin_view_boat_profile', 'perm_fin_change_boat_settings'],
    null,
    '_self',
    [
      new HeaderNavItem('general', 'general', 'info', null, 'perm_fin_view_boat_profile'),
      new HeaderNavItem('settings', 'settings', 'settings', null, 'perm_fin_change_boat_settings'),
    ],
  ),
  new HeaderNavItem(
    'search',
    'searchCrew',
    'search',
    null,
    ['perm_search_crew_search', 'perm_search_job_offer_view'],
    null,
    '_blank',
    [
      new HeaderNavItem('crew', 'search', 'search', null, 'perm_search_crew_search'),
      new HeaderNavItem('offers', 'offers', 'users', null, 'perm_search_job_offer_view'),
      new HeaderNavItem('suppliers', 'suppliers', 'user', null, null),
    ],
    false,
    null,
    'search-crew',
  ),
];
