import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {TransferHttpService} from '@core/modules/transfer-http/transfer-http.service';
import {httpParamsFromObject} from '@helpers/http-params';
import {
  AddAdditionalFieldPayload,
  AddBoatCategoryPayload,
  AddBoatDepartmentPayload,
  AddBoatInventoryCategoryPayload,
  AddBoatInventoryLocationPayload,
  AddBoatInventorySubCategoryPayload,
  AddBoatSubCategoryPayload,
  BoatAdditionalFieldItemDto,
  BoatCategoryDto,
  BoatCrewExcludedCategoriesDto,
  BoatCrewPermissionsDto,
  BoatDepartmentDto,
  BoatDepartmentPositionDto,
  BoatInventoryCatSubListDto,
  BoatInventoryLocationListDto,
  BoatSettingsEquipmentSystemItemDto,
  BoatSubCategoryDto,
  BoatSubscriptionInfoDto,
  BoatSupplierSettingsPayload,
  BoatUpdateCategoryItem,
  BoatUser,
  UpdateAdditoinalFieldPayload,
  UpdateBoatCategoryPayload,
  UpdateBoatCrewExcludeCategoriesPayload,
  UpdateBoatCrewPermissionsPayload,
  UpdateBoatDepartmentPayload,
  UpdateBoatDepartmentPositionPayload,
  UpdateBoatInventoryCategoryPayload,
  UpdateBoatInventoryLocationPayload,
  UpdateBoatInventorySubCategoryPayload,
  UpdateBoatSubCategoryPayload,
} from '@models/boat-settings';
import {CurrencyDto, ExchangeRateDto} from '@models/directories';
import {ResponseDto} from '@models/response';

import {BoatService} from '../boat/boat.service';

export interface BoatSettingsServiceI {
  apiUrl: string;

  updateBoatCurrency(boatId: number, currencyId: number, exchangeRate: number): Observable<ResponseDto>;

  getCurrencyExchangeRate(boatId: number, currencyId: number): Observable<ExchangeRateDto>;

  getBoatCurrency(boatId: number): Observable<CurrencyDto[]>;

  getBoatCategories(boatId: number): Observable<BoatCategoryDto[]>;

  addBoatCategory(payload: AddBoatCategoryPayload): Observable<BoatCategoryDto>;

  updateBoatCategory(payload: UpdateBoatCategoryPayload): Observable<BoatUpdateCategoryItem>;

  addBoatSubCategory(payload: AddBoatSubCategoryPayload): Observable<BoatSubCategoryDto>;

  updateBoatSubCategory(payload: UpdateBoatSubCategoryPayload): Observable<BoatSubCategoryDto>;

  getBoatAdditionalFields(boatId: number): Observable<BoatAdditionalFieldItemDto[]>;

  addBoatAdditionalField(payload: AddAdditionalFieldPayload): Observable<BoatAdditionalFieldItemDto>;

  addBoatAdditionalField(payload: AddAdditionalFieldPayload): Observable<BoatAdditionalFieldItemDto>;

  getCrewExcludedCategories(boatId: number, crewId: number): Observable<BoatCrewExcludedCategoriesDto>;

  updateCrewExcludedCategories(payload: UpdateBoatCrewExcludeCategoriesPayload): Observable<ResponseDto>;

  getCrewPermissions(boatId: number, crewId: number): Observable<BoatCrewPermissionsDto>;

  updateCrewPermissions(payload: UpdateBoatCrewPermissionsPayload): Observable<ResponseDto>;

  getBoatDepartmentList(boatId: number): Observable<BoatDepartmentDto[]>;

  addBoatDepartment(payload: AddBoatDepartmentPayload): Observable<BoatDepartmentDto>;

  updateBoatDepartment(payload: UpdateBoatDepartmentPayload): Observable<BoatDepartmentDto>;

  getBoatDepartmentPositionList(boatId: number): Observable<BoatDepartmentPositionDto[]>;

  updateBoatDepartmentPosition(payload: UpdateBoatDepartmentPositionPayload): Observable<BoatDepartmentPositionDto>;

  getSubscription(boatId: number): Observable<BoatSubscriptionInfoDto>;

  deleteCreditCard(boatId: number): Observable<ResponseDto>;

  setBoatSupplierSettings(payload: BoatSupplierSettingsPayload): Observable<ResponseDto>;
}

@Injectable({
  providedIn: 'root',
})
export class BoatSettingsService implements BoatSettingsServiceI {

  readonly apiUrl = 'api/boat';
  readonly apiUrlFleets = 'api/fleet-management';

  constructor(
    private readonly boatService: BoatService,
    private readonly http: TransferHttpService,
  ) {
  }

  updateBoatCurrency(boatId: number, currencyId: number, exchangeRate: number): Observable<ResponseDto> {
    return this.boatService.updateBoatCurrency(boatId, currencyId, exchangeRate);
  }

  getCurrencyExchangeRate(boatId: number, currencyId: number): Observable<ExchangeRateDto> {
    return this.boatService.getCurrencyExchangeRate(boatId, currencyId);
  }

  getBoatCurrency(boatId: number): Observable<CurrencyDto[]> {
    return this.boatService.getBoatShortInfo(boatId).pipe(
      map(response => response.currency),
    );
  }

  getBoatCategories(boatId: number): Observable<BoatCategoryDto[]> {
    const params = httpParamsFromObject({boatId});
    return this.http.get<ResponseDto<BoatCategoryDto[]>>(`${this.apiUrl}/get-cat-sub-fields`, {params})
      .pipe(
        map(response => response.data),
      );
  }

  addBoatCategory(payload: AddBoatCategoryPayload): Observable<BoatCategoryDto> {
    return this.http.post<ResponseDto<BoatCategoryDto>>(`${this.apiUrl}/create-category`, payload)
      .pipe(
        map(response => response.data),
      );
  }

  updateBoatCategory(payload: UpdateBoatCategoryPayload): Observable<BoatUpdateCategoryItem> {
    return this.http.put<ResponseDto<BoatUpdateCategoryItem>>(`${this.apiUrl}/update-category`, payload)
      .pipe(
        map(response => response.data),
      );
  }

  addBoatSubCategory(payload: AddBoatSubCategoryPayload): Observable<BoatSubCategoryDto> {
    return this.http.post<ResponseDto<BoatSubCategoryDto>>(`${this.apiUrl}/create-subcategory`, payload)
      .pipe(
        map(response => response.data),
      );
  }

  updateBoatSubCategory(payload: UpdateBoatSubCategoryPayload): Observable<BoatSubCategoryDto> {
    return this.http.put<ResponseDto<BoatSubCategoryDto>>(`${this.apiUrl}/update-subcategory`, payload)
      .pipe(
        map(response => response.data),
      );
  }

  getBoatAdditionalFields(boatId: number): Observable<BoatAdditionalFieldItemDto[]> {
    const params = httpParamsFromObject({boatId});
    return this.http.get<ResponseDto<BoatAdditionalFieldItemDto[]>>(`${this.apiUrl}/get-dynamic-fields`, {params})
      .pipe(
        map(response => response.data),
      );
  }

  addBoatAdditionalField(payload: AddAdditionalFieldPayload): Observable<BoatAdditionalFieldItemDto> {
    return this.http.post<ResponseDto<BoatAdditionalFieldItemDto>>(`${this.apiUrl}/create-dynamic-field`, payload)
      .pipe(
        map(response => response.data),
      );
  }

  updateBoatAdditionalField(payload: UpdateAdditoinalFieldPayload): Observable<BoatAdditionalFieldItemDto> {
    return this.http.put<ResponseDto<BoatAdditionalFieldItemDto>>(`${this.apiUrl}/update-dynamic-field`, payload)
      .pipe(
        map(response => response.data),
      );
  }

  getCrewExcludedCategories(boatId: number, crewId: number): Observable<BoatCrewExcludedCategoriesDto> {
    const params = httpParamsFromObject({boatId, crewId});
    return this.http.get<ResponseDto<BoatCrewExcludedCategoriesDto>>(
      `${this.apiUrl}/get-excludes-cat-sub-by-crew`,
      {params})
      .pipe(
        map(response => response.data),
      );
  }

  updateCrewExcludedCategories(payload: UpdateBoatCrewExcludeCategoriesPayload): Observable<ResponseDto> {
    return this.http.put<ResponseDto>(`${this.apiUrl}/update-excludes-cat-sub-by-crew`, payload);
  }

  getCrewPermissions(boatId: number, crewId: number): Observable<BoatCrewPermissionsDto> {
    const params = httpParamsFromObject({boatId, crewId});
    return this.http.get<ResponseDto<BoatCrewPermissionsDto>>(`${this.apiUrl}/get-permission-crew`, {params})
      .pipe(
        map(response => response.data),
      );
  }

  updateCrewPermissions(payload: UpdateBoatCrewPermissionsPayload): Observable<ResponseDto> {
    return this.http.put<ResponseDto>(`${this.apiUrl}/update-permission-crew`, payload);
  }

  getBoatUsers(boatId: number): Observable<BoatUser[]> {
    const params = httpParamsFromObject({boatId});
    return this.http.get<ResponseDto<BoatUser[]>>(`${this.apiUrl}/get-boat-users`, {params})
      .pipe(
        map(response => response.data),
      );
  }

  getBoatUserById(boatId: number, crewId: number): Observable<BoatUser> {
    const params = httpParamsFromObject({boatId, crewId});
    return this.http.get<ResponseDto<BoatUser>>(`${this.apiUrl}/get-boat-user`, {params})
      .pipe(
        map(response => response.data),
      );
  }

  getBoatDepartmentList(boatId: number): Observable<BoatDepartmentDto[]> {
    const params = httpParamsFromObject({boatId});
    return this.http.get<ResponseDto<BoatDepartmentDto[]>>(`${this.apiUrl}/get-departments`, {params})
      .pipe(
        map(response => response.data),
      );
  }

  addBoatDepartment(payload: AddBoatDepartmentPayload): Observable<BoatDepartmentDto> {
    return this.http.post<ResponseDto<BoatDepartmentDto>>(`${this.apiUrl}/create-department`, payload)
      .pipe(
        map(response => response.data),
      );
  }

  updateBoatDepartment(payload: UpdateBoatDepartmentPayload): Observable<BoatDepartmentDto> {
    return this.http.put<ResponseDto<BoatDepartmentDto>>(`${this.apiUrl}/update-department`, payload)
      .pipe(
        map(response => response.data),
      );
  }

  getBoatDepartmentPositionList(boatId: number): Observable<BoatDepartmentPositionDto[]> {
    const params = httpParamsFromObject({boatId});
    return this.http.get<ResponseDto<BoatDepartmentPositionDto[]>>(`${this.apiUrl}/get-positions`, {params})
      .pipe(
        map(response => response.data),
      );
  }

  updateBoatDepartmentPosition(payload: UpdateBoatDepartmentPositionPayload): Observable<BoatDepartmentPositionDto> {
    return this.http.put<ResponseDto<BoatDepartmentPositionDto>>(`${this.apiUrl}/update-position`, payload)
      .pipe(
        map(response => response.data),
      );
  }

  getSubscription(boatId: number): Observable<BoatSubscriptionInfoDto> {
    const params = httpParamsFromObject({boatId});
    return this.http.get<ResponseDto<BoatSubscriptionInfoDto>>(`${this.apiUrl}/get-subscription-info`, {params})
      .pipe(
        map(response => response.data),
      );
  }

  deleteCreditCard(boatId: number): Observable<ResponseDto> {
    const params = httpParamsFromObject({boatId});
    return this.http.delete<ResponseDto>(`${this.apiUrl}/delete-credit-card`, {params});
  }

  setBoatSupplierSettings(payload: BoatSupplierSettingsPayload): Observable<ResponseDto> {
    return this.http.put(`${this.apiUrl}/set-supplier-setting`, payload);
  }

  getBoatEquipments(boat: number, search: string): Observable<BoatSettingsEquipmentSystemItemDto[]> {
    const params = httpParamsFromObject({boat, search});
    return this.http.get<ResponseDto<BoatSettingsEquipmentSystemItemDto[]>>(
      `${this.apiUrl}/get-boat-equipments`,
      {params}).pipe(
      map(response => response.data),
    );
  }

  getBoatInventoryLocations(boat: number): Observable<BoatInventoryLocationListDto> {
    const params = httpParamsFromObject({boat});
    return this.http.get<ResponseDto<BoatInventoryLocationListDto>>(
      `${this.apiUrl}/get-inventory-location-list`,
      {params},
    ).pipe(
      map(response => response.data),
    );
  }

  addBoatInventoryLocation(payload: AddBoatInventoryLocationPayload): Observable<ResponseDto> {
    return this.http.post(
      `${this.apiUrl}/create-inventory-location`,
      payload,
    );
  }

  updateBoatInventoryLocation(payload: UpdateBoatInventoryLocationPayload): Observable<ResponseDto> {
    return this.http.put(
      `${this.apiUrl}/update-inventory-location`,
      payload,
    );
  }

  getBoatInventoryCatAndSubs(boat: number): Observable<BoatInventoryCatSubListDto> {
    const params = httpParamsFromObject({boat});
    return this.http.get<ResponseDto<BoatInventoryCatSubListDto>>(
      `${this.apiUrl}/get-inventory-cat-sub-list`,
      {params},
    ).pipe(
      map(response => response.data),
    );
  }

  addBoatInventoryCategory(payload: AddBoatInventoryCategoryPayload): Observable<ResponseDto> {
    return this.http.post(
      `${this.apiUrl}/create-inventory-category`,
      payload,
    );
  }

  updateBoatInventoryCategory(payload: UpdateBoatInventoryCategoryPayload): Observable<ResponseDto> {
    return this.http.put(
      `${this.apiUrl}/update-inventory-category`,
      payload,
    );
  }

  addBoatInventorySubCategory(payload: AddBoatInventorySubCategoryPayload): Observable<ResponseDto> {
    return this.http.post(
      `${this.apiUrl}/create-inventory-subcategory`,
      payload,
    );
  }

  updateBoatInventorySubCategory(payload: UpdateBoatInventorySubCategoryPayload): Observable<ResponseDto> {
    return this.http.put(
      `${this.apiUrl}/update-inventory-subcategory`,
      payload,
    );
  }
}
