import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {BoatCurrencyDirective} from './directive/boat-currency.directive';

@NgModule({
  declarations: [BoatCurrencyDirective],
  imports: [
    CommonModule,
  ],
  exports: [BoatCurrencyDirective],
})
export class BoatCurrencyModule {
}
