import {Expose} from 'class-transformer';

export type ChartType = 'bar' | 'bar-vertical' | 'line' | 'pie';

// export interface ChartDataResponse {
//   readonly name: string;
//   readonly currentExpense: number;
//   readonly id?: number;
//   readonly color?: string;
// }
//
// export interface ChartData extends Omit<ChartDataResponse, 'name' | 'currentExpense'>{
//   readonly label: string;
//   readonly value: number;
// }

export class ChartData {
  @Expose({name: 'name'}) label: string;
  @Expose({name: 'currentExpense'}) value: number;
  readonly id?: number;
  readonly color?: string;
}
