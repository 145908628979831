import {LocationDto} from '../location';
import {PaginationDto} from '../response';

export interface BoatsFleetFleetBoatShortItemDto {
  readonly models: BoatsFleetBoatDto[];
  readonly pagination: PaginationDto;
}

export interface BoatsFleetBoatDto {
  readonly id: number;
  readonly name: string;
  readonly activityType: string;
  readonly boatType: string;
  readonly image: string;
  readonly boatLength: number;
  readonly users: number;
  readonly location: LocationDto;
}

export class BoatFleetBoatsFilterParams {
  constructor(
    public page: number = 1,
    public search: string = '',
  ) {
  }
}

export class BoatsFleetBoatsPayload {
  constructor(
    public readonly page: number,
    public readonly search: string,
    public readonly fleet?: number,
    public readonly limit?: number,
  ) {
  }
}

export class BoatsFleetDeleteBoatPayload {
  constructor(
    public readonly fleet: number,
    public readonly boat: number,
  ) {
  }
}

export class BoatsFleetIdPayload {
  constructor(
    public readonly fleet: number,
  ){}
}

export class AddFleetBoatDirectories {
  constructor(
    public readonly boats: BoatsShortItemDto[],
  ) {}
}

export class BoatsShortItemDto {
  constructor(
    public readonly id: number,
    public readonly name: string,
  ) {
  }
}

export class FleetsDto {
  constructor(
    public readonly id: number,
    public readonly name: string,
  ) {
  }
}

export interface BoatFleetBoatManageForm {
  readonly boat: number[];
}

export interface BoatSettingFleetBoatManageForm {
  readonly fleetId: number;
  readonly boats: number[];
}

export class BoatSettingsFleetBoatAddPayload {
  constructor(
    public readonly boat: number[],
    public readonly fleet: number,
  ) {
  }
}

export class BoatFleetBoatAddPayload {
  readonly fleet: number;
  readonly boat: number[] | number;
  constructor(
    form: BoatFleetBoatManageForm,
    fleet: number,
  ) {
    this.fleet = fleet;
    this.boat = form.boat;
  }
}
