import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  EmbeddedViewRef,
  Input,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import {UserPermissions} from '@modules/permissions';
import {takeUntil} from 'rxjs/operators';

import {DestroySubscription} from '@helpers/destroy-subscription';

import {BoatDetailsService} from '../../../../services/boat-details/boat-details.service';

@Directive({
  selector: '[appPermission]',
})
export class PermissionDirective extends DestroySubscription implements AfterViewInit, OnDestroy {

  private permission: UserPermissions | UserPermissions[];
  private viewRef: EmbeddedViewRef<unknown> | null = null;
  // private elseViewRef: EmbeddedViewRef<unknown> | null = null;
  private readonly templateRef: TemplateRef<unknown>;
  // private elseTemplateRef: TemplateRef<unknown> | null = null;

  private isPermitted: boolean | undefined = undefined;

  @Input()
  set appPermission(permission: UserPermissions | UserPermissions[] | null) {
    if (!permission) {
      this.createView();
      return;
    }

    if (!this.permission) {
      this.permission = permission;
      this.init();
    }
  }

  // @Input()
  // set appPermissionElse(templateRef: TemplateRef<unknown> | null) {
  //   console.log({templateRef});
  //   this.elseTemplateRef = templateRef;
  //   this.elseViewRef = null;
  //   if (this.isPermitted !== undefined && !this.isPermitted) {
  //     this.createElseView();
  //   }
  // }

  constructor(
    private readonly ref: TemplateRef<any>,
    private readonly vcr: ViewContainerRef,
    private readonly boatDetailsService: BoatDetailsService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super();
    this.templateRef = ref;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  private init(): void {
    const boatId = this.boatDetailsService.boatId;
    if (!boatId) {
      this.createView();
      return;
    }
    const permissions$ = this.boatDetailsService.permissions$;
    if (!permissions$) {
      return;
    }
    permissions$.pipe(
      takeUntil(this.destroyStream$),
    ).subscribe((permissions) => {
      const contentPermissions = this.permission;
      const isPermitted = Array.isArray(contentPermissions) ?
        contentPermissions.some(permission => permissions.includes(permission)) :
        (contentPermissions ? permissions.indexOf(contentPermissions as UserPermissions) >= 0 : true);
      this.isPermitted = isPermitted;
      if (!isPermitted) {
        // console.log(this.elseTemplateRef, {isPermitted});
        this.vcr.clear();
        this.viewRef = null;
        // this.elseViewRef = null;
        // this.createElseView();
        return;
      }
      // this.elseViewRef = null;
      this.createView();
    });
  }

  private createView(): void {
    if (!this.viewRef) {
      this.viewRef = this.vcr.createEmbeddedView(this.templateRef);
      this.cdr.detectChanges();
    }
  }

  ngAfterViewInit() {
    if (this.isPermitted !== undefined && !this.isPermitted) {
      // this.createElseView();
    }
  }

  // private createElseView(): void {
  //   console.log('createElseView', this.elseViewRef, this.elseTemplateRef);
  //   if (this.elseTemplateRef) {
  //     // setTimeout(() => {
  //     //   this.elseViewRef = this.vcr.createEmbeddedView(this.elseTemplateRef!);
  //     //   this.cdr.detectChanges();
  //     // }, 2000);
  //     this.elseViewRef = this.vcr.createEmbeddedView(this.elseTemplateRef);
  //     this.cdr.detectChanges();
  //   }
  // }
}
