import {SeazoneIcon} from '../../modules/seazone-icons';
import {UserRoleType} from '../directories';
import {ExpenseCrewDto} from '../expense';
import {ConditionalStatus} from '../general';

export enum BoatMaintenanceWorkListStatus {
  InProgress = 1,
  Completed
}

export enum TaskCategoryType {
  Open = 1,
  Closed
}

export enum TaskDashboardStatisticMode {
  Previous = 1,
  Today,
  Planned,
}

export interface BoatMaintenanceStatisticFilterParams {
  readonly mode: TaskDashboardStatisticMode | null;
  readonly assignees: number[] | null;
  readonly categoryTypes: TaskCategoryType[] | null;
  readonly inbox: ConditionalStatus.YES | null;
  readonly dateFrom: string | null;
  readonly dateTo: string | null;
  readonly boats?: number[] | null;
}

type CurrentUser = Assignee & CurrentUserBoat;

export class MaintenanceTaskGroupedCrewDto {
  constructor(
    public readonly unassigned: UnassignedMaintenanceCrewDto,
    public readonly currentUser: CurrentUser | null = null,
    public readonly currentUserList: CurrentUser[] = [],
    public readonly defaultAssigneeId: number | null = null,
    public readonly crew: Assignee[] = [],
    public readonly head: Assignee[] = [],
  ) {
  }
}

export interface TaskWorkListDirectoryStatusColored extends TaskDirectoryItem<BoatMaintenanceWorkListStatus> {
  color: string;
}

export interface Assignee {
  readonly crewId?: number | null;
  readonly image: string | null;
  readonly name: string;
  readonly positionId: number | null;
  readonly userId: number;
  readonly userRole: UserRoleType;
}

export interface CurrentUserBoat {
  readonly boat?: string;
}

export interface TaskDirectoryItem<T> {
  readonly id: T;
  readonly name: string;
  readonly icon: SeazoneIcon | null;
  readonly cssClass: string;
}

export type UnassignedMaintenanceCrewDto = Omit<ExpenseCrewDto, 'userId' | 'userRole' | 'departmentRelated'> & {
  readonly userRole?: UserRoleType;
};

export enum TaskDashboardCustomFilterType {
  Preventive = 1,
  Installation,
  BreakFault,
  Other,
  Assignee,
  TotalCreated,
  StillOpen,
  AlreadyClosed,
  PreviouslyClosed,
  HiddenTasks,
  LastService,
  NextService,
}
