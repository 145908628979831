import {TaskStatusType} from '@models/boat-maintenance';
import {ConditionalStatus} from '@models/general';

export const TASKS_DEFAULT_STATUS_FILTERS = [TaskStatusType.Open];

export const TASKS_DEFAULT_FILTERS = {
  statuses: TASKS_DEFAULT_STATUS_FILTERS,
  initial: ConditionalStatus.YES,
  checkInbox: ConditionalStatus.YES,
};
