import {BasicNavItem} from '@models/general';
import {
  SupplierProfileEditNavLinkName,
  SupplierProfileNavUrl,
} from '@models/supplier/supplier-profile-navigation.model';

export const SUPPLIER_PROFILE_EDIT_NAV_ITEMS
: readonly [BasicNavItem, BasicNavItem, BasicNavItem, BasicNavItem, BasicNavItem, BasicNavItem, BasicNavItem] = [
  new BasicNavItem(SupplierProfileNavUrl.General, SupplierProfileEditNavLinkName.General),
  new BasicNavItem(SupplierProfileNavUrl.Contacts, SupplierProfileEditNavLinkName.Contacts),
  new BasicNavItem(SupplierProfileNavUrl.Service, SupplierProfileEditNavLinkName.Service),
  new BasicNavItem(SupplierProfileNavUrl.Documents, SupplierProfileEditNavLinkName.Documents),
  new BasicNavItem(SupplierProfileNavUrl.BankDetails, SupplierProfileEditNavLinkName.BankDetails),
  new BasicNavItem(SupplierProfileNavUrl.PhotoVideo, SupplierProfileEditNavLinkName.PhotoVideo),
  new BasicNavItem(SupplierProfileNavUrl.Branches, SupplierProfileEditNavLinkName.Branches),
];

export const SUPPLIER_URLS = SUPPLIER_PROFILE_EDIT_NAV_ITEMS.map(item => item.url).filter(url => url !== null) as SupplierProfileNavUrl[];
