import {BoatMaintenanceNotesType} from './boat-equipment-notes-form';

import {FileListItemI} from '../file';
import {PaginationDto, PaginationListDto} from '../response';

export interface BoatInventoryListPayload {
  readonly boat: number;
  readonly itemId: string | null;
  readonly search: string | null;
  readonly locations: number[] | null;
  readonly categories: number[] | null;
  readonly subCategories: number[] | null;
  readonly quantityFrom?: number | null;
  readonly quantityTo?: number | null;
  readonly dateCheckingFrom: string | null;
  readonly dateCheckingTo: string | null;
  readonly status: BoatInventoryStatus[] | null;
  readonly limit: number;
  readonly page: number;
  readonly tags: string[] | null;
}

interface BaseBoatInventoryItemDto {
  readonly name: string;
  readonly files: FileListItemI[] | null;
  readonly description: string | null;
  readonly location: number | null;
  readonly category: number | null;
  readonly subCategory: number | null;
  readonly quantity: number;
  readonly brand: number | null;
  readonly model: string | null;
  readonly serialNumber: string | null;
  readonly tags: string[];
}

/**
 * All possible statuses for inventory item
 */
export enum BoatInventoryStatus {
  checked = 1,
  notChecked,
  unconfirmed,
  deleted,
}

/**
 * List of modes for changing inventory item state: delete item or change status
 */
export enum BoatInventoryStateMode {
  delete = 1,
  changeStatus,
}

export interface BoatInventoryItemDto extends BaseBoatInventoryItemDto{
  readonly id: number;
  readonly status: BoatInventoryStatus;
  readonly dateUpdate: string;
  readonly lastUserUpdate: string;
  readonly dateChangeStatus: string;
}

export interface AddBoatInventoryPayload extends Omit<BaseBoatInventoryItemDto, 'files'> {
  readonly boat: number;
  readonly files: string[] | null;
  readonly brandOther?: string | null;
}

export interface UpdateBoatInventoryPayload extends AddBoatInventoryPayload {
  readonly id: number;
  readonly removeFiles: number[] | null;
}

export interface UpdateInventoryStatePayload {
  readonly boat: number;
  readonly inventories: number[];
  readonly mode: BoatInventoryStateMode;
  readonly 'payload[status]'?: BoatInventoryStatus;
  readonly 'payload[note]'?: string | null
}

interface BoatInventoryCounters {
  readonly attachments: number;
  readonly notes: number;
  readonly totalNotes: number;
}

export interface BoatInventoryListItemDto {
  readonly id: number;
  readonly photo: string;
  readonly location: string;
  readonly category: string;
  readonly subCategory: string;
  readonly name: string;
  readonly quantity: number;
  readonly status: BoatInventoryStatus;
  readonly dateStatusUpdate: string;
  readonly counters: BoatInventoryCounters;
}

export class BoatInventoryPaginationListDto extends PaginationListDto<BoatInventoryListItemDto> {
  constructor(
    public readonly models: BoatInventoryListItemDto[],
    public readonly pagination: PaginationDto,
    public readonly allItemIds: number[],
  ) {
    super(models, pagination);
  }
}

export class BoatInventoryNotesPayload {
  constructor(
    public readonly boat: number,
    public readonly inventory: number,
    public readonly page: number,
    public readonly type: BoatMaintenanceNotesType | null,
    public readonly limit: number,
  ) {
  }
}

export class AddBoatInventoryNotePayload {
  constructor(
    public readonly boat: number,
    public readonly inventory: number,
    public readonly text: string,
  ) {
  }
}

export class UpdateBoatInventoryNotePayload extends AddBoatInventoryNotePayload {
  constructor(
    boat: number,
    inventory: number,
    text: string,
    public readonly boatInventoryNote: number,
  ) {
    super(boat, inventory, text);
  }
}

export interface BoatInventoryTagsPayload {
  readonly boat: number;
  readonly search: string;
}
