import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {NumberPipe} from '@pipes/number-pipe';
import Chart from 'chart.js';

import {PlatformBrowserService} from '@core/modules';
import {MomentService} from '@services/moment/moment.service';

import {ChartManager} from '../../classes/chart-manager/chart-manager';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
  providers: [NumberPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineChartComponent extends ChartManager implements OnInit, OnChanges {

  @Input() firstYear: number[] = [];
  @Input() secondYear: number[] = [];
  @Input() customLabels: string[];
  @Input() height = '100%';

  constructor(
    private readonly momentService: MomentService,
    protected readonly browserService: PlatformBrowserService,
    protected readonly numberPipe: NumberPipe,
  ) {
    super(browserService, numberPipe, 'line');
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes || !this.browserService.isBrowser) {
      return;
    }
    if (!this.chart) {
      this.drawChart();
    }
    if ('firstYear' in changes || 'secondYear' in changes) {
      this.updateChartData(0, this.firstYear, false);
      this.updateChartData(1, this.secondYear, false);
    }

    if ('customLabels' in changes && this.customLabels) {
      this.updateChartLabels(this.customLabels);
    }

    if (this.chart) {
      this.chart.update();
    }
  }

  protected drawChart(): void {
    if (!this.browserService.isBrowser || !this.currencyBadge) {
      return;
    }
    const ctx = this.chartRef.nativeElement.getContext('2d');
    const gradientStroke = ctx.createLinearGradient(100, 100, 400, 400);
    gradientStroke.addColorStop(0, '#46A6A4');
    gradientStroke.addColorStop(1, 'rgba(70, 166, 164, 0.42)');

    const gradientStroke1 = ctx.createLinearGradient(0, 0, 0, 420);
    gradientStroke1.addColorStop(0, 'rgba(9, 189, 186, 0.4)');
    gradientStroke1.addColorStop(1, 'rgba(255, 255, 255, 0.2)');

    const gradientStroke2 = ctx.createLinearGradient(0, 0, 0, 420);
    gradientStroke2.addColorStop(0, 'rgba(125, 66, 210, 0.4)');
    gradientStroke2.addColorStop(1, 'rgba(255, 255, 255, 0.2)');

    const labels = this.customLabels || this.momentService.monthsShortList;
    this.chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels,
        datasets: [
          {
            data: this.firstYear,
            backgroundColor: gradientStroke2,
            borderColor: '#351F6F',
            pointBorderColor: '#fff',
            pointBackgroundColor: '#351F6F',
            pointRadius: 6,
            borderWidth: 4,
            pointBorderWidth: 2,
            cubicInterpolationMode: 'monotone',
          },
          {
            data: this.secondYear,
            backgroundColor: gradientStroke1,
            borderColor: '#46A6A4',
            pointBorderColor: '#fff',
            pointBackgroundColor: '#46A6A4',
            pointRadius: 6,
            borderWidth: 4,
            pointBorderWidth: 2,
            cubicInterpolationMode: 'monotone',
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
        tooltips: {
          callbacks: {
            title: () => '',
            label: (tooltipItems) => this.addCurrency(tooltipItems.yLabel as string),
          },
          borderColor: 'rgba(0,0,0,0.1)',
          borderWidth: 1,
          xPadding: 9,
          yPadding: 8,
          backgroundColor: '#FFF',
          bodyFontColor: '#351F6F',
          bodyFontSize: 16,
          displayColors: false,
        },
        scales: {
          yAxes: [
            {
              ticks: {
                fontColor: '#A9A9A9',
                beginAtZero: true,
                callback: (value) => this.addCurrency(value),
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                fontColor: '#A9A9A9',
              },
            },
          ],
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    });
  }
}
