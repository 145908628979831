import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {ActivatedRoute, Router, RouterModule} from '@angular/router';
import {RolesService} from '@modules/roles/services/roles/roles.service';

import {AppStateService} from '@core/modules/app-state/app-state.service';

import {AppRouterService} from './app-router.service';

export function appRouterServiceFactory(
  router: Router,
  route: ActivatedRoute,
  appStateService: AppStateService,
  rolesService: RolesService,
)
  : AppRouterService {
  return new AppRouterService(router, route, appStateService, rolesService);
}

@NgModule({
  imports: [
    RouterModule,
  ],
})
export class AppRouterModule {

  constructor(@Optional() @SkipSelf() parentModule: AppRouterModule) {
    if (parentModule) {
      throw new Error('AppRouter module should be imported only in AppModule.');
    }
  }

  static forRoot(): ModuleWithProviders<AppRouterModule> {
    return {
      ngModule: AppRouterModule,
      providers: [
        {
          provide: AppRouterService,
          useFactory: appRouterServiceFactory,
          deps: [Router, ActivatedRoute, AppStateService, RolesService],
        },
      ],
    };
  }
}
