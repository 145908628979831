import {Injectable} from '@angular/core';
import {plainToInstance} from 'class-transformer';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {TransferHttpService} from '@core/modules/transfer-http/transfer-http.service';
import {httpParamsFromObject} from '@helpers/http-params';
import {FinancialDataByChartDto, FinancialDataDto} from '@models/financial-data';
import {
  AnnualReportDto,
  ComparisonReportDto,
  ComparisonReportPayload,
  MonthlyReportDto,
  ReportFilterDto,
} from '@models/financial-report';
import {ResponseDto} from '@models/response';

@Injectable({
  providedIn: 'root',
})
export class FinancialReportService {

  private readonly apiUrl = 'api/boat-financial-report';

  constructor(private readonly http: TransferHttpService) {
  }

  financeDataByChart(payload: FinancialDataByChartDto): Observable<FinancialDataDto> {
    return this.getReportData('get-finance-data-by-chart', payload);
  }

  // TODO: remove plainToInstance
  monthlyReport(payload: ReportFilterDto): Observable<MonthlyReportDto> {
    return this.getReportData('get-month-report-data', payload).pipe(
      map(response => plainToInstance(MonthlyReportDto, response)),
    );
  }

  // TODO: remove plainToInstance
  annualReport(payload: ComparisonReportPayload): Observable<AnnualReportDto> {
    return this.getReportData('get-year-report-data', payload).pipe(
      map(response => plainToInstance(AnnualReportDto, response)),
    );
  }

  getComparisonReport(payload: ComparisonReportPayload): Observable<ComparisonReportDto> {
    return this.getReportData('get-comparison-year-budget', payload);
  }

  private getReportData<T>(
    url: string,
    payload: ReportFilterDto | FinancialDataByChartDto | ComparisonReportPayload,
  ): Observable<T> {
    const params = httpParamsFromObject(payload);
    return this.http.get<ResponseDto<T>>(`${this.apiUrl}/${url}`, {params}).pipe(
      map((response: ResponseDto) => response.data),
    );
  }

}
