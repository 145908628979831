export enum BoatMaintenanceTaskLogTemplates {
  general = 1,
  custom,
  todoList,
  workListLog,
  serviceLog,
}

export enum BoatMaintenanceTaskLogColumns {
  taskType = 1,
  createEditDate,
  system,
  name,
  workList,
  startDueDate,
  assignee,
  creator,
  description,
  priorities,
  status,
  service,
  statusDateUpdate,
  quote,
  approvedCost,
  actualCost,
  workListDescription,
  estimateOriginal,
  estimateActual,
  tags,
  charter,
}
