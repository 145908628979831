export type SupplierProfileNavStatus = 'incompleted' | 'unfilled' | 'completed';

export enum SupplierProfileNavUrl {
  General = 'general-details',
  Contacts = 'contacts',
  Service = 'service-catalog',
  Documents = 'documents',
  BankDetails = 'bank-details',
  PhotoVideo = 'additional-details',
  Branches = 'branches',
}
export enum SupplierProfileEditNavLinkName {
  General = 'generalDetails',
  Contacts = 'contacts',
  Service = 'serviceCatalog',
  Documents = 'documents',
  BankDetails = 'bankDetails',
  PhotoVideo = 'additionalPhotoVideo',
  Branches = 'branches',
}

export type SupplierProfileCompletionSectionDto = {[key in SupplierProfileEditNavLinkName]: SupplierProfileNavStatus};
