export enum FleetRoutes {
  Dashboard = 'dashboard',
  Calendar = 'calendar',
  Charters = 'charters',
  Contacts = 'contacts',
  Documents = 'documents',
  Maintenance = 'maintenance',
  Tasks = 'tasks',
}

export enum FleetContactsRoutes {
  Guests = 'guests',
  Crew = 'crew'
}
