import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';

import {AppRouterService} from '@core/services';
import {BoatService} from '@services/boat/boat.service';
import {BoatDetailsService} from '@services/boat-details/boat-details.service';

@Injectable()
export class IsBoatAdminGuard  {

  constructor(
    private readonly boatService: BoatService,
    private readonly boatDetailsService: BoatDetailsService,
    private readonly appRouterService: AppRouterService,
  ) {
  }

  canActivate(
    snapshot: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const id = this.getBoatId(snapshot);
    if (!id) {
      return true;
    }
    if (this.boatDetailsService.boatShortInfo$) {
      return this.boatDetailsService.isBoatAdmin$.pipe(
        tap(isBoatAdmin => this.redirect(isBoatAdmin)),
      );
    }
    return this.boatService.getBoatShortInfo(id).pipe(
      map(info => info.isBoatAdmin),
      tap(isBoatAdmin => this.redirect(isBoatAdmin)),
    );
  }

  private redirect(isBoatAdmin): void {
    if (!isBoatAdmin) {
      this.appRouterService.navigateToBoatsList();
    }
  }

  private getBoatId(snapshot: ActivatedRouteSnapshot): number | null {
    const parent = snapshot.parent;
    if (!parent) {
      return null;
    }
    const params = parent.params;
    const id = params && params.boatId;
    return !!id && Number(id) ? +id : this.getBoatId(parent);
  }

}
