import {InjectionToken} from '@angular/core';

import {
  MaintenanceTaskGroupedCrewDto,
  TaskCategoryType,
  TaskDashboardCustomFilterType,
  TaskDashboardStatisticMode,
  TaskDirectoryItem,
} from './boat-maintenance-shared';
import {BoatMaintenanceTasksInfoDto, BoatMaintenanceTasksLogPayload, TaskStatusType, TaskType} from './boat-tasks';
import {BoatEquipmentMileageDateColor, EquipmentListCardServiceDto, WarrantyStatusCode} from './equipment.dto';

import {BreadcrumbsItem} from '../../modules/breadcrumbs/interfaces/breadcrumbs';
import {BoatDocumentsFilterParams} from '../boat-documents';
import {EquipmentLevel} from '../boat-settings';
import {UserRoleType} from '../directories';
import {ConditionalStatus, LabeledItem} from '../general';
import {PaginationDto} from '../response';
import {GroupRange} from '../shared';

export interface BoatMaintenanceGeneralUpdateModalData {
  readonly dateFrom: string;
  readonly dateTo: string;
  readonly inbox: boolean;
  readonly generalUpdate: string | null;
}

export interface BoatMaintenanceDashboardMapPayload {
  readonly boat: number;
  readonly dateTime: string;
}

export type BoatMaintenanceDashboardMapFilter = Omit<BoatMaintenanceDashboardMapPayload, 'boat'>;

export interface BoatMaintenanceDashboardMapDto {
  readonly location: string | null;
  readonly lat: number | null;
  readonly lng: number | null;
  readonly destination: string | null;
  readonly status: string | null;
  readonly speed: string | null;
  readonly imoNumber: number | null;
  readonly mmsi: string | null;
  readonly datePositionReceived: string | null;
  readonly lastUpdated: string | null;
}

export class BoatMaintenanceTasksAlertsDirectories {
  constructor(
    public readonly alertModes: LabeledItem<BoatMaintenanceExpiredAlertsMode>[],
  ) {
  }
}

export interface BoatMaintenanceTasksAlertsDto<
  T = BoatMaintenanceTasksOpenAlertsDto, W = BoatMaintenanceTasksExpiredAlertsDto
> {
  readonly open: T;
  readonly expired: W;
}

export interface BoatMaintenanceTasksOpenAlertsDto {
  readonly ownersRequests: number;
  readonly urgent: number;
  readonly drafts: number;
  readonly quotes: number;
}

export interface BoatMaintenanceTasksExpiredAlertsDto {
  readonly count: number;
  readonly byType: BoatMaintenanceTasksTypesDto[];
  readonly byAssignee: BoatMaintenanceDashboardTasksAssigneeDto[];
}

export interface BoatMaintenanceTasksTypesDto {
  readonly id: TaskType;
  readonly count: number;
  readonly customFilterType?: TaskDashboardCustomFilterType;
  readonly boats?: number;
}

export interface BoatMaintenanceDashboardTasksAssigneeDto {
  readonly crew: BoatMaintenanceTasksCrewDto | null;
  readonly count: number;
  readonly workingDays: string;
  readonly actualHours: string;
}

export interface BoatMaintenanceTasksCrewDto {
  readonly id: number;
  readonly name: string;
  readonly avatar: string | null;
  readonly position: string | null;
  readonly role: string | null;
}

export interface BoatMaintenanceTasksChartItemDto {
  readonly title: string;
  readonly value: number;
  readonly id: number | string;
  readonly cssClass?: string;
  readonly customFilterType?: TaskDashboardCustomFilterType;
  readonly boats?: number | null;

}

export type BoatMaintenanceExpiredAlertsMode = keyof Omit<BoatMaintenanceTasksExpiredAlertsDto, 'count'>;

export interface BoatMaintenanceTasksAlertItem<T> {
  readonly title: string;
  readonly filterParams: T | null;
  readonly key: string;
  readonly value: number;
  readonly cssClass: string[] | null;
}

export type BoatMaintenanceTasksAlertItemParams =
  { [key in keyof BoatMaintenanceTasksOpenAlertsDto]: Partial<BoatMaintenanceTasksLogPayload> };

export interface BoatMaintenanceStatisticStatusTasksDto {
  readonly closed: BoatMaintenanceStatisticStatusTasksItemDto;
  readonly created: BoatMaintenanceStatisticStatusTasksItemDto;
  readonly open: BoatMaintenanceStatisticStatusTasksItemDto;
  readonly previously: BoatMaintenanceStatisticStatusTasksItemDto;
}

export interface BoatMaintenanceStatisticStatusTasksItemDto {
  readonly count: number;
  readonly customFilterType: TaskDashboardCustomFilterType;
}

export interface BoatMaintenanceStatisticDto {
  readonly counters: BoatMaintenanceTasksTypesDto[];
  readonly statusTasks: BoatMaintenanceStatisticStatusTasksDto;
  readonly tasksByAssignee?: BoatMaintenanceDashboardTasksAssigneeDto[];
  readonly hiddenTasks?: number;
}

export interface BoatMaintenanceMappedStatistic {
  readonly counters: BoatMaintenanceTasksChartItemDto[];
  readonly statusTasks: BoatMaintenanceTasksChartItemDto[] | null;
  readonly tasksByAssignee: BoatMaintenanceDashboardTasksAssigneeDto[];
  readonly totalTasksCount: number;
  readonly hiddenTasks: number;
}

export interface BoatMaintenanceTotalTaskChartItems {
  readonly counters: BoatMaintenanceTasksChartItemDto[];
  readonly totalTasksCount: number;
}

export interface BoatMaintenanceStatisticFilterForm {
  readonly mode: TaskDashboardStatisticMode;
  readonly assignees: number[] | null;
  readonly period: GroupRange<string> | null;
  readonly boats?: number[] | null;
  readonly categoryTypes?: TaskCategoryType[] | null;
  readonly inbox?: boolean | null;
}

export interface FleetMaintenanceCrew {
  crewId: number | null;
  image: string | null;
  name: string;
  positionId: number | null;
  userId: number;
  userRole: UserRoleType;
}

export interface FleetMaintenanceBoat {
  readonly id: number;
  readonly name: string;
  readonly boatType: string;
  readonly boatLength: number;
  readonly assignee: FleetMaintenanceCrew[];
}

export interface BoatMaintenanceStatisticFilterDirectories {
  readonly categoryTypes: TaskDirectoryItem<TaskCategoryType>[];
  readonly assignees: MaintenanceTaskGroupedCrewDto | null;
  readonly tasksInfo: BoatMaintenanceTasksInfoDto | null;
  readonly today: string;
  readonly yesterday: string;
  readonly tomorrow: string;
  readonly userId: number | null;
  readonly boats?: FleetMaintenanceBoat[] | null;
}

export interface BoatMaintenanceStatisticPayload {
  readonly boat: number;
  readonly mode: TaskDashboardStatisticMode;
  readonly assignees?: number[] | null;
  readonly dateFrom?: string | null;
  readonly dateTo?: string | null;
  readonly statuses?: TaskStatusType[] | null;
  readonly inbox?: ConditionalStatus.YES | null;
}

export interface BoatMaintenanceDocumentAlertsDto {
  readonly boat: BoatMaintenanceBoatDocumentAlertsDto;
  readonly crew: BoatMaintenanceCrewDocumentAlertsDto;
}

export interface BoatMaintenanceBoatDocumentAlertsDto {
  readonly expired: number;
  readonly expiringSoon: number;
}

export interface BoatMaintenanceCrewDocumentAlertsDto extends BoatMaintenanceBoatDocumentAlertsDto {
  readonly folder: number;
}

export interface BoatMaintenanceDocumentAlerts {
  readonly boat: BoatMaintenanceTasksAlertItem<Partial<BoatDocumentsFilterParams>>[];
  readonly crew: BoatMaintenanceTasksAlertItem<Partial<BoatDocumentsFilterParams>>[];
  readonly crewFolderId: number;
}

export type BoatMaintenanceDocumentItemParams =
  { [key in keyof BoatMaintenanceBoatDocumentAlertsDto]: Partial<BoatDocumentsFilterParams> };

export interface BoatMaintenanceDashboardSystemsFilters {
  readonly page: number;
}

export interface BoatMaintenanceSystemsPayload extends BoatMaintenanceDashboardSystemsFilters {
  readonly boat: number;
}

export interface BoatMaintenanceDashboardSystemsDto {
  readonly models: BoatMaintenanceDashboardSystemItemDto[];
  readonly pagination: PaginationDto;
}

export interface BoatMaintenanceDashboardSystemItemDto {
  readonly id: number;
  readonly level: EquipmentLevel;
  readonly name: string;
  readonly coverPhoto: string;
  readonly breadcrumbs: BreadcrumbsItem[];
  readonly warranty: boolean;
  readonly warrantyStatus: WarrantyStatusCode | null;
  readonly brand: string;
  readonly model: string;
  readonly runningHours: number;
  readonly lastUpdate: string;
  readonly color: BoatEquipmentMileageDateColor | null;
  readonly tasks: BoatMaintenanceDashboardSystemTasksDto | null;
  readonly service: EquipmentListCardServiceDto | null;
}

export interface BoatMaintenanceDashboardSystemTasksDto {
  readonly open: number;
  readonly overdue: number;
}

export interface BoatMaintenanceDashboardDownloadReport {
  readonly display: boolean;
  readonly data: BoatMaintenanceGeneralUpdateModalData | null;
}

export type BoatMaintenanceOpenTasksAlertItem = BoatMaintenanceTasksAlertItem<
Partial<BoatMaintenanceTasksLogPayload | BoatDocumentsFilterParams>>

export type BoatMaintenanceTasksAlertsItem = BoatMaintenanceBoatDocumentAlertsDto | BoatMaintenanceTasksOpenAlertsDto;

export type BoatMaintenanceTasksAlertsFilters = {
  [key in keyof BoatMaintenanceTasksAlertsItem]:
  BoatMaintenanceTasksAlertsItem[key];
}

export const IS_FLEET_MAINTENANCE = new InjectionToken<boolean>('Is fleet maintenance data', {
  factory: () => false,
});
