export class ComparisonReportByYearsDto {
  constructor(public readonly items: ComparisonReportItemDto[]) {
  }
}

export class ComparisonReportItemDto {
  constructor(
    public readonly year: number,
    public readonly monthExpenses: Array<number | null>,
  ) {
  }
}

export class YearExpenseReport {
  constructor(
    public readonly firstYear: Array<number | null> = [],
    public readonly secondYear: Array<number | null> = [],
  ) {
  }
}

export class ComparisonReport {
  constructor(
    public readonly items: ComparisonReportItemDto[],
    public readonly years: number[],
  ) {
  }
}

export class YearExpenseForm {
  constructor(
    public firstYear: number | null = null,
    public secondYear: number | null = null,
  ) {
  }
}
