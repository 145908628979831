<div
  class="pie-chart-wrapper"
  #pieChartContainer
  appBoatCurrency
  [addCurrency]="false"
  (currencyChange)="onChangeCurrency($event)">
  <div class="chart-container" [ngStyle]="{height: height, 'min-width': minWidth}">
    <canvas #chart>{{ chart }}</canvas>
  </div>
</div>
