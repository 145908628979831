import {UserPermissions} from '@modules/permissions';

export const APA_REPORT_PERMISSIONS: UserPermissions[] = [
  'perm_charter_read_apa_report_charterer_view',
  'perm_charter_read_apa_report_yacht_view',
  'perm_charter_see_and_edit_own_apa_expenses',
  'perm_charter_edit_all_apa_expenses',
  'perm_charter_delete_all_apa_expenses',
];

export const ALL_CHARTER_PERMISSIONS: UserPermissions[] = [
  'perm_charter_read_charters',
  'perm_charter_create_edit_delete_charter_and_guests',
  'perm_charter_download_guest_list',
  'perm_charter_overview',
  'perm_charter_calendar',
  'perm_charter_download_report_charter_overview',
  'perm_charter_add_apa_expenses',
  'perm_charter_download_apa_report',
  'perm_boat_calendar_see_calendar_and_events',
  'perm_boat_calendar_see_create_edit_calendar_and_events',
  'perm_boat_calendar_see_create_edit_delete_calendar_and_events',
  ...APA_REPORT_PERMISSIONS,
];
