import {cleanObject} from '@helpers/clean-object';

import {BoatFlagDto} from './boat.model';

import {CompanyDto} from '../auth';
import {CrewPreviewDto} from '../crew';
import {UserRoleType} from '../directories';
import {JobOfferLocation} from '../job-offers';
import {AisLocationDto} from '../location';
import {PaginationDto} from '../response';

export class SelfRoleDto {
  constructor(
    public readonly roleKey: UserRoleType,
    public readonly roleName: string,
  ) {
  }
}

export class BoatCurrency {
  constructor(
    public readonly name: string,
    public readonly badge: string,
    public readonly isSelected: boolean,
  ) {
  }
}

export class BoatPreviewDto {
  constructor(
    public readonly boatId: number,
    public readonly image: string,
    public readonly name: string,
    public readonly shortName: string,
    public readonly lastExpensesUploading: string,
    public readonly currency: BoatCurrency,
    public readonly pettyCashBalance: number,
    public readonly captain: string,
    public readonly brand: string,
    public readonly boatLength: number,
    public readonly myCrew: CrewPreviewDto[],
    public readonly selfRole: SelfRoleDto,
    public readonly subscription: false,
    public readonly company: CompanyDto | null,
    public readonly location: AisLocationDto | null,
    public readonly flag?: string,
  ) {
  }
}

export class BoatListDto {
  constructor(
    public readonly boatList: BoatPreviewDto[],
    public readonly pagination: PaginationDto,
  ) {
  }
}

export class BoatsListFilter {

  get params(): object {
    const page = this.page;
    const search = this.search;
    return cleanObject({page, search}, true);
  }

  constructor(
    public page: number = 1,
    public search: string = '',
  ) {
  }
}

export class BoatShortViewDto {
  constructor(
    public readonly name: string,
    public readonly image: string,
    public readonly boatType: string,
    public readonly boatLength: string,
    public readonly flag: BoatFlagDto,
    public readonly boatBrand: string,
    public readonly boatModel: string,
    public readonly builtYear: number,
    public readonly boatTonnage: string,
    public readonly maxCrew: number,
    public readonly maxGuest: number,
    public readonly location?: JobOfferLocation,
  ) {
  }
}

export class CanUserChangeBoatLocationDto {
  constructor(
    public readonly  canChange: boolean,
  ) {
  }
}

export class ChangeUserBoatPayload {
  constructor(
    public readonly boatId: number,
    public readonly locationName: string | null = null,
    public readonly locationLat: number | null = null,
    public readonly locationLng: number | null = null,
  ) {
  }
}
