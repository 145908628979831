import {FormControl} from '@angular/forms';
import {OverlayCloseEventType} from '@modules/modal/interfaces/overlay-close-event';
import {SeazoneIconColor} from '@modules/seazone-icons';

import {DateRangeDto} from '@controls/date-control/models/date-control';
import {SeazoneRadio} from '@controls/seazone-radio';
import {FormControlsData, Nullable} from '@helpers/utility-types';

import {
  Assignee,
  BoatMaintenanceStatisticFilterParams,
  BoatMaintenanceWorkListStatus,
  MaintenanceTaskGroupedCrewDto,
  TaskCategoryType,
  TaskDashboardCustomFilterType,
  TaskDirectoryItem,
  TaskWorkListDirectoryStatusColored,
  UnassignedMaintenanceCrewDto,
} from './boat-maintenance-shared';
import {BoatMaintenanceTaskLogTemplates} from './boat-maintenance-task-log-columns';
import {BoatMaintenanceShortWorkListItem} from './boat-maintenance-work-list';
import {BoatTaskQuoteStatus} from './boat-task-quotes';
import {EquipmentTreeItem, LastServiceHoursDto, WarrantyStatusCode} from './equipment.dto';

import {BoatPayloadDto, BoatShortInfoCharterListDto, BoatTimezoneDto} from '../boat';
import {EquipmentLevel} from '../boat-settings';
import {ListItemDto, UserRoleType} from '../directories';
import {ConditionalStatus} from '../general';
import {BadRequestStatusOptions, PaginationDto} from '../response';
import {UploadedFile} from '../uploaded-file';

export enum TaskCategoryTypeFilter {
  TotalCreated = 1,
  Open,
  Closed,
  PreviouslyClosed,
}

export enum TaskStatusType {
  Open = 1,
  Done,
  Canceled
}

export enum TaskType {
  Preventive = 1,
  BreakFault,
  Installation,
  Other
}

export enum TaskExtendType {
  Repair = 1,
  Replacement
}

export enum TaskPriorityType {
  OwnersRequest = 1,
  Urgent,
  WarrantyIssue,
}

export const isServiceCustomFilterType = (type: unknown): boolean => {
  return type === TaskDashboardCustomFilterType.NextService || type === TaskDashboardCustomFilterType.LastService;
};

export enum TaskDoneBy {
  Technician = 1,
  Crew,
  Tbd,
}

export enum TaskVisibility {
  all = 1,
  onlyMe,
  backOffice,
  headUsers,
}

export enum TaskServicesStatus {
  previous = 1,
  last,
  next,
  planned,
  expired
}

export enum TaskRepeatPeriodType {
  day = 1,
  week,
  month,
  year,
}

export enum TaskRepeatEndType {
  never = 1,
  on,
}

export enum TaskEditMode {
  onlyThis = 1,
  thisAndFollowing,
}

export enum FleetMaintenanceItemType {
  preventive = 1,
  installation,
  breakFault,
  other,
  totalCreatedTasksByPeriod,
  alreadyClosed,
  stillOpen,
  previouslyCreatedClosed,
  ownersRequests,
  urgent,
  drafts,
  unapprovedQuotes,
  expiredPreventive,
  expiredInstallation,
  expiredBreakFault,
  expiredOther,
}

export enum RepeatModalInstructionAction {
  remindTomorrow = 1,
  hideForever,
}

/**
 * List of types which should be redirected boat to task log info page
 */
export const TASK_LOG_INFO_FLEET_ITEM_TYPES: FleetMaintenanceItemType[] = [
  FleetMaintenanceItemType.preventive,
  FleetMaintenanceItemType.installation,
  FleetMaintenanceItemType.breakFault,
  FleetMaintenanceItemType.other,
  FleetMaintenanceItemType.totalCreatedTasksByPeriod,
  FleetMaintenanceItemType.alreadyClosed,
  FleetMaintenanceItemType.stillOpen,
  FleetMaintenanceItemType.previouslyCreatedClosed,
];

export interface TaskDirectoryStatusColored extends TaskDirectoryItem<TaskStatusType> {
  readonly color: string;
}

export interface TaskDirectoryVisibility extends Omit<TaskDirectoryItem<TaskVisibility>, 'cssClass'> {
  readonly description: string | null;
  readonly iconColor: SeazoneIconColor | null;
  /**
   * Accepted only by selected user roles. If value equals `null` - accepts all user roles.
   */
  readonly acceptedRoles: UserRoleType[] | null;
  readonly allowedForCreator?: boolean;
  readonly relatedForRepeats?: boolean;
  readonly relatedForService?: boolean;
}

export interface TaskDirectorySystems {
  readonly id: number;
  readonly name: string;
  readonly level: EquipmentLevel;
  readonly taskNumbers?: number;
  readonly allTasks?: number | null;
}

export interface TaskCategoryDirectoryItem<T> extends TaskDirectoryItem<T> {
  readonly children: TaskStatusType[];
}

export type TaskDirectoryCollectionKeys = keyof TaskDirectoryCollection;

export type TaskDirectoryItemType =
  TaskStatusType
  | TaskType
  | TaskExtendType
  | TaskPriorityType
  | TaskCategoryType
  | BoatMaintenanceWorkListStatus;

export interface TaskDirectoryCollection {
  readonly types: TaskDirectoryItem<TaskType>[];
  readonly extendedTypes: TaskDirectoryItem<TaskExtendType>[];
  readonly statuses: TaskDirectoryStatusColored[];
  readonly priorities: TaskDirectoryItem<TaskPriorityType>[];
  readonly categoryTypes: TaskCategoryDirectoryItem<TaskCategoryType>[];
  readonly workListStatuses: TaskWorkListDirectoryStatusColored[];
  readonly visibility: TaskDirectoryVisibility[];
}

export interface BoatMaintenanceTasksFiltersDirectories extends Omit<TaskDirectoryCollection, 'visibility'> {
  readonly assignees: MaintenanceTaskGroupedCrewDto;
  readonly systems: TaskDirectorySystems[] | null;
  readonly tags: string[];
  readonly workList: BoatMaintenanceShortWorkListItem[];
}

export interface BoatMaintenanceTaskDatesForm {
  readonly dueDate: string | null;
  readonly remindBeforeDue: number | null;
  readonly startDate: string | null;
  readonly remindBeforeStart: number | null;
}

export interface TaskRepeatForm {
  readonly every: FormControl<number>;
  readonly periodType: FormControl<TaskRepeatPeriodType>;
  readonly dateStart: FormControl<string>;
  readonly endType: FormControl<TaskRepeatEndType>;
  readonly dateEnd: FormControl<string | null>;
  readonly startRunningHours: FormControl<number | null>;
  readonly byEveryRunningHours: FormControl<number | null>;
}

export interface TaskStatusForm {
  readonly planedHours?: FormControl<number>;
  readonly planedDate: FormControl<string>;
  readonly actualHours?: FormControl<number>;
  readonly actualDate: FormControl<string>;
  readonly recalculateRunningHours?: FormControl<boolean>;
}

export type TaskStatusFormData = FormControlsData<TaskStatusForm>;

export type TaskRepeatFormData = FormControlsData<TaskRepeatForm> & Partial<TaskStatusFormData>;

export interface BoatMaintenanceTaskForm extends BoatMaintenanceTaskDatesForm {
  readonly boatTask: number | null;
  readonly name: string;
  readonly serviceTask: boolean | null;
  readonly system: number | null;
  readonly type: TaskType;
  readonly typeExtended?: TaskExtendType | null;
  readonly assignee: number | null;
  readonly priorities: TaskPriorityType[];
  readonly description: string | null;
  readonly tags: string[];
  readonly files: UploadedFile[] | null;
  readonly removeFiles: number[] | null;
  readonly workList: number | null;
  readonly doneByTechnician: boolean;
  readonly technicianCrewBy: boolean;
  readonly toBeAssigned: boolean;
  readonly status?: TaskStatusType;
  readonly editMode?: TaskEditMode;
  readonly repeatRule: TaskRepeatFormData | null;
  readonly estimateOriginalHour: number | null;
  readonly estimateOriginalMinute: number | null;
  readonly estimateActualHour: number | null;
  readonly estimateActualMinute: number | null;
  readonly charterRelated: boolean;
  readonly charter: number | null;
  readonly showInReport: boolean;
  readonly visibility: TaskDirectoryVisibility | TaskVisibility;
}

export interface DoneByData<T = ConditionalStatus> {
  readonly doneByTechnician: T | null;
  readonly technicianCrewBy: T | null;
  readonly toBeAssigned: T | null;
}

export interface TaskRepeatData {
  readonly runningHours: number | null;
  readonly repeat: { period: string, until: string | null };
}

export interface RepeatDataDto {
  readonly every: number;
  readonly periodType: TaskRepeatPeriodType;
  readonly dateStart: string | null;
  readonly endType: TaskRepeatEndType;
  readonly dateEnd: string | null;
  readonly byEveryRunningHours?: number | null;
  readonly isEndStatus?: boolean;
  readonly isRefreshList?: boolean;
  readonly startRunningHours: number | null;
}

export interface BoatMaintenanceTaskBasePayload extends DoneByData {
  [key: string]: unknown;

  readonly name: string;
  readonly system: number | null;
  readonly type: TaskType;
  readonly assignee: number | null;
  readonly priorities: TaskPriorityType[];
  readonly dueDate: string | null;
  readonly remindBeforeDue: number | null;
  readonly startDate: string | null;
  readonly remindBeforeStart: number | null;
  readonly description: string | null;
  readonly tags: string[];
  readonly boat: number;
  readonly typeExtended?: TaskExtendType | null;
  readonly workList?: number | null;
  readonly status?: number | null;
  readonly serviceTask: ConditionalStatus | null;
  readonly estimateOriginalHour: number | null;
  readonly estimateOriginalMinute: number | null;
  readonly estimateActualHour: number | null;
  readonly estimateActualMinute: number | null;
  readonly charter: number | null;
  readonly showInReport: ConditionalStatus;
  readonly visibility: TaskVisibility;
}

export interface BoatMaintenanceAddTaskPayload extends BoatMaintenanceTaskBasePayload {
  readonly repeatEvery: number | null;
  readonly repeatPeriodType: TaskRepeatPeriodType | null;
  readonly repeatDateStart: string | null;
  readonly repeatEndType: TaskRepeatEndType | null;
  readonly repeatDateEnd: string | null;
  readonly repeatByEveryRunningHours: number | null;
  readonly repeatStartRunningHours: number | null;
  readonly repeatActualHours: number | null;
  readonly repeatRecalculateRunningHours: ConditionalStatus | null;
}

export interface BoatMaintenanceEditTaskPayload extends BoatMaintenanceTaskBasePayload {
  readonly boatTask: number | null;
  readonly removeFiles: number[] | null;
  readonly editMode: TaskEditMode;
}

export interface BoatMaintenanceTasksCard {
  readonly id: number;
  readonly type: TaskType;
  readonly status: TaskStatusType;
  readonly system: TasksSystemDto | null;
  readonly assignee: BoatMaintenanceTasksAssignee;
  readonly name: string;
  readonly description: string;
  readonly files: BoatMaintenanceTaskFormFileDto[];
  readonly priorities: TaskPriorityType[];
  readonly dates: BoatMaintenanceTasksCardDates;
  readonly tags: string[];
  readonly typeExtended: TaskExtendType | null;
  readonly counters: TasksCardCounters;
  readonly image: string | null;
  readonly isViewed: boolean;
  readonly workList: BoatMaintenanceShortWorkListItem | null;
  readonly repeatNumber: number | null;
  readonly visibility: TaskVisibility;
}

export class TasksCardCounters {
  constructor(
    public readonly attachments: number = 0,
    public readonly notes: number = 0,
    public readonly totalNotes: number = 0,
    public readonly quotes: number = 0,
  ) {
  }
}

export interface BoatTaskInfoDto {
  readonly counters: TasksCardCounters;
}

export interface TasksSystemDto {
  readonly id: number;
  readonly breadcrumbs: TasksBreadCrumbsDto[];
}

export interface TasksBreadCrumbsDto {
  readonly id: number;
  readonly name: string;
}

export interface BoatMaintenanceTasksAssignee {
  readonly id: number;
  readonly fullName: string;
  readonly s3Url: string;
  readonly position?: string;
}

export interface BoatMaintenanceTasksCardDates {
  readonly startDate: string | null;
  readonly remindBeforeStart: number | null;
  readonly dueDate: string | null;
  readonly remindBeforeDue: number | null;
  readonly dateClosed: string | null;
  readonly dateCreate: string;
}

export interface BoatMaintenanceTasksInfoDto {
  readonly hasInbox: boolean;
  readonly countInbox?: number;
}

export interface BoatMaintenanceTasksList {
  readonly model: BoatMaintenanceTasksDay[];
  readonly counters: BoatMaintenanceTasksCounter;
}

export interface BoatMaintenanceTasksCounter {
  readonly filtered: number;
  readonly total: number;
  readonly expired: number;
  readonly inbox: number;
  readonly currentDay?: string;
  readonly totalDays?: string[];
}

export interface BoatMaintenanceTasksDay {
  readonly items: BoatMaintenanceTasksCard[];
  readonly count: number;
  readonly date: string | null;
  readonly totalCount: number;
  readonly nextDay?: string;
  readonly previousDay?: string;
}

export interface BoatMaintenanceTasksFiltersForm {
  statuses: TaskStatusType[];
  types: TaskType[] | null;
  priorities: TaskPriorityType[] | null;
  assignees: number[] | null;
  systems: number[] | null;
  startDateFrom: string | null;
  startDateTo: string | null;
  dueDateFrom: string | null;
  dueDateTo: string | null;
  tags: string[] | null;
  onlyMy: number | null;
  categoryTypes: TaskCategoryType[] | null;
  createdByMe: ConditionalStatus.YES | null;
  expired: boolean | null;
  inbox: boolean | null;
  search: string | null;
  initial: boolean | null;
  workLists: number[] | null;
}

export class BoatMaintenanceTasksFiltersModal {
  constructor(
    public readonly form: BoatMaintenanceTasksFiltersForm,
    public readonly directories: BoatMaintenanceTasksFiltersDirectories,
  ) {
  }
}

export interface BoatMaintenanceTasksFiltersPayload {
  readonly statuses: TaskStatusType[] | null;
  readonly types: TaskType[] | null;
  readonly priorities: TaskPriorityType[] | null;
  readonly assignees: number[] | null;
  readonly systems: number[] | null;
  readonly startDateFrom: string | null;
  readonly startDateTo: string | null;
  readonly dueDateFrom: string | null;
  readonly dueDateTo: string | null;
  readonly tags: string[] | null;
  readonly expired: ConditionalStatus.YES | null;
  readonly createdByMe: ConditionalStatus.YES | null;
  readonly inbox: ConditionalStatus.YES | null;
  readonly search: string | null;
  readonly initial: ConditionalStatus.YES | null;
  readonly workLists: number[] | null;
}

export interface BoatMaintenanceTasksPayload extends BoatMaintenanceTasksFiltersPayload {
  boat: number;
  date: string | null;
}

export interface FleetAssignee<T extends 'crew' | 'head'> extends Omit<Assignee, 'crewId' | 'positionId' | 'userRole'> {
  readonly crewIds: number[];
  readonly type: T;
}

export interface FleetCurrentUser extends Assignee {
  readonly crewIds: number[];
}

export class MaintenanceFleetTaskGroupCrewDto {
  constructor(
    public readonly unassigned: UnassignedMaintenanceCrewDto,
    public readonly currentUser: FleetCurrentUser | null = null,
    public readonly crew: FleetAssignee<'crew'>[] = [],
    public readonly head: FleetAssignee<'head'>[] = [],
  ) {
  }
}

export interface BoatMaintenanceTaskFormDirectories {
  readonly types: TaskDirectoryItem<TaskType>[];
  readonly extendedTypes: TaskDirectoryItem<TaskExtendType>[];
  readonly priorities: TaskDirectoryItem<TaskPriorityType>[];
  readonly statuses: TaskDirectoryItem<TaskStatusType>[];
  readonly visibility: TaskDirectoryVisibility[];
  readonly systems: EquipmentTreeItem[];
  readonly crewList: MaintenanceTaskGroupedCrewDto;
  readonly defaultAssigneeId: number | null;
  readonly boatTimezone: BoatTimezoneDto,
  readonly workList: BoatMaintenanceShortWorkListItem[];
  readonly editMode: SeazoneRadio<TaskEditMode>[];
  readonly charters: BoatShortInfoCharterListDto;
}

export type BoatTaskModalCloseEvent = OverlayCloseEventType | 'updated' | 'deleted' | 'added';

export type BoatMaintenanceTaskFormMode = 'create' | 'edit';

export interface DoneByForm {
  readonly doneByTechnician: boolean;
  readonly technicianCrewBy: boolean;
}

export interface TaskPlannedActualData {
  readonly actualDate: string;
  readonly actualHours: number;
  readonly plannedDate: string;
  readonly plannedHours: number;
}

export interface BoatMaintenanceTaskFormDto extends BoatMaintenanceTaskDatesForm {
  readonly id: number;
  readonly type: TaskType;
  readonly typeExtended: TaskExtendType | null;
  readonly status: TaskStatusType;
  readonly systemId: number;
  readonly assigneeId: number | null;
  readonly name: string;
  readonly description: string | null;
  readonly priorities: number[];
  readonly tags: string[];
  readonly files: BoatMaintenanceTaskFormFileDto[];
  readonly dateClosed: string | null;
  readonly dateCreate: string | null;
  readonly dateUpdate: string | null;
  readonly tree: BoatMaintenanceTaskFormTreeItem;
  readonly workList: number | null;
  readonly canChangeWorkList?: boolean;
  readonly doneByTechnician?: ConditionalStatus;
  readonly technicianCrewBy?: ConditionalStatus;
  readonly toBeAssigned?: ConditionalStatus;
  readonly serviceTask: ConditionalStatus | null;
  readonly doneBy: DoneByForm;
  readonly repeatRule: RepeatDataDto | null;
  readonly repeatNumber: number | null;
  readonly estimateOriginalHour: number | null;
  readonly estimateOriginalMinute: number | null;
  readonly estimateActualHour: number | null;
  readonly estimateActualMinute: number | null;
  readonly charter: number | null;
  readonly showInReport: ConditionalStatus | null;
  readonly visibility: TaskVisibility;
  readonly creatorId: number;
  readonly plannedServiceHours: number | null;
  readonly canEditRepeatRule: boolean;
  readonly plannedActualData: Nullable<TaskPlannedActualData>;
  readonly editRulMessage: string | null;
  readonly repeatDateRange: DateRangeDto;
  readonly showRepeatInstruction: boolean;
}

export interface BoatMaintenanceTaskFormTreeItem {
  readonly id: number;
  readonly level: EquipmentLevel;
  readonly name: string;
  readonly hide: boolean;
  readonly warranty: WarrantyStatusCode | null;
  readonly children: BoatMaintenanceTaskFormTreeItem | null;
  readonly runningHours: number | null;
  readonly dateLastUpdated: string | null;
  readonly dateLastService: string | null;
}

export interface BoatMaintenanceTaskFormFileDto {
  readonly id: number;
  readonly fileName: string;
  readonly originalFileName: string;
  readonly url: string;
}

export interface BoatMaintenanceTaskStateOptions {
  readonly status?: TaskStatusType;
  readonly assignee?: number;
  readonly dueDate?: string;
}

export class BoatMaintenanceTaskStatePayload {

  readonly boat: number;
  readonly boatTasks: number[];
  readonly status?: TaskStatusType;
  readonly assignee?: number;
  readonly dueDate?: string;
  readonly workList?: number;

  constructor(
    boat: number,
    boatTasks: number[],
    options: {
      status?: TaskStatusType,
      assignee?: number | null,
      dueDate?: string,
      workList?: number | null,
    },
  ) {
    this.boat = boat;
    this.boatTasks = boatTasks;
    if (options?.status) {
      this.status = options.status;
    }
    if (options?.assignee) {
      this.assignee = options.assignee;
    }
    if (options?.dueDate) {
      this.dueDate = options.dueDate;
    }
    if (options?.workList) {
      this.workList = options?.workList;
    }
  }
}

export interface BoatMaintenanceDefaultAssigneeForm {
  readonly assignee: number | null;
}

export class BoatMaintenanceDefaultAssigneeDirectories {
  constructor(
    public readonly selectedAssignee: number | null,
    public readonly crewList: MaintenanceTaskGroupedCrewDto,
  ) {
  }
}

export interface BoatMaintenanceTaskActionDto {
  readonly id: number;
  readonly status: TaskStatusType;
  readonly workListStatus: BoatMaintenanceWorkListStatus | null;
  readonly visibility: TaskVisibility;
  readonly repeatNumber: number | null;
}

export interface BoatMaintenanceTasksCalendarPayload extends BoatMaintenanceTasksFiltersPayload {
  boat: number;
  month: string | null;
}

export interface BoatMaintenanceTasksCalendarDto {
  readonly list: BoatMaintenanceCalendarDayDto[];
  readonly foundDate: string | null;
}

export interface BoatMaintenanceCalendarDayDto {
  readonly date: string;
  readonly count: number;
}

export interface BoatMaintenanceTasksPagination {
  readonly previousDate: string | null;
  readonly enabledPrevious: boolean;
  readonly nextDate: string | null;
  readonly enabledNext: boolean;
  readonly lastDate: string | null;
}

export interface BoatMaintenanceTasksLogPayload
  extends Omit<BoatMaintenanceTasksPayload, 'date' | 'createdByMe' | 'initial'> {
  readonly createDateFrom: string | null;
  readonly createDateTo: string | null;
  readonly updateDateFrom: string | null;
  readonly updateDateTo: string | null;
  readonly changeStatusDateFrom: string | null;
  readonly changeStatusDateTo: string | null;
  readonly name: string | null;
  readonly creators: number[] | null;
  readonly description: string | null;
  readonly page: number;
  readonly doneBy: number[] | null;
  readonly pageSystem: ConditionalStatus.YES | null;
  readonly approvedCost: ConditionalStatus[] | null;
  readonly actualCost: ConditionalStatus[] | null;
  readonly quotes: ConditionalStatus[] | null;
  readonly services: TaskServicesStatus[] | null;
  readonly repeatLimit: number | null;
  readonly workListDescription: string | null;
  readonly estimateOriginal: ConditionalStatus[] | null;
  readonly estimateActual: ConditionalStatus[] | null;
  readonly charters: number[] | null;
}

export type BoatMaintenanceTasksLogFormPayload =
  Omit<BoatMaintenanceTasksLogPayload, 'boat' | 'expired' | 'inbox' | 'search' | 'page' | 'pageSystem' | 'repeatLimit'>;

export interface BoatMaintenanceTasksLogTemplateQueryParam {
  readonly template?: BoatMaintenanceTaskLogTemplates,
}

export interface QuoteCountersDto {
  readonly actual: number | null;
  readonly approved: number | null;
}

export interface WorkingTimeCountersDto {
  readonly actualHours: string;
  readonly estimatedHours: string;
  readonly workingDays: number | null;
}

export interface BoatMaintenanceTasksLogDto {
  readonly models: BoatMaintenanceTasksLogItemDto[];
  readonly pagination: PaginationDto;
  readonly quoteCounters: QuoteCountersDto;
  readonly workingCounters: WorkingTimeCountersDto;
}

export interface BoatMaintenanceQuoteDto {
  readonly id: number;
  readonly amount: number;
  readonly order: number;
  readonly status: BoatTaskQuoteStatus;
}

export interface DoneByDto {
  readonly isDoneByTechnician: boolean;
  readonly technicianCrewBy: boolean;
  readonly isToBeAssigned: boolean;
}

export interface BoatMaintenanceTasksLogItemDto {
  readonly id: number;
  readonly group: number;
  readonly type: TaskType;
  readonly typeExtended: TaskExtendType | null;
  readonly status: TaskStatusType;
  readonly system: TasksSystemDto | null;
  readonly assignee: BoatMaintenanceTasksLogUserDto;
  readonly creator: BoatMaintenanceTasksLogUserDto;
  readonly name: string;
  readonly description: string;
  readonly priorities: TaskPriorityType[];
  readonly dates: BoatMaintenanceTasksLogDatesDto;
  readonly isViewed: boolean;
  readonly counters: TasksCardCounters;
  readonly workList: BoatMaintenanceShortWorkListItem | null;
  readonly quotes: BoatMaintenanceQuoteDto[];
  readonly approvedCost: number | null;
  readonly actualCost: number | null;
  readonly service: TaskServicesStatus | null;
  readonly repeatNumber: number | null;
  readonly estimateOriginal: string | null;
  readonly estimateActual: string | null;
  readonly tags: string[] | null;
  readonly charter: ListItemDto | null;
  readonly doneBy: DoneByDto | null;
  readonly visibility: TaskVisibility;
}

export interface BoatMaintenanceTasksLogDatesDto {
  readonly startDate: string | null;
  readonly dueDate: string | null;
  readonly dateClosed: string | null;
  readonly dateCreate: string;
  readonly dateUpdate: string | null;
  readonly changeStatusDateUpdate: string | null;
}

export interface BoatMaintenanceTasksLogUserDto {
  readonly id: number;
  readonly fullName: string;
  readonly position: string;
}

export interface BoatMaintenanceTaskLogInfoParams extends BoatMaintenanceStatisticFilterParams {
  readonly page: number;
  readonly customFilterType: TaskDashboardCustomFilterType | null;
  readonly types: TaskType[] | null;
  readonly statuses: TaskStatusType[] | null;
  readonly systemName: string | null;
  readonly ids: number[] | null;
}

export interface BoatMaintenanceTaskLogInfoPayload extends BoatMaintenanceTaskLogInfoParams {
  readonly  boat: number;
}

export interface BoatMaintenanceSettingsPayload {
  readonly boat: number;
  readonly template: number;
}

/**
 * Model which describes list item of possible periods.
 */
export interface BoatMaintenanceTaskRepeatPeriodType {
  /**
   * @param id period type
   */
  readonly id: TaskRepeatPeriodType;
  /**
   * name displaying in dropdown and form badges
   */
  readonly name: string;
  /**
   * @param unit Moment.js unit type needed for fields validation
   */
  readonly unit: moment.unitOfTime.DurationConstructor;
}

export interface BoatMaintenanceTaskRepeatPayload {
  readonly boat: number;
  readonly task: number;
}

export interface UpdateBoatMaintenanceTaskRepeatPayload extends BoatMaintenanceTaskRepeatPayload {
  readonly every: number;
  readonly periodType: TaskRepeatPeriodType;
  readonly dateStart: string;
  readonly endType: TaskRepeatEndType;
  readonly dateEnd: string | null;
  readonly startRunningHours?: number | null;
  readonly byEveryRunningHours?: number | null;
}

export type BoatMaintenanceTaskRepeatValidationPayload =
  Partial<Omit<UpdateBoatMaintenanceTaskRepeatPayload, 'task'>>
  & {
    readonly system: number | null;
    readonly actualHours: number | null;
    readonly actualDate: string | null;
    readonly planedDate: string | null;
  };

/**
 * Query params for Task Log page
 */
export type TaskLogRouteParams = Partial<
BoatMaintenanceTasksLogPayload &
BoatMaintenanceTasksLogTemplateQueryParam>;

/**
 * Query params for Task Log Info page
 */
export type TaskLogInfoRouteParams = Partial<BoatMaintenanceTaskLogInfoParams>;

export interface BoatMaintenanceInfo {
  readonly shortName: string;
  readonly name: string;
  readonly boatLength: number;
}

export interface ExternalTaskListItemDto {
  readonly id: number;
  readonly name: string;
  readonly repeatNumber: number;
  readonly type: TaskType;
  readonly typeExtended: TaskExtendType | null;
  readonly image: string | null;
  readonly dates: Pick<BoatMaintenanceTaskDatesForm, 'startDate' | 'dueDate'>;
  readonly status: TaskStatusType;
  readonly assignee: BoatMaintenanceTasksAssignee | null;
  readonly counters: TasksCardCounters;
  readonly doneBy: DoneByDto | null;
}

export interface BoatTaskTagsPayload {
  readonly search: string;
  readonly boat: number;
  readonly withTask?: ConditionalStatus;
}

export interface SelectedSystem {
  readonly path: EquipmentTreeItem[];
  readonly item: EquipmentTreeItem;
}

export enum ValidateRepeatTipType {
  HOURS = 1,
  HOURS_AND_DATE,
  DATE,
}
export interface ValidateRepeatErrorPayload {
  readonly planedHours: number;
  readonly planedDate: string;
  readonly actualHours: number;
  readonly actualDate: string;
  readonly disabledActualDate: boolean;
  readonly hideHours: boolean;
  readonly disabledActualHours?: boolean;
  readonly repeatNumber?: number;
  readonly tipType?: ValidateRepeatTipType;
  readonly tipMessage?: string;
  readonly name?: string;
  readonly dateRange?: Nullable<DateRangeDto>;
  readonly id?: Nullable<number>;
  readonly description: string;
  readonly additional: Nullable<LastServiceHoursDto>;
}

interface ValidateRepeatErrorData {
  readonly statusOptions: BadRequestStatusOptions.INVALID_REPEAT_FORM;
  readonly payloadData: ValidateRepeatErrorPayload;
}

type RepeatFormErrors = {
  readonly form: Partial<Record<keyof TaskStatusFormData, string>>;
};

export type ValidateRepeatError = {
  readonly errors: ValidateRepeatErrorData | RepeatFormErrors;
  readonly message: string;
  readonly success: false;
};

export const isValidateRepeatError =
  (error: ValidateRepeatError | unknown): error is ValidateRepeatError =>
    (error as ValidateRepeatError).errors !== undefined;

export const isRepeatFormErrors =
  (errors: ValidateRepeatErrorData | RepeatFormErrors): errors is RepeatFormErrors =>
    (errors as RepeatFormErrors).form !== undefined;

export const isValidateRepeatErrorPayload =
  (errors: ValidateRepeatErrorData | unknown): errors is ValidateRepeatErrorData => {
    const statusOptions = (errors as ValidateRepeatErrorData).statusOptions;
    return statusOptions === BadRequestStatusOptions.INVALID_REPEAT_FORM;
  };

export interface ExpiredTaskDataDto extends ValidateRepeatErrorPayload {
  readonly id: number;
  readonly systemId: number;
}

export interface UpdateExpiredRepeatTaskPayload extends BoatPayloadDto {
  readonly task: number;
  readonly actualHours: number | null;
  readonly actualDate: string | null;
  readonly recalculate: ConditionalStatus | null;
}

export interface RepeatModalInstructionPayload extends BoatPayloadDto {
  readonly state: RepeatModalInstructionAction;
}
