import {SeazoneIconColor} from '@modules/seazone-icons';

import {GroupRange} from '../shared';

export enum ActivitiesLabels {
  Available = 1,
  Busy,
  Charter,
  OwnersCharters,
  Trip,
  AllInclusive,
  Delivery,
  Shipyard,
  Custom,
}

export enum FleetActivityPeriod {
  Today = 'today',
  Custom = 'custom'
}

export class FleetSwitchPeriodDto {
  constructor(
    public readonly label: string,
    public readonly period: FleetActivityPeriod,
  ) {
  }
}

export class ActivitiesLabelsList {
  constructor(
    public readonly id: ActivitiesLabels,
    public readonly name: string,
    public readonly backgroundColor: string | null  = null,
    public readonly color: string | null = null,
    public readonly iconColor: SeazoneIconColor | null = null,
    public readonly main: boolean = false,
  ) { }
}

export interface FleetActivityDto {
  readonly boatCounts: number;
  readonly activities: ActivitiesDto[] | null;
}

export interface FleetActivityFilteredDto {
  readonly boatCounts: number;
  readonly activities: ActivitiesList[];
}

export interface ActivitiesDto {
  readonly type: ActivitiesLabels;
  readonly count: number;
}

export class ActivitiesList {
  constructor(
    public id: number,
    public label: string,
    public count: number,
    public backgroundColor: string | null,
    public color: string | null,
    public iconColor: SeazoneIconColor | null,
    public main: boolean,
  ) {
  }
}

export class ActivityPayloadDto {
  constructor(
    public dateStart: string,
    public dateEnd: string,
  ) {
  }
}

export class FleetActivityPayloadDto {
  constructor(
    public readonly fleet: number | null,
    public dateStart: string | null,
    public dateEnd: string | null,
  ) {
  }
}

export interface FleetActivityForm {
  readonly date: GroupRange<string>;
}

export interface FleetActivityDate {
  readonly startDate: string;
  readonly endDate: string;
}
