import {Params} from '@angular/router';

import {cleanObject} from '@helpers/clean-object';

import {CurrencyDto} from '../directories';
import {PaginationDto} from '../response';

export enum FleetBoatPermissionsGroup {
  OpenJobOffer = 1,
  ViewCharter,
  EditCharter,
  AddApa,
  OpenApa,
  AddCharterExpenses,
  OpenCharterExpenses,
  BoatDocumentView,
  BoatDocumentDownload,
  BoatDocumentEdit,
  BoatDocumentDelete,
  OpenBoatCalendar,
  ListCharter,
  EditCalendarEvents,
  Maintenance,
  Expenses,
  TasksList,
  ChangeTaskStatus,
}

export enum FleetBoatDeniedReason {
  FleetPermission = 1,
  BoatWithoutSubscription
}

export class BoatFleets {
  constructor(
    public readonly models: FleetList[] | null,
    public readonly pagination: PaginationDto,
  ) {
  }
}

export class BoatFleetsPayload {
  constructor(
    public boat: number,
    public page,
    public limit: number,
  ) {
  }
}

export class BoatFleetFilterParams {

  get params(): Params {
    const page = this.page;
    return cleanObject({page}, true);
  }

  constructor(
    public boat?: number,
    public page: number = 1,
    public limit?: number,
  ) {
  }
}

export class BoatSettingBoatIdPayload {
  constructor(
    public readonly boat: number,
  ) {
  }
}

export class BoatFleetDeletePayload {
  constructor(
    public fleet: number,
    public boat: number,
  ) {
  }
}

export class FleetList {
  constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly description: string,
    public readonly image: string,
    public readonly currency: CurrencyDto,
  ) {
  }
}

export interface BoatSettingFleetDto {
  readonly id: number;
  readonly currency: CurrencyDto;
  readonly name: string;
  readonly description: string;
  readonly image: string;
}

type PermissionsPayloadUsefulData = `useful[${'taskId'}]`;

export type FleetBoatCheckPermissionsPayload = {
  readonly [key in PermissionsPayloadUsefulData]?: unknown;
} & {
  readonly boat: number;
  readonly group: FleetBoatPermissionsGroup;
};

export interface FleetBoatCheckPermissionsDto {
  access: boolean;
  reason: FleetBoatDeniedReason | null;
}
