import {SeazoneIcon, SeazoneIconColor} from '../../modules/seazone-icons';
import {FileListItemI} from '../file';
import {UploadedFile} from '../uploaded-file';

export interface BoatEquipmentNotesForm {
  readonly text: string;
  readonly files?: UploadedFile[] | null;
  readonly removeFiles?: number[] | null;
}

export class AddBoatEquipmentNotePayload {
  constructor(
    public readonly boat: number,
    public readonly system: number,
    public readonly text: string,
  ) {
  }
}

export class UpdateBoatEquipmentNotePayload extends AddBoatEquipmentNotePayload {
  constructor(
    boat: number,
    system: number,
    text: string,
    public readonly systemEquipmentNote: number,
  ) {
    super(boat, system, text);
  }
}

export class BoatEquipmentNotesPayload {
  constructor(
    public readonly boat: number,
    public readonly system: number,
    public readonly page: number,
    public readonly limit: number,
  ) {
  }
}

export enum BoatMaintenanceNotesType {
  User = 1,
  System = 2,
}

export interface BoatMaintenanceNoteItemDataDto {
  readonly name: string;
  readonly newValue: string;
  readonly oldValue: string;
}

export interface BoatMaintenanceNoteItemDto {
  readonly id: number;
  readonly user: BoatEquipmentNoteUserDto;
  readonly type: BoatMaintenanceNotesType;
  readonly text: string;
  readonly dateUpdate: number;
  readonly data: BoatMaintenanceNoteItemDataDto[];
  readonly files?: FileListItemI[];
}

export interface BoatEquipmentNoteUserDto {
  readonly id: number;
  readonly name: string;
  readonly position: string | null;
}

export class BoatTaskNotesFilters {
  constructor(
    public readonly type: BoatMaintenanceNotesType | null = null,
    public readonly page = 1,
  ) {
  }
}

export class BoatTaskNotesPayload {
  constructor(
    public readonly boat: number,
    public readonly boatTask: number,
    public readonly page: number,
    public readonly type: BoatMaintenanceNotesType | null,
    public readonly limit: number,
  ) {
  }
}

export interface BoatMaintenanceTaskNote extends Omit<BoatMaintenanceTaskNoteDto, 'files'>{
  readonly files: UploadedFile[] | null;
}

export interface BoatMaintenanceTaskNoteDto {
  readonly id: number;
  readonly user: BoatEquipmentNoteUserDto;
  readonly type: BoatMaintenanceNotesType;
  readonly text: string;
  readonly dateUpdate: number;
  readonly files?: FileListItemI[] | null;
}

export interface BoatTaskNoteMedia {
  readonly fileName: string;
  readonly originalFileName: string;
}

export interface AddBoatTaskNotePayload {
  readonly boat: number;
  readonly boatTask: number;
  readonly text: string;
  readonly [key: string]: unknown;
}

export interface UpdateBoatTaskNotePayload extends AddBoatTaskNotePayload {
  boatTaskNote: number;
  removeFiles: number[];
}

export interface BoatTaskNoteFilterChips {
  readonly id: BoatMaintenanceNotesType;
  readonly name: string;
  readonly icon: SeazoneIcon;
  readonly iconColor: SeazoneIconColor;
}
