import {Injectable} from '@angular/core';
import {plainToInstance} from 'class-transformer';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {TransferHttpService} from '@core/modules/transfer-http/transfer-http.service';
import {buildURLParams} from '@helpers/build-url-params';
import {httpParamsFromObject} from '@helpers/http-params';
import {
  AddBoatDto,
  AddBoatResponseDtoI,
  BoatAisNotifyDto,
  BoatDto,
  BoatGeneralInfoDtoI,
  BoatListDto,
  BoatParamsPayload,
  BoatPayload,
  BoatShortInfoDto,
  BoatShortViewDto,
  BoatSupplierDetailsDto,
  BoatTaskDefaultAssigneePayload,
  BoatUpdateParams,
  BoatUpdatePayload,
  CanUserChangeBoatLocationDto,
  ChangeUserBoatPayload,
} from '@models/boat';
import {
  AddDocumentPayload,
  AddFolderPayload,
  BoatDocumentDto,
  BoatDocumentFilteredListDto,
  BoatDocumentFolderListDto,
  BoatDocumentFolderListPayload,
  BoatDocumentListDto,
  BoatDocumentsPayload,
  BoatDocumentVersionsListDto,
  BoatDocumentVersionsPayload,
  DeleteFolderPayload,
  DocumentStatePayload,
  EditFolderPayload,
  FavoriteDocumentPayload,
  RestoreDocumentPayload,
  SearchBoatDocumentsPayload,
  UpdateDocumentPayload,
} from '@models/boat-documents';
import {BoatPettyCashTableDto} from '@models/boat-petty-cash';
import {TableDataDto} from '@models/dashboard';
import {ExchangeRateDto} from '@models/directories/currency.model';
import {ExpenseCurrencyRateInfoDto, ExpenseCurrencyRateInfoParams} from '@models/expense';
import {ResponseDto} from '@models/response';

@Injectable({
  providedIn: 'root',
})
export class BoatService {

  private readonly apiUrl = 'api/boat';

  private readonly apiUrlDash = 'api/dashboard';

  private readonly apiDocuments = 'api/boat-document';

  private readonly apiCompany = 'api/company';

  constructor(private readonly http: TransferHttpService) {
  }

  boatsList(page: number, search: string, limit = 5): Observable<BoatListDto> {
    const params = httpParamsFromObject({page, limit, search});
    return this.http.get<ResponseDto<BoatListDto>>(`${this.apiUrl}/get-my-boat-list`, {params}).pipe(
      map(response => response.data),
    );
  }

  getBoat(boatId: number): Observable<BoatDto> {
    return this.getBoatData<BoatDto>(`${this.apiUrl}/get-profile-boat-edit`, {boatId}).pipe(
      map(response => response.data),
    );
  }

  addBoat(payload: AddBoatDto): Observable<ResponseDto<AddBoatResponseDtoI>> {
    return this.http.post(`${this.apiUrl}/create-boat`, payload);
  }

  updateBoat(payload: BoatUpdatePayload, updateParams: BoatUpdateParams): Observable<ResponseDto<BoatDto>> {
    const params = httpParamsFromObject(updateParams);
    return this.http.put(`${this.apiUrl}/edit-boat`, payload, {params});
  }

  getBoatGeneralInfo(boatId: number): Observable<ResponseDto<BoatGeneralInfoDtoI>> {
    return this.getBoatData<BoatGeneralInfoDtoI>(`${this.apiUrl}/get-profile-boat-info`, {boatId});
  }

  // TODO: remove plainToInstance
  getBoatShortInfo(boatId: number): Observable<BoatShortInfoDto> {
    return this.getBoatData<BoatShortInfoDto>(`${this.apiUrl}/get-boat-short-info`, {boatId}).pipe(
      map(response => response.data),
      map(response => plainToInstance(BoatShortInfoDto, response)),
    );
  }

  paymentMethods(payload: BoatParamsPayload): Observable<ResponseDto<TableDataDto>> {
    return this.getBoatData<TableDataDto>(`${this.apiUrlDash}/payment-methods`, payload);
  }

  monthlyReportByDepartments$(boatId: number): Observable<ResponseDto<TableDataDto>> {
    return this.getBoatData<TableDataDto>(`${this.apiUrlDash}/month-department-transactions`, {boatId});
  }

  monthlyReportByMainCategory(payload: BoatParamsPayload): Observable<ResponseDto<TableDataDto>> {
    return this.getBoatData<TableDataDto>(`${this.apiUrlDash}/month-report-by-category`, payload);
  }

  monthReportBySubcategory(payload: BoatParamsPayload): Observable<ResponseDto<TableDataDto>> {
    return this.getBoatData<TableDataDto>(`${this.apiUrlDash}/month-report-by-subcategory`, payload);
  }

  monthReportBySubcategoryYtd(payload: BoatParamsPayload): Observable<ResponseDto<TableDataDto>> {
    return this.getBoatData<TableDataDto>(`${this.apiUrlDash}/month-report-by-subcategory-ytd`, payload);
  }

  private getBoatData<T>(url: string, payload: BoatPayload | BoatParamsPayload): Observable<ResponseDto<T>> {
    const params = httpParamsFromObject(payload);
    return this.http.get(url, {params});
  }

  uploadExpenseFile(boatId: number, importFile: File, charterId: number | null): Observable<ResponseDto> {
    return this.http.post('api/boat-finance/upload-finance-data', {boatId, importFile, charterId});
  }

  getCurrencyExchangeRate(boatId: number, currencyId: number): Observable<ExchangeRateDto> {
    const params = httpParamsFromObject({boatId, currencyId});
    return this.http.get<ResponseDto<ExchangeRateDto>>(`${this.apiUrl}/get-exchange-rate-to-new-currency`, {params}).pipe(
      map(response => response.data),
    );
  }

  updateBoatCurrency(boatId: number, currencyId: number, exchangeRate: number): Observable<ResponseDto> {
    return this.http.put(`${this.apiUrl}/change-currency-default`, {boatId, currencyId, exchangeRate});
  }

  boatFolders(payload: BoatDocumentFolderListPayload): Observable<BoatDocumentFolderListDto> {
    const params = httpParamsFromObject(payload);
    return this.http.get<ResponseDto<BoatDocumentFolderListDto>>(`${this.apiDocuments}/get-folders`, {params}).pipe(
      map(response => response.data),
    );
  }

  boatDocuments(payload: BoatDocumentsPayload): Observable<BoatDocumentFilteredListDto> {
    const params = httpParamsFromObject(payload);
    return this.http.get<ResponseDto<BoatDocumentFilteredListDto>>(`${this.apiDocuments}/get-documents`, {params}).pipe(
      map(response => response.data),
    );
  }

  boatFolderDocuments(payload: BoatDocumentFolderListPayload): Observable<BoatDocumentListDto> {
    const params = httpParamsFromObject(payload);
    return this.http.get<ResponseDto<BoatDocumentListDto>>(
      `${this.apiDocuments}/get-folder-documents`,
      {params},
    ).pipe(
      map(response => response.data),
    );
  }

  searchBoatDocuments(payload: SearchBoatDocumentsPayload): Observable<BoatDocumentListDto> {
    const params = httpParamsFromObject(payload);
    return this.http.get<ResponseDto<BoatDocumentListDto>>(`${this.apiDocuments}/search-documents`, {params}).pipe(
      map(response => response.data),
    );
  }

  boatFavoriteDocuments(payload: BoatPayload): Observable<BoatDocumentDto[]> {
    const params = httpParamsFromObject(payload);
    return this.http.get<ResponseDto<BoatDocumentDto[]>>(`${this.apiDocuments}/get-favorites`, {params}).pipe(
      map(response => response.data),
    );
  }

  addBoatFolder(payload: AddFolderPayload): Observable<ResponseDto> {
    return this.http.post<ResponseDto>(`${this.apiDocuments}/create-folder`, payload);
  }

  editBoatFolder(payload: EditFolderPayload): Observable<ResponseDto> {
    return this.http.put(`${this.apiDocuments}/edit-folder`, payload);
  }

  deleteBoatFolder(payload: DeleteFolderPayload): Observable<ResponseDto> {
    const params = httpParamsFromObject(payload);
    return this.http.delete(`${this.apiDocuments}/delete-folder`, {params});
  }

  addDocument(payload: AddDocumentPayload): Observable<ResponseDto> {
    return this.http.post(`${this.apiDocuments}/create-document`, payload);
  }

  updateDocument(payload: UpdateDocumentPayload): Observable<ResponseDto> {
    return this.http.put(`${this.apiDocuments}/edit-document`, payload);
  }

  restoreDocument(payload: RestoreDocumentPayload): Observable<ResponseDto> {
    return this.http.put(`${this.apiDocuments}/reestablish-document-version`, payload);
  }

  setDocumentState(payload: FavoriteDocumentPayload): Observable<ResponseDto> {
    return this.http.put(`${this.apiDocuments}/set-document-state`, payload);
  }

  deleteDocument(payload: DocumentStatePayload): Observable<ResponseDto> {
    const params = httpParamsFromObject(payload);
    return this.http.delete(`${this.apiDocuments}/delete-document`, {params});
  }

  getBoatShortViewDto(boatId: number, jobOfferInvitationId: number | null): Observable<BoatShortViewDto> {
    const params = httpParamsFromObject({boatId, jobOfferInvitationId});
    return this.http.get<ResponseDto<BoatShortViewDto>>(`${this.apiUrl}/get-crew-boat-short-info`, {params}).pipe(
      map(response => response.data),
    );
  }

  canUserChangeBoatLocation(boatId: number): Observable<CanUserChangeBoatLocationDto> {
    const params = httpParamsFromObject({boatId});
    return this.http.get<ResponseDto<CanUserChangeBoatLocationDto>>(
      `${this.apiUrl}/can-user-change-boat-location`,
      {params},
    ).pipe(
      map(response => response.data),
    );
  }

  updateUserBoatLocation(payload: ChangeUserBoatPayload): Observable<ResponseDto> {
    return this.http.put(`${this.apiUrl}/change-user-boat-location`, payload);
  }

  affiliateBoat(companyId: number, boatId: number): Observable<ResponseDto> {
    const payload = {boatId, companyId};
    return this.http.post<ResponseDto<any>>(`${this.apiCompany}/affiliate-boat-to-company`, payload);
  }

  unaffiliateBoat(companyId: number, boatId: number): Observable<ResponseDto> {
    const payload = {boatId, companyId};
    return this.http.post<ResponseDto<any>>(`${this.apiCompany}/unaffiliate-boat-to-company`, payload);
  }

  getCurrencyRateInfo(payload: ExpenseCurrencyRateInfoParams): Observable<ExpenseCurrencyRateInfoDto> {
    const params = httpParamsFromObject(payload);
    return this.http.get<ResponseDto<ExpenseCurrencyRateInfoDto>>(`${this.apiUrl}/get-currency-rate-info`, {
      params,
    }).pipe(
      map(response => response.data),
    );
  }

  getDocumentVersionsList(payload: BoatDocumentVersionsPayload, page: number)
    : Observable<BoatDocumentVersionsListDto> {
    const params = httpParamsFromObject({...payload, page});
    return this.http.get<ResponseDto<BoatDocumentVersionsListDto>>(`${this.apiDocuments}/get-document-versions`, {
      params,
    }).pipe(
      map(res => res.data),
    );
  }

  deleteDocumentVersion(payload: BoatDocumentVersionsPayload): Observable<ResponseDto> {
    const params = httpParamsFromObject(payload);
    return this.http.delete<ResponseDto>(`${this.apiDocuments}/delete-document-version`, {params});
  }

  getBoatPettyCash(boatId: number): Observable<BoatPettyCashTableDto> {
    const params = buildURLParams({boatId});
    return this.http.get<ResponseDto<BoatPettyCashTableDto>>(`${this.apiUrl}/get-petty-cash-balance-by-currency`, {params})
      .pipe(
        map(res => res.data),
      );
  }

  setTaskDefaultAssignee(payload: BoatTaskDefaultAssigneePayload): Observable<ResponseDto> {
    return this.http.put(`${this.apiUrl}/set-default-assigned-task`, payload);
  }

  getNotifyBoatLocation(boat: number): Observable<BoatAisNotifyDto> {
    return this.http.post<ResponseDto>(`${this.apiUrl}/check-notify-set-boat-location`, {boat}).pipe(
      map(response => response.data),
    );
  }

  setNotifyBoatLocation(boat: number): Observable<ResponseDto> {
    return this.http.post<ResponseDto>(`${this.apiUrl}/set-no-show-notify-set-boat-location`, {boat});
  }

  confirmCurrentTimezone(boat: number): Observable<ResponseDto> {
    return this.http.put<ResponseDto>(`${this.apiUrl}/confirm-current-timezone`, {boat});
  }

  getBoatSuppliers(boat: number): Observable<BoatSupplierDetailsDto[]> {
    const params = buildURLParams({boat});
    return this.http.get<ResponseDto<BoatSupplierDetailsDto[]>>(`${this.apiUrl}/get-boat-suppliers`, {params})
      .pipe(
        map(res => res.data),
      );
  }
}
