import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {NumberPipe} from '@pipes/number-pipe';

import {PlatformBrowserService} from '@core/modules';
import {htmlDecode} from '@helpers/html-decode';
import {BoatCurrentCurrency, CurrencyDirectoriesDto, CurrencyDto} from '@models/directories/currency.model';

import {ChartData, ChartType} from '../../models/chart.model';

export class CustomLabelsPlugin {

  beforeDraw(chart: Chart.ChartPluginsOptions): void {
    const ctx = chart.chart.ctx;
    ctx.font = '14px Roboto, sans-serif';
    ctx.strokeStyle = 'black';
    ctx.fillStyle = '#4D4E67';
    const meta = chart.getDatasetMeta(0);
    if (meta) {
      meta.data.forEach((value) => {
        const yPosition = value._model.y;
        const xScale = value._xScale;
        const x = xScale.left;
        const y = yPosition - 16;
        const label = value._model.label;
        ctx.fillText(label, x, y);
      });
    }
    ctx.restore();
  }

}

@Directive()
export abstract class ChartManager<T = ChartData> implements OnChanges {

  chart: Chart;
  defaultMinData = 50;
  defaultMaxData = 150;
  maxDataPercentage = 1.1;

  protected currencyBadge: string | null = null;

  protected get labels(): string[] {
    const data = this.data;
    return Array.isArray(data) ? data.map(item => item.label) : [];
  }

  protected get values(): number[] {
    const data = this.data;
    return Array.isArray(data) ? data.map(item => item.value) : [];
  }

  protected get colors(): string[] {
    const data = this.data;
    return Array.isArray(data) ? data.map(item => item.color || '') : [];
  }

  protected get windowWidth(): number | null {
    return window && window.innerWidth;
  }

  protected get smallViewport(): boolean {
    const width = this.windowWidth;
    return !!width && width < 1280;
  }

  protected get maxData(): number {
    return Math.max(...this.data.map(data => data.value)) || this.defaultMaxData;
  }

  @Input() data: ChartData[] = [];
  @Input() minValue: number | null = null;
  @Input() color = '#46A6A4';
  @Input() handleClick = false;
  @Input() currency: CurrencyDto | CurrencyDirectoriesDto | null;
  @Input() showCurrency = true;

  @Output() selectItem = new EventEmitter<number>();

  @ViewChild('chart', {static: true}) protected chartRef: ElementRef;

  constructor(
    protected readonly browserService: PlatformBrowserService,
    protected readonly numberPipe: NumberPipe,
    protected readonly chartType: ChartType,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const dataChanges = changes.data;
    if (!dataChanges || !this.browserService.isBrowser) {
      return;
    }
    if (dataChanges) {
      const minValue = this.minValue;
      if (minValue !== null && !isNaN(minValue)) {
        this.data = [...this.data.filter(item => item.value >= minValue)];
      }
    }
    if (!this.chart) {
      this.drawChart();
    }
    this.updateChartData(0, this.values, true, this.chartType === 'pie');
    if (this.chart) {
      this.chart.update();
    }
  }

  protected updateChartData(datasetIndex: number, data: number[], updateLabels = true, updateColors = false): void {
    const chart = this.chart;
    if (!chart) {
      return;
    }
    const dataSets = chart.data && chart.data.datasets;
    if (!dataSets || !dataSets[datasetIndex]) {
      return;
    }
    dataSets[datasetIndex].data = data;
    if (updateLabels) {
      chart.data.labels = this.labels;
    }
    if (updateColors) {
      dataSets[datasetIndex].backgroundColor = this.colors;
    }
    /*
      Parameter maxData make chart to calculate bar width and steps on X axis not by real max value in data
      array but by maxData parameter. This allowes to make enougth space for labels on the right of the each bar.
      Parameter maxDataPercentage calculates difference between real max data and maxData,
      basicaly it shows how wide space for label will be. By default it's 110%.
     */
    const xAxesTicks =
      chart?.options?.scales?.xAxes &&
      chart.options.scales.xAxes[datasetIndex]?.ticks;
    if (!xAxesTicks) {
      return;
    }
    xAxesTicks.suggestedMax = this.maxData * this.maxDataPercentage;
  }

  protected updateChartLabels(labels: string[]): void {
    const chart = this.chart;
    if (!chart) {
      return;
    }
    chart.data.labels = labels;
    chart.update();
  }

  protected getSelectedIndex(event: MouseEvent | undefined): void {
    if (!event) {
      return;
    }
    const elem = this.chart.getElementAtEvent(event)[0] as any;
    if (!elem) {
      return;
    }
    this.onClickChartItem(elem._index as number);
  }

  private onClickChartItem(index: number): void {
    this.selectItem.emit(index);
  }

  protected abstract drawChart(): void;

  protected addCurrency(content: string | number, currency: CurrencyDto | CurrencyDirectoriesDto | null = null): string {
    if (!this.showCurrency) {
      return `${this.numberPipe.transform(content)}`;
    }
    const badge = currency ? currency.badge : this.currencyBadge || '';
    if (!badge) {
      return `${this.numberPipe.transform(content)}`;
    }
    const currencyBadge = htmlDecode(badge);
    return `${currencyBadge} ${this.numberPipe.transform(content)}`;
  }

  onChangeCurrency(currency: BoatCurrentCurrency): void {
    this.currencyBadge = currency && currency.badge || '';
    if (!this.chart) {
      this.drawChart();
      return;
    }
    this.chart.update();
  }

}
