import {BoatInventoryStatus,BoatInventoryStatusItem} from '@models/boat-maintenance';

export const BOAT_INVENTORY_STATUS_TYPES: BoatInventoryStatusItem[] = [
  {
    id: BoatInventoryStatus.checked,
    name: 'maintenance.inventory.statuses.checked',
    cssClass: 'inventory-status-type inventory-status-type--checked',
    color: '#3AAE27',
    multiple: true,
  },
  {
    id: BoatInventoryStatus.unconfirmed,
    name: 'maintenance.inventory.statuses.unconfirmed',
    cssClass: 'inventory-status-type inventory-status-type--unconfirmed',
    color: '#F7C948',
  },
  {
    id: BoatInventoryStatus.notChecked,
    name: 'maintenance.inventory.statuses.notChecked',
    cssClass: 'inventory-status-type inventory-status-type--not-checked',
    color: '#BDBDBD',
    multiple: true,
  },
  {
    id: BoatInventoryStatus.deleted,
    name: 'maintenance.inventory.statuses.deleted',
    cssClass: 'inventory-status-type inventory-status-type--deleted',
    color: '#D70000',
    hidden: true,
  },
];

export const BOAT_INVENTORY_DEFAULT_LIMIT = 10;
export const BOAT_INVENTORY_LIMITS: number[] = [BOAT_INVENTORY_DEFAULT_LIMIT, 25, 50, 100];

export const BOAT_INVENTORY_DEFAULT_FILTERS = {
  status: [BoatInventoryStatus.notChecked, BoatInventoryStatus.checked, BoatInventoryStatus.unconfirmed],
};
