import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {shareReplay} from 'rxjs/operators';

import {AppStateService} from '@core/modules';
import {UserRoleType} from '@models/directories';

@Injectable()
export class RolesService {

  userRole$: Observable<UserRoleType | null>;

  constructor(
    private readonly appStateService: AppStateService,
  ) {
    this.userRole$ = this.getUserRoleAsync();
  }

  isCrewGroup(userRole: UserRoleType): boolean {
    return this.hasRole(['role_boat_captain', 'role_crew'], userRole);
  }

  isAdditionalGroup(userRole: UserRoleType): boolean {
    return this.hasRole(['role_boat_manager', 'role_boat_owner'], userRole);
  }

  isManager(userRole: UserRoleType): boolean {
    return this.hasRole(['role_boat_manager'], userRole);
  }

  isSupplier(userRole: UserRoleType) {
    return this.hasRole(['role_supplier'], userRole);
  }

  hasRole(roles: UserRoleType | UserRoleType[], userRole: UserRoleType): boolean {
    if (Array.isArray(roles)) {
      return roles.some(role => userRole === role);
    } else {
      return roles ? userRole === roles : true;
    }
  }

  checkRole(userRoles: UserRoleType[] | UserRoleType): boolean {
    const role = this.getUserRole();
    if (!role) {
      return false;
    }
    return this.hasRole(userRoles, role);
  }

  private getUserRole(): UserRoleType | null {
    const role = this.appStateService.userRole;
    return role;
  }

  private getUserRoleAsync(): Observable<UserRoleType | null> {
    return this.appStateService.userRole$.pipe(
      shareReplay(1),
    );
  }

}
