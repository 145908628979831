import {BoatSupplierDetailsDto} from '../boat';
import {BoatCurrentCurrency, CurrencyDto} from '../directories';
import {BoatSupplierBankDetailsForm} from '../expense';
import {FileListItemI} from '../file';
import {ConditionalStatus} from '../general';
import {UploadedFile} from '../uploaded-file';

export enum BoatTaskQuoteStatus {
  created = 1,
  approved,
  unApproved
}

export type BoatTaskQuoteFormMode = 'create' | 'edit' | 'preview';

export interface BoatTaskQuoteDirectories {
  readonly currencies: CurrencyDto[];
  readonly boatCurrency: BoatCurrentCurrency;
  readonly suppliers: BoatSupplierDetailsDto[];
}

export interface BoatTaskQuoteForm {
  readonly amount: string;
  readonly currency: number;
  readonly description: string | null;
  readonly files: UploadedFile[] | null;
  readonly removeFiles: number[] | null;
  readonly supplierName: string | null;
  readonly selectedSupplier: number | null;
  readonly bankDetails: BoatSupplierBankDetailsForm;
}

export interface BoatTaskQuotePayload {
  [key: string]: unknown;

  readonly amount: number;
  readonly currency: number;
  readonly description: string | null;
  readonly supplierId: number | null;
  readonly supplierName: string | null;
  readonly beneficiaryName: string | null;
  readonly bankName: string | null;
  readonly bankAccount: string | null;
  readonly bankAccountNotRelevant: ConditionalStatus;
  readonly iban: string | null;
  readonly ibanNotRelevant: ConditionalStatus;
  readonly swift: string | null;
  readonly bankAddress: string | null;
  readonly beneficiaryAddress: string | null;
}

export interface AddBoatTaskQuotePayload extends BoatTaskQuotePayload {
  readonly boatTask: number;
}

export interface UpdateBoatTaskQuotePayload extends BoatTaskQuotePayload {
  readonly boatTaskQuote: number;
  readonly removeFiles: number[] | null;
  readonly removeBankDetailFiles: number[] | null;
}

export interface BoatTaskQuoteStatusPayload {
  readonly boatTaskQuote: number;
  readonly status: BoatTaskQuoteStatus;
}

export interface BoatTaskQuoteStatusInfo {
  readonly status: BoatTaskQuoteStatus;
  readonly name: string | null;
  readonly date: string | null;
}

export interface BoatTaskQuoteFormDto {
  readonly id: number;
  readonly order: number;
  readonly status: BoatTaskQuoteStatus;
  readonly amount: number;
  readonly currencyId: number;
  readonly description: string | null;
  readonly files: FileListItemI[] | null;
  readonly boatSupplier: BoatSupplierDetailsDto;
  readonly approvedBy: string | null;
  readonly dateApproved: string | null;
}

export interface BoatTaskQuoteListDto {
  readonly actualCost: number;
  readonly list: BoatTaskQuoteItemDto[];
}

export interface BoatTaskQuoteItemDto {
  readonly id: number;
  readonly order: number;
  readonly status: BoatTaskQuoteStatus;
  readonly originalAmount: number;
  readonly convertedAmount: number;
  readonly originalCurrency: string;
  readonly boatSupplier: string;
  readonly approvedBy: string | null;
  readonly dateApproved: string | null;
  readonly hasFiles: boolean;
}

export interface UpdateBoatTaskActualCostPayload {
  readonly boatTask: number;
  readonly amount: string;
}
