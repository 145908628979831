import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {PermissionGuard} from '@modules/permissions/guards/permission.guard';

import {AppStateService, UniversalStorageService} from '@core/modules';
import {UserService} from '@services/user/user.service';

import {PermissionDirective} from './directives/permission/permission.directive';
import {PermissionCheckDirective} from './directives/permission-check/permission-check.directive';
import {IsBoatAdminGuard} from './guards/is-boat-admin.guard';
import {PermissionsService} from './services/permissions/permissions.service';

import {NotificationModule} from '../notification';

export const permissionsServiceFactory = (
  authService: UserService,
  universalStorageService: UniversalStorageService,
  appStateService: AppStateService,
): PermissionsService => new PermissionsService(authService, universalStorageService, appStateService);

@NgModule({
  declarations: [PermissionDirective, PermissionCheckDirective],
  imports: [
    CommonModule,
    NotificationModule,
  ],
  exports: [PermissionDirective, PermissionCheckDirective],
})
export class PermissionsModule {

  static forRoot(): ModuleWithProviders<PermissionsModule> {
    return {
      ngModule: PermissionsModule,
      providers: [
        {
          provide: PermissionsService,
          useFactory: permissionsServiceFactory,
          deps: [UserService, UniversalStorageService, AppStateService],
        },
        {
          provide: PermissionGuard,
          useClass: PermissionGuard,
        },
        {
          provide: IsBoatAdminGuard,
          useClass: IsBoatAdminGuard,
        },
      ],
    };
  }
}
