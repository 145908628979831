import {ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit} from '@angular/core';
import {NumberPipe} from '@pipes/number-pipe';
import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import {PlatformBrowserService} from '@core/modules';

import {ChartManager} from '../../classes/chart-manager/chart-manager';

@Component({
  selector: 'app-bar-vertical-chart',
  templateUrl: './bar-vertical-chart.component.html',
  styleUrls: ['./bar-vertical-chart.component.scss'],
  providers: [NumberPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BarVerticalChartComponent extends ChartManager implements OnInit {

  private get chartPadding(): any {
    return this.smallViewport ? {top: 70} : {top: 30};
  }

  constructor(
    private readonly cdr: ChangeDetectorRef,
    protected readonly browserService: PlatformBrowserService,
    protected readonly numberPipe: NumberPipe,
  ) {
    super(browserService, numberPipe, 'bar-vertical');
  }

  ngOnInit() {
    this.drawChart();
  }

  @HostListener('window:resize')
  onResize() {
    const chart = this.chart;
    if (!chart) {
      return;
    }
    this.chart.clear();
    const options = chart.config && chart.config.options;
    if (!options) {
      return;
    }
    const layout = options.layout;
    if (!layout) {
      return;
    }
    layout.padding = this.chartPadding;
    this.chart.update();
  }

  protected drawChart(): void {
    if (!this.browserService.isBrowser) {
      return;
    }
    const ctx = this.chartRef.nativeElement.getContext('2d');
    const gradientStroke = ctx.createLinearGradient(0, 0, 0, 480);
    gradientStroke.addColorStop(1, '#46A6A4');
    gradientStroke.addColorStop(0, 'rgba(70, 166, 164, 0.42)');
    this.chart = new Chart(ctx, {
      plugins: [ChartDataLabels],
      type: 'bar',
      data: {
        labels: [...this.labels],
        datasets: [
          {
            backgroundColor: gradientStroke,
            borderColor: gradientStroke,
            data: [...this.values],
            barPercentage: .7,
          },
        ],
      },
      options: {
        layout: {
          padding: this.chartPadding,
        },
        legend: {
          display: false,
        },
        tooltips: {
          enabled: false,
        },
        plugins: {
          datalabels: {
            color: 'rgba(77, 78, 103, .9)',
            anchor: 'end',
            formatter: (value) => this.addCurrency(value),
            font: {
              family: 'Roboto',
              size: 14,
              weight: 500,
            },
            align: 'end',
            offset: -5,
            rotation: () => this.smallViewport ? -70 : 0,
          },
        },
        scales: {
          yAxes: [
            {
              display: true,
              gridLines: {
                offsetGridLines: true,
              },
              ticks: {
                display: true,
                fontColor: '#A9A9A9',
                beginAtZero: true,
                callback: (label) => this.addCurrency(label),
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                offsetGridLines: true,
                display: false,
              },
              ticks: {
                min: 0,
                suggestedMin: 50,
                suggestedMax: 150,
                fontColor: '#4D4E67',
                callback: (label) => label.toLocaleString(),
              },
            },
          ],
        },
        animation: {
          duration: 0,
        },
        onClick: (event) => this.getSelectedIndex(event),
        onHover(event: any): any {
          const point = this.getElementAtEvent(event);
          event.target.style.cursor = point && point.length ? 'pointer' : 'default';
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    });
    this.cdr.detectChanges();
  }

}
