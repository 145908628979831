import {TaskDashboardCustomFilterType} from './boat-maintenance-shared';

import {BoatPayloadDto} from '../boat';
import {EquipmentLevel} from '../boat-settings';
import {ConditionalStatus} from '../general';
import {PaginationDto} from '../response';

export enum WarrantyStatusCode {
  Ok = 1,
  Warning,
  Expired
}

export class SystemFile {
  constructor(
    public readonly fileName: string | null,
    public readonly customName: string | null = null,
  ) {
  }
}

export interface EquipmentFileDto {
  readonly id: number;
  readonly fileName: string;
  readonly originalFileName: string;
  readonly customFileName: string;
  readonly url: string;
}

export interface AddEquipmentDto {
  readonly boat: number;
  readonly level: EquipmentLevel;
  readonly coverPhotoFile: string | null;
  readonly group?: number;
  readonly system?: number;
  readonly subSystem?: number;
  readonly name: string;
  readonly note: string | null;
  readonly brand: number | 9999 | null;
  readonly brandCustom?: string | null;
  readonly model: string | null;
  readonly serialNumber: string | null;
  readonly dateInstallation: string | null;
  readonly warranty: ConditionalStatus;
  readonly warrantyExpireDate?: string | null;
  readonly warrantyLifeTime?: ConditionalStatus;
  readonly runningHoursMode: ConditionalStatus;
  readonly runningHoursValue?: number | null;
  readonly hide?: ConditionalStatus;
  readonly coverPhotoFileRemove?: ConditionalStatus.YES | null;
  readonly filesRemove?: number[];
  readonly actualHours: number | null;
  readonly actualDate: string | null;
  readonly recalculate: ConditionalStatus | null;
  readonly [key: string]: unknown;
}

export interface EquipmentDto extends AddEquipmentDto {
  readonly id: number;
  readonly warrantyLifeTime: ConditionalStatus;
  readonly hide: ConditionalStatus;
  readonly files: EquipmentFileDto[];
  readonly isSystem: boolean;
  readonly isParentHide: boolean;
  readonly dateUpdateHour: string | null;
  readonly connectedRepeatRunningHours: boolean;
  readonly canChangeLastService: boolean;
  readonly serviceTaskRepeatRunningHours?: number | null;
}

export interface GetEquipmentParams {
  readonly boat: number;
  readonly equipment: number;
}

export interface EquipmentTreeItemData {
  readonly model: EquipmentTreeItem[];
}

export interface EquipmentTreeItem {
  readonly id: number;
  readonly level: EquipmentLevel;
  readonly name: string;
  readonly hide: boolean;
  readonly warranty: WarrantyStatusCode | null;
  readonly children: EquipmentTreeItem[] | null;
  readonly taskNumbers: number;
  readonly expiredTasks?: number;
  readonly allTasks?: number | null;
  readonly runningHours: number | null;
  readonly dateLastUpdated: string | null;
  readonly dateLastService: string | null;
}

export interface EquipmentBreadCrumbsDto {
  readonly id: number;
  readonly name: string;
  readonly translate?: boolean;
}

export interface EquipmentListCardTasksDto {
  readonly open: number;
  readonly overdue: number;
}

export interface EquipmentListCardDto {
  readonly id: number;
  readonly breadcrumbs: EquipmentBreadCrumbsDto[];
  readonly level: EquipmentLevel;
  readonly name: string;
  readonly isHidden: boolean;
  readonly coverPhoto: string | null;
  readonly warranty: boolean | null;
  readonly warrantyStatus: WarrantyStatusCode | null;
  readonly brand: string | null;
  readonly model: string | null;
  readonly runningHours: number | null;
  readonly runningMiles: number | null;
  readonly lastUpdate: string | null;
  readonly color: BoatEquipmentMileageDateColor | null;
  readonly service: EquipmentListCardServiceDto | null;
  readonly tasks?: EquipmentListCardTasksDto;
}

export interface EquipmentListDto {
  readonly models: EquipmentListCardDto[];
  readonly pagination: PaginationDto;
}

export enum EquipmentSort {
  DateDesc = 1,
  DateAsc,
  NameAsc,
  NameDesc
}

export interface EquipmentSortItem {
  readonly id: EquipmentSort;
  readonly name: string;
}

export interface EquipmentListFiltersForm {
  readonly filters: BoatSystemsFilters[] | null;
  readonly sort: EquipmentSort | null;
}

export interface EquipmentFilterForm {
  readonly underWarranty: boolean;
  readonly noWarranty: boolean;
  readonly runningHours: boolean;
  readonly runningMiles: boolean;
  readonly hidden: boolean;
  readonly notUpdated: boolean;
  readonly notUpdatedMonth: boolean;
}

export class EquipmentListPayload {
  constructor(
    public readonly boat: number | null = null,
    public readonly systemType: EquipmentLevel | null = null,
    public readonly system: number | null = null,
    public readonly filters: number[] | null = null,
    public readonly search: string | null = null,
    public readonly page: number = 1,
    public readonly limit: number = 9,
    public readonly sort: EquipmentSort | null = EquipmentSort.NameAsc,
  ) {
  }
}

export interface EquipmentFilters {
  readonly filters: number[] | null;
  readonly page: number;
  readonly search: string | null;
  readonly sort: EquipmentSort | null;
  readonly systemType: EquipmentLevel | null;
}

export enum BoatSystemsFilters {
  OverDueTasks = 1,
  TodayTasks,
  NextWeekTasks,
  MyTasks,
  UnderWarranty,
  NoWarranty,
  RunningHours,
  RunningMiles,
  WithSubsystem,
  Hidden,
  NotUpdated,
  NotUpdatedMonth,
}

export class BoatSystemsFilterSystemType {
  constructor(
    public readonly id: EquipmentLevel | null,
    public readonly name: string,
  ) {
  }
}

export class BoatSystemsFilterFilters {
  constructor(
    public readonly id: BoatSystemsFilters,
    public readonly name: string,
  ) {
  }
}

export class BoatSystemsFilterDirectories {
  constructor(
    public readonly systemType: BoatSystemsFilterSystemType[],
    public readonly filters: BoatSystemsFilterFilters[],
    public readonly sortItems: EquipmentSortItem[],
  ) {
  }
}

export interface BoatSystemsFilterModalI {
  initialForm: BoatSystemsFilters[];
}

export enum BoatSystemsSystemInfoMode {
  Details = 1,
  Documents
}

export interface BoatSystemsSystemInfo {
  readonly card: BoatSystemsItemCardDto;
  readonly documents: BoatSystemsSystemDocumentItemDto[] | null;
  readonly details: BoatSystemsSystemDetailsDto;
  readonly tasks?: EquipmentListCardTasksDto;
}

export interface BoatSystemsItemCardDto extends EquipmentListCardDto {
  readonly group: number | null;
  readonly system: number | null;
  readonly subSystem: number | null;
}

export interface EquipmentListCardServiceDto {
  readonly last: EquipmentListCardServiceItemDto | null;
  readonly next: EquipmentListCardServiceItemDto | null;
  readonly expiredCount: number | null;
  readonly leftTimeToNextService?: number | null;
}

export interface EquipmentListCardServiceItemDto {
  readonly id: number;
  readonly date: string;
  readonly name: string;
  readonly list: number[];
  readonly days?: number;
}

export interface BoatSystemsSystemDocumentItemDto {
  readonly id: number;
  readonly customFilename: string;
  readonly originalFilename: string;
  readonly filename: string;
  readonly s3Url: string;
}

export interface BoatSystemsSystemDetailsDto {
  readonly brand: string;
  readonly installationDate: string;
  readonly model: string;
  readonly note: string;
  readonly serialNumber: string;
  readonly warrantyExpiration: string | null;
}

export class BoatEquipmentMileageFilters {
  constructor(
    public readonly search: string = '',
  ) {
  }
}

export type BoatEquipmentMileagePayload = BoatEquipmentMileageFilters & {boat: number};

export enum BoatEquipmentMileageDateColor {
  Green = 1,
  Black,
  Red
}

export interface BoatEquipmentMileageDateItem {
  readonly value: BoatEquipmentMileageDateColor;
  readonly cssClass: string;
}

export interface BoatEquipmentMileageItemDto {
  readonly id: number;
  readonly name: string;
  readonly level: EquipmentLevel;
  readonly brand: string;
  readonly model: string;
  readonly dateLastUpdate: string;
  readonly canUpdateHours: boolean;
  readonly runningHours: number;
  readonly color: BoatEquipmentMileageDateColor;
  readonly children: BoatEquipmentMileageItemDto[];
  readonly hours?: number;
}

export interface BoatEquipmentMileageForm {
  readonly hours: number;
  readonly equipment: number;
  readonly lastHours?: number;
}

export type UpdateBoatEquipmentMileageDto = Omit<BoatEquipmentMileageItemDto, 'children'>;

export interface EquipmentFlatNode {
  expandable: boolean;
  name: string;
  level: EquipmentLevel;
  id: number;
  active: boolean;
  taskNumbers?: number;
  isParentHide?: boolean;
  expiredTasks?: number;
  connectedRepeatRunningHours?: boolean;
}

export class UpdateEquipmentHideStatusPayload {
  constructor(
    public readonly boat: number,
    public readonly system: number,
    public readonly hide: ConditionalStatus,
  ) {
  }
}

export const getSelectedSystemPath = (
  systemId: number | null,
  systems: EquipmentTreeItem[],
  path: EquipmentTreeItem[] = [],
  isFound = false,
): EquipmentTreeItem[] => systems.reduce((acc: EquipmentTreeItem[], curr) => {
  if (isFound) {
    return acc;
  }
  if (curr.id === systemId) {
    isFound = true;
    return [curr];
  }
  if (curr.children?.length) {
    const selected = getSelectedSystemPath(systemId, curr.children, acc, isFound);
    if (selected?.length) {
      isFound = true;
      return [curr, ...selected];
    }
  }
  return acc;
}, path);

export interface ServiceTasksPayload {
  readonly type: TaskDashboardCustomFilterType;
  readonly ids: number[];
  readonly systemName: string;
}

export interface LastServiceHoursParams extends BoatPayloadDto {
  readonly equipment: number;
}

export interface LastServiceHoursDto {
  readonly runningHours: number | null;
  readonly dateUpdate: string | null;
  readonly dateLastService: string | null;
}

export interface EquipmentValidatePayload extends BoatPayloadDto {
  readonly equipment: number;
  readonly actualHours: number | null;
  readonly actualDate: string | null;
  readonly recalculate: ConditionalStatus | null;
}

export interface UpdateBoatEquipmentMileagePayload extends EquipmentValidatePayload {
  readonly equipment: number;
  readonly hours: number;
}
