import {
  parseUploadFileName,
  parseUploadFileNames,
  parseUploadOriginalNames,
} from '@helpers/parse-new-upload-file-names';

import {FleetBoatShortItemDto, FleetDto} from './boats-fleet.model';

import {
  CharterCompletedStatus,
  CharterIncomeI,
  CharterKindType,
  CharterPeriodStatus,
  SystemCharterType,
} from '../charters';
import {booleanToConditionalStatus, ConditionalStatus} from '../general';
import {GuestCharterType, GuestForm, GuestFormDto, GuestInfoItemDto, GuestPhoneForm} from '../guests';
import {LocationDto} from '../location';
import {PaginationDto} from '../response';

export interface FleetGuestsFilters {
  readonly search?: string | null;
  readonly guestType?: GuestCharterType[] | null;
  readonly page?: number;
}

export class FleetGuestsPayload {
  constructor(
    public readonly fleet: number,
    public readonly search: string,
    public readonly guestType: GuestCharterType[],
    public readonly page: number,
    public readonly limit: number,
  ) {
  }
}

export interface DateBirth {
  readonly date: string;
  readonly icon: boolean;
}

export interface LastBoat {
  readonly name: string;
  readonly shortName: string;
  readonly boatLength: number;
}

export interface GuestFleetData {
  readonly id: number;
  readonly name: string;
  readonly image: string;
}

export interface GuestContactsPhone {
  readonly fullPhoneNumber: string;
  readonly telegram: boolean;
  readonly viber: boolean;
  readonly whatsapp: boolean;
}

export interface GuestContactsDto {
  readonly email: string;
  readonly phones: GuestContactsPhone[];
}

export interface FleetGuestsDto {
  readonly models: GuestInfoItemDto[];
  readonly pagination: PaginationDto;
}

export interface FleetGuestFormDto extends GuestFormDto {
  readonly isExistsGuestOnAnyCharter: boolean;
  readonly isLastGuestAnyFleet: boolean;
}

export class AddFleetGuestPayload {
  [key: string]: unknown;

  readonly firstName: string;
  readonly lastName: string;
  readonly email: string | null;
  readonly image: string | null;
  readonly gender: number;
  readonly type: number;
  readonly dateBirth: string | null;
  readonly passportNumber: string | null;
  readonly passportIssueCountry: number | null;
  readonly passportExpiryDate: string | null;
  readonly passportAttachments: string[] | null = null;
  readonly passportOriginalAttachments: string[] | null = null;
  readonly documentAttachments: string[] | null = null;
  readonly documentOriginalAttachments: string[] | null = null;
  readonly notes: string | null;
  readonly fleet: number;
  readonly fleets: number[];
  readonly removeFleets: number[] | null;

  constructor(
    form: GuestForm,
    fleet: number,
  ) {
    this.firstName = form.firstName;
    this.lastName = form.lastName;
    this.email = form.email;
    this.gender = form.gender;
    this.type = form.type;
    this.dateBirth = form.dateBirth;
    this.passportNumber = form.passportNumber;
    this.passportIssueCountry = form.passportIssueCountry;
    this.passportExpiryDate = form.passportExpiryDate;
    const {passportAttachments, documentAttachments, image} = form;
    if (image?.file) {
      this.image = parseUploadFileName(image) || null;
    }
    if (passportAttachments) {
      this.passportAttachments = parseUploadFileNames(passportAttachments);
      this.passportOriginalAttachments = parseUploadOriginalNames(passportAttachments);
    }
    if (documentAttachments) {
      this.documentAttachments = parseUploadFileNames(documentAttachments);
      this.documentOriginalAttachments = parseUploadOriginalNames(documentAttachments);
    }
    this.notes = form.notes;
    form.phones.forEach((item, index) => this.setPhoneParams(item, index));
    this.fleets = form.addFleets || [];
    this.removeFleets = form.removeFleets;
    this.fleet = fleet;
  }

  private setPhoneParams(item: GuestPhoneForm, index: number) {
    const parentKey = `phones[${index}]`;
    if (item.phone) {
      this[`${parentKey}[phoneCountryCode]`] = item.phone.phoneCountryCode;
      this[`${parentKey}[phoneNumber]`] = item.phone.phoneNumber;
      this[`${parentKey}[viber]`] = booleanToConditionalStatus(item.viber);
      this[`${parentKey}[whatsapp]`] = booleanToConditionalStatus(item.whatsapp);
    }
  }
}

export class EditFleetGuestPayload extends AddFleetGuestPayload {
  readonly guest: number;
  readonly removePassportAttachments: number[] | null;
  readonly removeDocumentAttachments: number[] | null;
  readonly removeImage: ConditionalStatus.YES | null;

  constructor(
    form: GuestForm,
    guest: number,
    fleet: number,
  ) {
    super(form, fleet);
    this.guest = guest;
    this.removePassportAttachments = form.removePassportAttachments;
    this.removeDocumentAttachments = form.removeDocumentAttachments;
    this.removeImage = form.removeImage;
  }
}

export class FleetGuestChartersFilter {
  constructor(
    public readonly search = '',
    public readonly page = 1,
  ) {
  }
}

export class FleetGuestChartersPayload extends FleetGuestChartersFilter {
  constructor(
    public readonly fleet: number,
    public readonly guest: number,
    public readonly limit: number,
    search = '',
    page = 1,
  ) {
    super(search, page);
  }
}

export interface FleetGuestChartersDto {
  readonly models: FleetChartersInfoDto[];
  readonly pagination: PaginationDto;
}

export interface FleetChartersInfoDto {
  readonly id: number;
  readonly name: string;
  readonly periodStatus: CharterPeriodStatus;
  readonly charterType: CharterKindType;
  readonly systemCharterType: SystemCharterType;
  readonly status: CharterCompletedStatus;
  readonly dateLastEdit: string;
  readonly dateTimeStart: string;
  readonly timeZoneNameStart: string;
  readonly locationPickUp: LocationDto;
  readonly dateTimeEnd: string;
  readonly timeZoneNameEnd: string;
  readonly locationDropOff: LocationDto;
  readonly guests: number;
  readonly income: CharterIncomeI;
  readonly boat: FleetGuestBoatDto;
}

export interface FleetGuestBoatDto {
  readonly image: string;
  readonly name: string;
  readonly shortName: string;
  readonly boatLength: number;
  readonly flag: string;
  readonly id: number;
}

export class FleetGuestFleetsFilter {
  constructor(
    public readonly search = '',
    public readonly page = 1,
  ) {
  }
}

export class FleetGuestFleetsPayload extends FleetGuestFleetsFilter {
  constructor(
    public readonly guest: number,
    public readonly limit: number,
    search = '',
    page = 1,
  ) {
    super(search, page);
  }
}

export interface FleetGuestFleetDto {
  readonly isExistsGuestOnAnyCharter: boolean;
  readonly isLastGuestAnyFleet: boolean;
  readonly models: FleetGuestFleetItemDto[];
  readonly pagination: PaginationDto;
}

export interface FleetGuestFleetItemDto {
  readonly fleet: FleetDto;
  readonly boats: FleetBoatShortItemDto[];
}

export class FleetGuestRemoveFleetPayload {
  constructor(
    public readonly fleet: number,
    public readonly guest: number,
  ) {
  }
}
