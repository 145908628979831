import {FormControl} from '@angular/forms';

import {ConfirmationDialogDataI} from '../../modules/confirmation-dialog';
import {UserPermissions} from '../../modules/permissions';
import {BoatPayload} from '../boat';
import {
  BoatSubscriptionModeType,
  BoatSubscriptionPlanDetailsDto,
  BoatSubscriptionPromoCode,
} from '../boat-subscription';
import {UserRoleType} from '../directories';
import {CrewStatuses, ExpenseCategoryType} from '../expense';
import {ConditionalStatus} from '../general';

export enum EquipmentLevel {
  ALL,
  GROUP = 1,
  SYSTEM,
  SUB_SYSTEM,
  PART,
}

export enum BoatSettingsItemStatus {
  Deleted,
  Active,
}

export enum BoatAdditionalFieldType {
  Text = 1,
  Date,
  Price,
  Number,
}

export type FinancialAccountItemDto = {
  readonly id: number;
  readonly name: string;
  readonly status: boolean;
}

export interface AddBoatCardPayload {
  boatId: number;
  name: string;
  status: BoatSettingsItemStatus;
}

export class ToggleDisableCardsPayload {
  constructor(
    public readonly boat: number,
    public readonly status: ConditionalStatus,
  ) {
  }
}

export interface UpdateBoatCardPayload extends AddBoatCardPayload {
  creditCardId: number;
}

export class BoatCategoryDto {
  constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly subcategories: BoatSubCategoryDto[],
    public readonly status: boolean,
    public readonly categoryType: ExpenseCategoryType,
  ) {
  }
}

export class BoatSubCategoryDto {
  constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly additionalFields: BoatAdditionalFieldDto[],
    public readonly status: boolean,
  ) {
  }
}

export class BoatAdditionalFieldDto {
  constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly typeField: BoatAdditionalFieldType,
  ) {
  }
}

export interface BoatCategoryModalConfig extends ConfirmationDialogDataI {
  categoryItemType: BoatCategoryItemType;
  status: boolean;
}

export interface AddBoatCategoryPayload {
  boatId: number;
  name: string;
  status: BoatSettingsItemStatus;
}

export interface UpdateBoatCategoryPayload extends AddBoatCategoryPayload {
  categoryId: number;
}

export interface BoatSubCategoryPaylod {
  boatId: number;
  name: string;
  status: BoatSettingsItemStatus;
  additionalFields: BoatAdditionalFieldType[];
}

export interface AddBoatSubCategoryPayload extends BoatSubCategoryPaylod {
  categoryId: number;
}

export interface UpdateBoatSubCategoryPayload extends BoatSubCategoryPaylod {
  subCategoryId: number;
}

export interface UpdateBoatCategory {
  name: string;
  status: BoatSettingsItemStatus;
  categoryId: number;
}

export interface UpdateBoatSubCategory {
  name: string;
  status: BoatSettingsItemStatus;
  subCategoryId: number;
  additionalFields: BoatAdditionalFieldType[];
}

export interface AddCategoryItem {
  name: string;
  status: BoatSettingsItemStatus;
  categoryId?: number;
  additionalFields?: BoatAdditionalFieldType[];
}

export interface BoatUpdateCategoryItem {
  name: string;
  id: number;
  status: boolean;
  categoryType: ExpenseCategoryType;
}

export enum BoatCategoryItemType {
  Category = 1,
  SubCategory,
}

export class BoatAdditionalFieldItemDto {
  constructor(
    public readonly id: number,
    public readonly typeField: BoatAdditionalFieldType,
    public readonly name: string,
    public readonly status: boolean,
  ) {
  }
}

export interface AddAdditionalFieldPayload {
  boatId: number;
  name: string;
  typeField: BoatAdditionalFieldType;
  status: BoatSettingsItemStatus;
}

export interface UpdateAdditoinalFieldPayload extends AddAdditionalFieldPayload {
  dynamicFieldId: number;
}

export interface AdditionalFieldForm {
  name: string;
  typeField: BoatAdditionalFieldType;
  status: boolean;
}

export class BoatCrewExcludedCategoriesDto {
  constructor(
    public readonly categories: number[],
    public readonly subCategories: number[],
  ) {
  }
}

export class BoatCrewExcludeCategory {
  constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly excluded: boolean,
    public readonly subcategories: BoatCrewExcludeSubCategory[],
  ) {
  }
}

export class BoatCrewExcludeSubCategory {
  constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly excluded: boolean,
  ) {
  }
}

export interface BoatCrewPayload {
  boatId: number;
  crewId: number;
}

export interface UpdateBoatCrewExcludeCategories {
  categories: number[];
  subCategories: number[];
}

export interface UpdateBoatCrewExcludeCategoriesPayload extends BoatCrewPayload, UpdateBoatCrewExcludeCategories {
}

export class BoatCrewPermissionsDto {
  permissions: UserPermissions[];
}

export interface UpdateBoatCrewPermissionsPayload extends BoatCrewPayload, BoatCrewPermissionsDto {
}

type InvitedBoatUser = {
  readonly id: number;
  readonly userId: number;
  readonly userRole: UserRoleType;
  readonly name: string;
  readonly email: string;
  readonly image: string | null;
  readonly status: CrewStatuses | null;
}

export type BoatUser = InvitedBoatUser & Partial<{
  readonly phone: string;
  readonly position: string;
  readonly isAdmin: boolean;
  readonly isMe: boolean;
  readonly dateEnd: string;
  readonly dateStart: string;
}>

export interface RemoveBoatUser {
  userId: number;
  userName: string;
  isFinishContract: boolean;
}

export class BoatDepartmentDto {
  constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly status: boolean,
  ) {
  }
}

export interface AddBoatDepartment {
  name: string;
  status: BoatSettingsItemStatus;
}

export interface UpdateBoatDepartment extends AddBoatDepartment {
  departmentId: number;
}

export interface AddBoatDepartmentPayload extends AddBoatDepartment, BoatPayload {
}

export interface UpdateBoatDepartmentPayload extends UpdateBoatDepartment, BoatPayload {
}

export class BoatDepartmentPositionDto {
  constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly departmentId: number,
    public readonly users: string[],
  ) {
  }
}

export interface UpdateBoatDepartmentPosition {
  positionId: number;
  departmentId: number;
}

export interface UpdateBoatDepartmentPositionPayload extends UpdateBoatDepartmentPosition, BoatPayload {
}

export class BoatSubscriptionInfoDto {
  constructor(
    public readonly subscription: BoatSubscriptionDto,
    public readonly freeTrial: BoatTrialSubscriptionDto,
    public readonly creditCard: BoatSubscriptionCreditCardDto | null,
  ) {
  }
}

export class BoatSubscriptionDto {
  constructor(
    public readonly id: number,
    public readonly packageMode: BoatSubscriptionModeType,
    public readonly basePrice: number,
    public readonly perMonthPrice: number,
    public readonly users: BoatSubscriptionUsersDto,
    public readonly additionalUsers: BoatSubscriptionAdditonalUsersDto,
    public readonly nextPay: BoatSubscriptionNextPay,
    public readonly autoRenewal: boolean,
    public readonly trial: true,
    public readonly switchPlan: BoatSubscriptionSwitchPlan | null,
    public readonly plan: BoatSubscriptionPlanDetailsDto,
  ) {
  }
}

export class BoatTrialSubscriptionDto {
  constructor(
    public readonly status: boolean,
    public readonly dateExpired: string | null,
  ) {
  }
}

export class BoatSubscriptionSwitchPlan {
  constructor(
    public readonly canChange: boolean,
    public readonly nextPlan: BoatSubscriptionModeType,
  ) {
  }
}

export class BoatSubscriptionNextPay {
  constructor(
    public readonly dateStart: string,
    public readonly planPrice: number,
    public readonly userPrice: number,
    public readonly finalCost: number,
    public readonly promoCode: BoatSubscriptionPromoCode | null,
  ) {
  }
}

export class BoatSubscriptionUsersDto {
  constructor(
    public readonly currentCrew: number,
    public readonly maxCrew: number,
    public readonly currentAdditional: number,
    public readonly maxAdditional: number,
  ) {
  }
}

export class BoatSubscriptionAdditonalUsersDto {
  constructor(
    public readonly quantity: number,
    public readonly pricePerOne: number,
    public readonly totalCost: number,
  ) {
  }
}

export class BoatSubscriptionCreditCardDto {
  constructor(
    public readonly id: number,
    public readonly cardNumber: number,
    public readonly cardType: CreditCardType | null,
  ) {
  }
}

export enum CreditCardType {
  MasterCard = 1,
  Visa,
  Diners,
  AmericanExpress,
  IssuerPrivateCard,
}

export class BoatSupplierSettingsDto {
  constructor(
    public readonly status: boolean,
  ) {
  }
}

export class BoatSupplierSettingsPayload {

  constructor(
    public readonly boatId: number,
    public readonly status: ConditionalStatus,
  ) {
  }
}

export interface BoatSettingsEquipmentSystemItemDto {
  readonly id: number;
  readonly name: string;
  readonly level: EquipmentLevel;
  readonly isSystem: boolean;
  readonly isParentHide: boolean;
  readonly isActive: boolean;
  readonly children: BoatSettingsEquipmentSystemItemDto[];
  readonly connectedRepeatRunningHours: boolean;
  readonly hide?: boolean;
}

export interface BoatInventoryLocationListDto {
  readonly list: BoatInventoryLocationItemDto[];
}

export interface BoatInventoryLocationItemDto {
  readonly id: number;
  readonly name: string;
  readonly status: ConditionalStatus;
}

export interface BoatInventoryLocationForm {
  name: FormControl<string>;
}

export interface BoatInventoryLocationFormData {
  readonly name: string;
}

export interface AddBoatInventoryLocationPayload {
  readonly boat: number;
  readonly name: string;
}

export interface UpdateBoatInventoryLocationPayload extends AddBoatInventoryLocationPayload {
  readonly location: number;
  readonly status: ConditionalStatus;
}

export interface BoatInventoryCatSubListDto {
  readonly list: BoatInventoryCategoryDto[];
}

export interface BoatInventoryCatSubItemBaseDto {
  readonly id: number;
  readonly name: string;
  readonly status: ConditionalStatus;
  readonly canModify: boolean;
}

export interface BoatInventoryCategoryDto extends BoatInventoryCatSubItemBaseDto {
  readonly connectToMaintenance: boolean;
  readonly subcategory: BoatInventorySubCategoryDto[];
}

export interface BoatInventorySubCategoryDto extends BoatInventoryCatSubItemBaseDto {
  readonly quantityType: number;
}

export enum BoatInventoryCategoryItemType {
  category = 1,
  subCategory
}

export interface BoatInventoryCategoryForm {
  name: FormControl<string>;
  itemType: FormControl<BoatInventoryCategoryItemType>;
  category?: FormControl<number>;
}

export interface BoatInventoryCategoryFormData {
  readonly name: string;
  readonly itemType: BoatInventoryCategoryItemType;
  readonly category?: number;
}

export interface AddBoatInventoryCategoryPayload {
  readonly boat: number;
  readonly name: string;
}

export interface UpdateBoatInventoryCategoryPayload extends AddBoatInventoryCategoryPayload {
  readonly category: number;
  readonly status: ConditionalStatus;
}

export interface AddBoatInventorySubCategoryPayload extends AddBoatInventoryCategoryPayload {
  readonly category: number;
}

export interface UpdateBoatInventorySubCategoryPayload extends AddBoatInventoryCategoryPayload {
  readonly subcategory: number;
  readonly status: ConditionalStatus;
}
