import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {NumberPipe} from '@pipes/number-pipe';
import {Chart} from 'chart.js';
import ChartDataLabels, {Context} from 'chartjs-plugin-datalabels';

import {PlatformBrowserService} from '@core/modules';

import {ChartManager, CustomLabelsPlugin} from '../../classes/chart-manager/chart-manager';

export const hex2rgba = (hex, alpha = .4) => {
  const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
  providers: [NumberPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BarChartComponent extends ChartManager implements OnInit {

  @Input() sameColor = true;
  @Input() clickable = true;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    protected readonly browserService: PlatformBrowserService,
    protected readonly numberPipe: NumberPipe,
  ) {
    super(browserService, numberPipe, 'bar');
  }

  @ViewChild('parent', {static: true}) parent: ElementRef;

  ngOnInit() {
  }

  protected drawChart(): void {
    if (!this.browserService.isBrowser) {
      return;
    }
    const ctx = this.chartRef.nativeElement.getContext('2d');
    // TODO: refactor
    const colors = this.getElementsColors(ctx);
    const clickable = this.clickable;
    this.chart = new Chart(ctx, {
      plugins: [ChartDataLabels, new CustomLabelsPlugin()],
      type: 'horizontalBar',
      data: {
        labels: [],
        datasets: [
          {
            backgroundColor: colors,
            borderColor: colors,
            data: [],
            barPercentage: 1.0,
            categoryPercentage: 1.0,
            barThickness: 24,
          },
        ],
      },
      options: {
        layout: {
          padding: {
            top: 10,
          },
        },
        legend: {
          display: false,
        },
        tooltips: {
          enabled: false,
        },
        plugins: {
          datalabels: {
            font: {
              size: 14,
              weight: 500,
              family: 'Roboto',
            },
            color: (context: Context) => this.getOffset(context) ? 'rgba(77, 78, 103, .9)' : '#FFFFFF',
            anchor: 'end',
            offset: (context: Context) => this.getOffset(context) ? 4 : 7,
            formatter: (value) => this.addCurrency(value, this.currency),
            align: (context: Context) => this.getOffset(context) ? 'end' : 'left',
          },
        },
        scales: {

          yAxes: [
            {
              display: false,
              gridLines: {
                tickMarkLength: 5,
              },
              ticks: {
                display: true,
                fontColor: '#A9A9A9',
                beginAtZero: true,
                maxTicksLimit: 1,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                offsetGridLines: true,
              },
              ticks: {
                min: 0,
                suggestedMin: this.defaultMinData,
                suggestedMax: this.defaultMaxData,
                fontColor: '#A9A9A9',
                callback: (label) => this.addCurrency(label, this.currency),
              },
            },
          ],
        },
        animation: {
          duration: 0,
        },
        responsive: true,
        maintainAspectRatio: false,
        onClick: (event) => this.getSelectedIndex(event as any),
      },
    });
    if (clickable) {
      this.chart.options.onHover = (event: any) => {
        const point = this.chart.getElementAtEvent(event);
        event.target.style.cursor = point && point.length ? 'pointer' : 'default';
      };
    }
    this.cdr.detectChanges();
  }

  private getElementsColors(ctx): CanvasGradient | CanvasGradient[] {
    if (this.sameColor) {
      return this.getGradient(ctx);
    }
    return this.colors.map(color => this.getGradient(ctx, color));
  }

  private getGradient(ctx, color?: string): CanvasGradient {
    const gradientStroke = ctx.createLinearGradient(0, 0, 800, 0);
    gradientStroke.addColorStop(0, hex2rgba(color || this.color, 1));
    gradientStroke.addColorStop(1, hex2rgba(color || this.color));
    return gradientStroke;
  }

  private getOffset(context: Context) {
    const datasetIndex = context.datasetIndex;
    const index = context.dataIndex;
    const dataset = context.chart.getDatasetMeta(datasetIndex);
    const item = dataset.data[index];
    // @ts-ignore
    const scaleWidth = item._xScale._endPixel;
    // @ts-ignore
    const itemWidth = item._model.x;
    return (scaleWidth - itemWidth) > 75;
  }

}
