import {Injectable} from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd,
  NavigationExtras,
  NavigationStart,
  ParamMap,
  Params,
  PRIMARY_OUTLET,
  Router,
} from '@angular/router';
import {RolesService} from '@modules/roles/services/roles/roles.service';
import {EquipmentFilters} from 'app/shared/models/boat-maintenance';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';

import {AppStateService} from '@core/modules/app-state/app-state.service';
import {BoatCalendarQueryParams} from '@features/boat-calendar/shared/models';
import {SUPPLIER_PROFILE_EDIT_NAV_ITEMS} from '@features/suppliers/static/supplier-profile-links';
import {cleanObject} from '@helpers/clean-object';
import {LoginParams, RegistrationParams, RegistrationProvider, SupplierRedirectType} from '@models/auth';
import {BoatDocumentsFilterParams} from '@models/boat-documents';
import {
  BoatMaintenanceTasksLogPayload,
  TaskLogInfoRouteParams,
  TaskLogRouteParams,
  TaskStatusType,
} from '@models/boat-maintenance/boat-tasks';
import {FleetContactsRoutes, FleetRoutes} from '@models/boats-fleet';
import {CharterFilterParams} from '@models/charters';
import {AddApaQueryParams, ApaFinancialInfoParams, ApaViewRoute} from '@models/charters/charter-apa.model';
import {AddEditCharterPages, AddEditTripPages, CharterPaymentDetailsQuery} from '@models/charters/charter.model';
import {CrewListType} from '@models/crew/crew.model';
import {ReportChartParams} from '@models/dashboard';
import {ReportViewType} from '@models/financial-report';
import {
  AUTHORIZED_NAVIGATION_EXTRAS,
  BoatDetailsRoutes,
  BoatSearchRoutes,
  CharterPageRoutes,
  ConditionalStatus,
  CreateExpenseRoutes,
  FinancialRoutes,
  ShareApaReportRoutes,
} from '@models/general';
import {JobOffersReadyType} from '@models/job-offers';
import {ShortcutNavLink, ShortcutPageCharter} from '@models/shortcuts';
import {
  SupplierProfileEditNavLinkName,
  SupplierProfileNavUrl,
} from '@models/supplier/supplier-profile-navigation.model';
import {
  BoatMaintenanceChecklistsRoutes,
  BoatMaintenanceRoutes,
  BoatMaintenanceSystemItemRoutes,
} from '@static/boat-maintenance';
import {TASKS_DEFAULT_FILTERS} from '@static/task-default-filters';

@Injectable()
export class AppRouterService {

  readonly authPath: string[] = ['/', 'auth'];
  readonly authCompanyPath: string[] = ['/', 'auth', 'company'];
  readonly authSupplierPath: string[] = ['/', 'auth', 'supplier'];
  readonly boatPath: string[] = ['/', 'boat-details'];

  get url(): string {
    return this.router.url;
  }

  get token(): string | null {
    return this.route.snapshot.queryParams.token || null;
  }

  get companyId(): string | null {
    return this.route.snapshot.queryParams.company_id || null;
  }

  get queryParams$(): Observable<ParamMap> {
    return this.route.queryParamMap;
  }

  get queryParams(): ParamMap {
    return this.route.snapshot.paramMap;
  }

  navigationStart$: Observable<NavigationStart> = this.router.events.pipe(
    filter(event => event instanceof NavigationStart),
    map(event => event as NavigationStart),
  );

  navigationEnd$: Observable<NavigationEnd> = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    map(event => event as NavigationEnd),
  );

  navigationEndExtras$: Observable<NavigationExtras | null> = this.navigationEnd$.pipe(
    map(() => {
      const currentNavigation = this.router.getCurrentNavigation();
      return (currentNavigation && currentNavigation.extras) || null;
    }),
  );

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly appStateService: AppStateService,
    private readonly rolesService: RolesService,
  ) {
  }

  getChildrenFromUrl(divider: string): string[] | null {
    const url = this.url;
    const tree = this.router.parseUrl(url);
    const {segments} = tree.root.children[PRIMARY_OUTLET];
    const index = segments.findIndex(s => s.path === divider);
    if (index < 0) {
      return null;
    }
    return segments.slice(index + 1).map(s => s.path);
  }

  navigateToLogin(queryParams: LoginParams = {}): Promise<boolean> {
    return this.navigate([...this.authPath, 'login'], {queryParams});
  }

  navigateToRegistration(queryParams: RegistrationParams = {}): Promise<boolean> {
    return this.navigate([...this.authPath, 'registration'], {queryParams});
  }

  navigateToSuccessRegistration(url: string | null = null): Promise<boolean> {
    return this.navigate([...this.authPath, 'registration', 'success', url || '']);
  }

  navigateToSuccessSocialRegistration(url: string | null = null): Promise<boolean> {
    return this.navigate([...this.authPath, 'registration', 'social-success', url || '']);
  }

  navigateToBoatsList(extras: NavigationExtras = {}): Promise<boolean> {
    return this.navigate(['/', 'boats'], extras);
  }

  navigateToSupplier(extras: NavigationExtras = {}): Promise<boolean> {
    return this.navigate(['/', 'supplier'], extras);
  }

  navigateToBranches(extras: NavigationExtras = {}): Promise<boolean> {
    return this.navigate(['/', 'supplier', 'edit', 'branches'], extras);
  }

  navigateToSupplierEdit(
    segment: SupplierProfileNavUrl,
    queryParams: Params = {},
  ): Promise<boolean> {
    return this.navigate(['/', 'supplier', 'edit', segment], {queryParams});
  }

  navigateToBranchEdit(
    segment: SupplierProfileNavUrl,
    branchId: number,
    queryParams: Params = {},
  ): Promise<boolean> {
    return this.navigate(['/', 'supplier', 'edit', 'branches', `${branchId}`, segment], {queryParams});
  }

  navigateToSupplierEditByName(
    segment: SupplierProfileEditNavLinkName,
    queryParams: Params = {},
  ): Promise<boolean> {
    const navItem = SUPPLIER_PROFILE_EDIT_NAV_ITEMS.find(item => item.name === segment);
    const url: SupplierProfileNavUrl = navItem ? navItem.url as SupplierProfileNavUrl : SupplierProfileNavUrl.General;
    return this.navigateToSupplierEdit(url, queryParams);
  }

  navigateToCommonSupplierProfile(shareHash: string): Promise<boolean> {
    return this.navigate(['/', 'suppliers', 'profile', shareHash]);
  }

  navigateToBusinessCard(shareHash: string): Promise<boolean> {
    return this.navigate(['/', 'business-card', shareHash]);
  }

  navigateToAddBoat(): Promise<boolean> {
    return this.navigate(['/', 'boats', 'add']);
  }

  navigateToAddBoatSubscription(boatId: string | number): Promise<boolean> {
    return this.navigate(['/', 'boats', 'add', `${boatId}`, 'subscription']);
  }

  navigateToBoatEdit(boatId: string | number): Promise<boolean> {
    return this.navigate([...this.boatPath, `${boatId}`, 'profile', 'general', 'edit']);
  }

  navigateToBoatPettyCash(boatId: string | number, queryParams: Params = {}): Promise<boolean> {
    return this.navigate([...this.boatPath, `${boatId}`, 'financial', 'petty-cash-balance'], {queryParams});
  }

  navigateToBoatAdmin(boatId: string | number): Promise<boolean> {
    return this.navigate(['/', 'boat', 'admin', `${boatId}`]);
  }

  navigateToBoatCurrency(boatId: string | number): Promise<boolean> {
    return this.navigate(['/', 'boat', 'currency', `${boatId}`]);
  }

  navigateToBoatWelcome(boatId: string | number): Promise<boolean> {
    return this.navigate(['/', 'boat', 'welcome', `${boatId}`]);
  }

  navigateToBoatDashboard(boatId: number): Promise<boolean> {
    return this.navigate([...this.boatPath, `${boatId}`, 'dashboard']);
  }

  navigateToBoatProfile(boatId: string): Promise<boolean> {
    return this.navigate([...this.boatPath, boatId, 'profile']);
  }

  navigateToBoatDocuments(boatId: string, queryParams: Partial<BoatDocumentsFilterParams> = {}): Promise<boolean> {
    return this.navigate([...this.boatPath, boatId, 'profile', 'documents'], {queryParams});
  }

  navigateToBoatDocumentsFolder(
    boatId: string,
    folderId: number,
    queryParams: Partial<BoatDocumentsFilterParams> = {},
  ): Promise<boolean> {
    return this.navigate([...this.boatPath, boatId, 'profile', 'documents', `${folderId}`], {queryParams});
  }

  navigateToBoatExpense(
    boatId: string,
    type: CreateExpenseRoutes = CreateExpenseRoutes.Expense,
    queryParams: Params = {returnUrl: null})
    : Promise<boolean> {
    return this.navigate([...this.boatPath, boatId, 'expense', type], {queryParams});
  }

  navigateToBoatCharterExpenses(boatId: string, charterId: number): Promise<boolean> {
    return this.navigate([...this.boatPath, boatId, 'charters', 'list', `${charterId}`, 'charter-expenses']);
  }

  navigateToBoatFinancialReport(boatId: string | number, type: string = ReportViewType.Overview): Promise<boolean> {
    return this.navigate([...this.boatPath, `${boatId}`, 'financial', 'report', 'monthly', type]);
  }

  navigateToCreditCardStatements(boatId: string | number, id: number, replaceUrl = false): Promise<boolean> {
    return this.router.navigate(
      [...this.boatPath, `${boatId}`, 'financial', 'card-statements', `${id}`],
      {
        queryParamsHandling: 'merge',
        replaceUrl,
      },
    );
  }

  navigateToBankAccountStatements(boatId: string | number, id: number, replaceUrl = false): Promise<boolean> {
    return this.router.navigate(
      [...this.boatPath, `${boatId}`, BoatDetailsRoutes.Financial, FinancialRoutes.BankStatements, `${id}`],
      {
        queryParamsHandling: 'merge',
        replaceUrl,
      },
    );
  }

  navigateToBoatBFD(boatId: string): Promise<boolean> {
    return this.navigate([...this.boatPath, boatId, 'financial', 'data']);
  }

  navigateToBoatDetailsPages(boatId: string, route: string[]): Promise<boolean> {
    return this.navigate([...this.boatPath, boatId, ...route]);
  }

  navigateToMyCrew(boatId: string, type: CrewListType = 'current'): Promise<boolean> {
    return this.navigate([...this.boatPath, boatId, 'crew', type]);
  }

  navigateToAddCrew(boatId: string, params: Params = {}): Promise<boolean> {
    return this.router.navigate([...this.boatPath, boatId, 'crew', 'add'], {queryParams: params});
  }

  navigate(url: string[], extras: NavigationExtras = {}): Promise<boolean> {
    return this.router.navigate(url, extras);
  }

  navigateToShortcuts(boatId: string | number): Promise<boolean> {
    return this.router.navigate([...this.boatPath, boatId, 'shortcuts']);
  }

  navigateToBoatCalendar(boatId: string | number, queryParams: Partial<BoatCalendarQueryParams> = {}): Promise<boolean> {
    return this.router.navigate([...this.boatPath, boatId, 'calendar'], {queryParams});
  }

  shortcutNavigate(boatId: number, config: ShortcutNavLink): Promise<boolean> {
    return this.router.navigate([...this.boatPath, boatId, ...config?.url], {queryParams: config?.params});
  }

  shortcutCoursesNavigate(config: ShortcutNavLink): Promise<boolean> {
    return this.router.navigate([...config?.url], {queryParams: config?.params});
  }

  shortcutCharterNavigate(boatId: number, config: ShortcutNavLink, charter: ShortcutPageCharter): Promise<boolean> {
    return this.router.navigate([...this.boatPath, boatId, 'charters', 'list', charter.id, ...config?.url],
      {queryParams: config?.params});
  }

  editProfile(): Promise<boolean> {
    return this.navigate(['/', 'profile', 'edit', 'general']);
  }

  navigateToEditUserCv(param = 'general', queryParams: Params = {}): Promise<boolean> {
    return this.router.navigate(['/', 'cv', 'edit', param], {queryParams});
  }

  userProfile(param: 'general' | 'contract' = 'general'): Promise<boolean> {
    return this.navigate(['/', 'profile', param]);
  }

  navigateToUserCv(): Promise<boolean> {
    return this.router.navigate(['/', 'cv']);
  }

  navigateToSearchByType(boatId: string, type: BoatSearchRoutes = BoatSearchRoutes.Crew) {
    return this.router.navigate([...this.boatPath, boatId, 'search', type]);
  }

  navigateToCommonSearch() {
    return this.router.navigate(['suppliers']);
  }

  navigateToSearchCrew(boatId: string, params: Params = {}) {
    return this.router.navigate([...this.boatPath, boatId, 'search', BoatSearchRoutes.Crew, 'all'], {
      queryParams: params,
      queryParamsHandling: 'merge',
    });
  }

  navigateToPublishOffers(boatId: string): Promise<boolean> {
    return this.navigate([...this.boatPath, boatId, 'search', BoatSearchRoutes.Offers, 'published']);
  }

  navigateToUnpublishOffers(boatId: string): Promise<boolean> {
    return this.navigate([...this.boatPath, boatId, 'search', 'offers', 'unpublished']);
  }

  navigateToOffer(boatId: string, offerId: string, type: JobOffersReadyType = 'applied', params: Params = {}) {
    return this.router.navigate(
      [...this.boatPath, boatId, 'search', 'job-offer', offerId, type], {queryParams: params},
    );
  }

  toSearchUserProfile(boatId: string, userId: string, jobOfferInvitationId: string | null = null): Promise<boolean> {
    return this.router.navigate(
      [...this.boatPath, boatId, 'search', 'profile'],
      {
        queryParams: {userId, jobOfferInvitationId},
      },
    );
  }

  toSeeSearchUserContract(boatId: number, contractId: number): Promise<boolean> {
    return this.router.navigate(
      [...this.boatPath, boatId, 'search', 'see-contract', contractId],
    );
  }

  navigateToAddCrewByInvitationId(boatId: string, params = {}) {
    return this.router.navigate(
      [...this.boatPath, boatId, 'search', 'contract'], {queryParams: params});
  }

  navigateToAddCrewByUserId(boatId: string, params = {}) {
    return this.router.navigate(
      [...this.boatPath, boatId, 'crew', 'add-contract'], {queryParams: params});
  }

  navigateToStartPageByRole(
    isConnectedToBoats = false,
    needRedirectToCv = false,
    redirectToSupplier: SupplierRedirectType = false,
    oneBoatId: number | null = null,
  ): Promise<boolean> {
    const user = this.appStateService.user;
    if (!user) {
      this.appStateService.clearUserData();
      return this.navigateToLogin();
    }
    const fleet = this.appStateService.fleet;
    const roleType = user.roleType;
    const isAdditionalGroup = this.rolesService.isAdditionalGroup(roleType);
    const isManager = this.rolesService.isManager(roleType);
    const isManagerWithDefault = isManager && fleet?.defaultFleetId;
    const isManagerWithFleet = isManager && fleet?.hasFleet;
    const isCaptain = roleType === 'role_boat_captain';
    const isCaptainWithBoat = isCaptain && isConnectedToBoats;
    const isCrewOnBoat = roleType === 'role_crew' && isConnectedToBoats;
    const isOnOneBoat = (isCrewOnBoat || isCaptainWithBoat) && oneBoatId;
    const extras: NavigationExtras = AUTHORIZED_NAVIGATION_EXTRAS;

    if (isOnOneBoat) {
      return this.navigateToBoatDashboard(oneBoatId);
    }
    if (isManagerWithDefault) {
      return this.navigateToFleet(fleet?.defaultFleetId);
    }
    if (isManagerWithFleet) {
      return this.navigateToMyFleets();
    }
    if (isAdditionalGroup || isCaptainWithBoat || isCrewOnBoat) {
      return this.navigateToBoatsList(extras);
    }
    if (redirectToSupplier) {
      return this.navigateToSupplierEditByName(redirectToSupplier, {mode: 'add'});
    }
    const isSupplier = roleType === 'role_supplier';
    if (isSupplier) {
      return this.navigateToSupplier();
    }
    return this.navigateToCrewGroup(needRedirectToCv, isCaptain, extras);
  }

  private navigateToCrewGroup(needRedirectToCv: boolean, isCaptain: boolean, extras: NavigationExtras): Promise<boolean> {
    if (isCaptain) {
      return this.navigateToUserDashboard({...extras, queryParams: {chooseCaptainType: ConditionalStatus.YES}});
    }
    if (needRedirectToCv) {
      return this.navigateToEditUserCv('general', {greeting: ConditionalStatus.YES});
    }
    return this.navigateToUserDashboardAfterAuth(extras);
  }

  navigateToUserDashboard(extras: NavigationExtras = {}): Promise<boolean> {
    return this.router.navigate(['/', 'dashboard'], extras);
  }

  navigateToUserDashboardAfterAuth(extras: NavigationExtras = {}): Promise<boolean> {
    const queryParams = {checkCv: 'check'};
    return this.router.navigate(['/', 'dashboard'], {
      ...extras,
      queryParams: {...extras.queryParams, ...queryParams},
    });
  }

  navigateToJobSearch(): Promise<boolean> {
    return this.navigate(['/', 'search', 'job-search']);
  }

  navigateToAppliedJobSearch(): Promise<boolean> {
    return this.navigate(['/', 'search', 'job-search', 'my-offers', 'applied']);
  }

  navigateToJobSearchOffer(queryParams: Params): Promise<boolean> {
    return this.router.navigate(['/', 'search', 'job-search', 'job-offer'], {queryParams});
  }

  navigateToJobSearchBoat(queryParams: Params): Promise<boolean> {
    return this.router.navigate(['/', 'search', 'job-search', 'boat'], {queryParams});
  }

  changePassword(): Promise<boolean> {
    return this.navigate(['/', 'change-password']);
  }

  navigateToContract(contractId: number): Promise<boolean> {
    return this.navigate(['/', 'contracts', `${contractId}`]);
  }

  addBudget(boatId: number): Promise<boolean> {
    return this.navigate([...this.boatPath, `${boatId}`, 'financial', 'budget', 'new']);
  }

  navigateToBudgetGeneral(boatId: number, params: Params = {}): Promise<boolean> {
    return this.router.navigate([...this.boatPath, `${boatId}`, 'financial', 'budget', 'general'], {queryParams: params});
  }

  navigateToAdditionalFieldsSettings(boatId: number): Promise<boolean> {
    return this.router.navigate([...this.boatPath, `${boatId}`, 'profile', 'settings', 'fields']);
  }

  navigateToUserSettings(boatId: number, userId: number, settingsName: string): Promise<boolean> {
    return this.router.navigate([...this.boatPath, `${boatId}`, 'profile', 'settings', 'user', `${userId}`, `${settingsName}`]);
  }

  navigateToUserManagement(boatId: string): Promise<boolean> {
    return this.router.navigate([...this.boatPath, `${boatId}`, 'profile', 'settings', 'users']);
  }

  navigateToUserSubscription(boatId: number): Promise<boolean> {
    return this.router.navigate([...this.boatPath, `${boatId}`, 'profile', 'settings', 'subscription']);
  }

  navigateToCrewDocuments(boatId: number, crewId: number): Promise<boolean> {
    return this.router.navigate([...this.boatPath, `${boatId}`, 'crew', 'crew-documents', `${crewId}`]);
  }

  navigateToShareCrewCv(token: string): Promise<boolean> {
    return this.router.navigate(['/', 'view-user-cv'], {queryParams: {token}});
  }

  chartInfo(
    boatId: number,
    params: ReportChartParams,
  ): Promise<boolean> {
    const queryParams = cleanObject(params);
    return this.router.navigate(['boat-details', `${boatId}`, 'financial', 'report', 'info'], {queryParams});
  }

  goBack(): void {
    if (window && window.history) {
      window.history.back();
    }
  }

  getActivatedChildUrl(snapshot: ActivatedRouteSnapshot, arr: string[] = []): string[] {
    const child = snapshot.firstChild;
    if (!child) {
      return arr;
    }
    const config = child.routeConfig;
    const path = config && config.path;
    if (!!path) {
      arr.push(path);
    }
    return this.getActivatedChildUrl(child, arr);
  }

  navigateToCharterList(boatId: number, queryParams: CharterFilterParams = {}): void {
    this.router.navigate([...this.boatPath, boatId, 'charters', 'list'], {queryParams});
  }

  navigateToAddTrip(boatId: number, tripId: number, params: Params = {returnUrl: null}): void {
    this.router.navigate([...this.boatPath, boatId, 'charters', 'list', tripId, 'add', 'trip', 'trip-details'], {queryParams: params});
  }

  navigateToAddCharter(boatId: number, charterId: number, params: Params = {returnUrl: null}): void {
    this.router.navigate(
      [...this.boatPath, boatId, 'charters', 'list', charterId, 'add', 'charter', 'charter-details'],
      {queryParams: params},
    );
  }

  navigateToCharterData(charterId: number, boatId: number): void {
    this.router.navigate([...this.boatPath, boatId, 'charters', 'list', charterId, 'charter-data']);
  }

  navigateToEditCharter(
    boatId: number,
    charterId: number,
    pageType: AddEditCharterPages = 'charter-details',
    queryParams: CharterPaymentDetailsQuery = {},
  ): void {
    this.router.navigate(
      [...this.boatPath, boatId, 'charters', 'list', charterId, 'edit', 'charter', pageType],
      {queryParams},
    );
  }

  navigateToEditTrip(boatId: number, tripId: number, pageType: AddEditTripPages = 'trip-details'): void {
    this.router.navigate([...this.boatPath, boatId, 'charters', 'list', tripId, 'edit', 'trip', pageType]);
  }

  navigateToCharter(boatId: number, charterId: number, type: CharterPageRoutes = CharterPageRoutes.CharterData): void {
    this.router.navigate([...this.boatPath, boatId, 'charters', 'list', charterId, type]);
  }

  navigateToAddCharterApa(boatId: number, charterId: number, queryParams: AddApaQueryParams = {returnUrl: null}): void {
    this.router.navigate([...this.boatPath, boatId, 'charters', 'list', charterId, 'add-apa'], {queryParams});
  }

  navigateToApaReport(boatId: number, charterId: number, reportRoute: ApaViewRoute = ApaViewRoute.Yacht): void {
    this.router.navigate([...this.boatPath, boatId, 'charters', 'list', charterId, CharterPageRoutes.ApaReport, reportRoute]);
  }

  navigateToApaReportInfo(boatId: number, charterId: number, queryParams: ApaFinancialInfoParams): void {
    this.router.navigate([...this.boatPath, boatId, 'charters', 'list', charterId, 'apa-info'], {queryParams});
  }

  navigateSharedToApaReport(route: ShareApaReportRoutes, token: string): void {
    this.router.navigate([route, token]);
  }

  navigateToChartersCalendar(boatId: number): void {
    this.router.navigate([...this.boatPath, boatId, 'charters', 'calendar']);
  }

  navigateToCompanyRegistration(): Promise<boolean> {
    return this.router.navigate([...this.authCompanyPath, 'registration']);
  }

  navigateToSuccessCompanyRegistration(): Promise<boolean> {
    return this.router.navigate([...this.authCompanyPath, 'success']);
  }

  navigateToCompanyUserRegistration(companyId: number): Promise<boolean> {
    return this.router.navigate([...this.authCompanyPath, 'registration', companyId]);
  }

  navigateToSuccessCompanyUserRegistration(): Promise<boolean> {
    return this.navigate([...this.authCompanyPath, 'check', 'inbox']);
  }

  navigateToSupplierClaim(token: string, provider: RegistrationProvider | null): Promise<boolean> {
    return this.navigate(['auth', 'registration', 'claim-supplier', token], {queryParams: {provider}});
  }

  navigateToDownloads(): Promise<boolean> {
    return this.navigate(['/', 'downloads']);
  }

  navigateToOutstandingPayment(boatId: number): Promise<boolean> {
    return this.router.navigate([...this.boatPath, boatId, 'financial', 'outstanding-payments']);
  }

  navigateToNotFound(): Promise<boolean> {
    return this.router.navigate(['/not-found']);
  }

  navigateToMyFleets(): Promise<boolean> {
    return this.router.navigate(['/fleet-management', 'my-fleets']);
  }

  /**
   * navigate to fleet
   *
   * @param fleetId - fleet id
   * @param page - fleet child page
   * @param child - sub route of child page, use bitwise  | for set sub child route type
   */
  navigateToFleet(
    fleetId: number,
    page: FleetRoutes = FleetRoutes.Dashboard,
    child?: FleetContactsRoutes,
  ): Promise<boolean> {
    if (child) {
      return this.router.navigate(['/fleet-management', fleetId, page, child]);
    }
    return this.router.navigate(['/fleet-management', fleetId, page]);
  }

  navigateToFleetGuest(guestId: number, fleetId: number): Promise<boolean> {
    return this.router.navigate(
      ['/fleet-management', 'guest', guestId],
      {queryParams: {fleetId}},
    );
  }

  navigateToFleetDocuments(fleetId: number, queryParams: Params = {}): Promise<boolean> {
    return this.router.navigate(
      ['/', 'fleet-management', fleetId, 'documents'],
      {queryParams},
    );
  }

  navigateToBoatMaintenanceSystems(
    boatId: number,
    params: Partial<EquipmentFilters> = {},
  ): Promise<boolean> {
    return this.router.navigate(
      [...this.boatPath, boatId, 'maintenance', BoatMaintenanceRoutes.Systems],
      {queryParams: params},
    );
  }

  navigateToBoatMaintenanceSearch(boatId: number, search: string): Promise<boolean> {
    return this.router.navigate(
      [...this.boatPath, boatId, 'maintenance', BoatMaintenanceRoutes.Systems, 'search'],
      {queryParams: {search}},
    );
  }

  navigateToBoatMaintenanceUpdateHours(boatId: number): Promise<boolean> {
    return this.router.navigate(
      [...this.boatPath, boatId, 'maintenance', BoatMaintenanceRoutes.Systems, BoatMaintenanceRoutes.Mileage],
    );
  }

  navigateToSystemById(boatId: number,
    system: number,
    subRoute: BoatMaintenanceSystemItemRoutes = BoatMaintenanceSystemItemRoutes.List)
    : Promise<boolean> {
    return this.router.navigate(
      [...this.boatPath, boatId, 'maintenance', BoatMaintenanceRoutes.Systems, system, subRoute],
    );
  }

  navigateToSystemTasksById(
    boatId: number,
    system: number,
    queryParams: Partial<BoatMaintenanceTasksLogPayload> = {statuses: [TaskStatusType.Open]},
  ): Promise<boolean> {
    return this.router.navigate(
      [...this.boatPath, boatId, 'maintenance', BoatMaintenanceRoutes.Systems, system, BoatMaintenanceSystemItemRoutes.Tasks],
      {queryParams},
    );
  }

  navigateToSystemTasks(boatId: number, systemId: number): Promise<boolean> {
    return this.router.navigate([...this.boatPath, boatId, 'maintenance', BoatMaintenanceRoutes.Systems, systemId]);
  }

  navigateToTasks(boatId: number, addTask = false): Promise<boolean> {
    return this.router.navigate([...this.boatPath, boatId, 'maintenance', BoatMaintenanceRoutes.Tasks], {
      queryParams: {
        ...TASKS_DEFAULT_FILTERS,
        addTask: addTask ? 1 : null,
      },
    });
  }

  navigateToTasksLog(boatId: number, queryParams: TaskLogRouteParams): Promise<boolean> {
    return this.router.navigate(
      [...this.boatPath, boatId, 'maintenance', BoatMaintenanceRoutes.TasksLog],
      {queryParams},
    );
  }

  navigateToTasksLogInfo(boatId: number, queryParams: TaskLogInfoRouteParams = {}): Promise<boolean> {
    return this.router.navigate(
      [...this.boatPath, boatId, 'maintenance', BoatMaintenanceRoutes.TasksLogInfo],
      {queryParams},
    );
  }

  navigateToMaintenanceDashboard(boatId: number, queryParams: Params = {}): Promise<boolean> {
    return this.router.navigate(
      [...this.boatPath, boatId, 'maintenance', BoatMaintenanceRoutes.Dashboard],
      {queryParams},
    );
  }

  navigateToMaintenancePage(boatId: number, route: BoatMaintenanceRoutes, queryParams: Params = {}): Promise<boolean> {
    return this.router.navigate(
      [...this.boatPath, boatId, 'maintenance', route],
      {queryParams},
    );
  }

  navigateToChecklistFolder(
    boatId: number,
    folderId: number,
    path: BoatMaintenanceChecklistsRoutes = BoatMaintenanceChecklistsRoutes.Checklists,
  ): Promise<boolean> {
    return this.router.navigate([
      ...this.boatPath,
      boatId,
      'maintenance',
      BoatMaintenanceRoutes.CheckLists,
      folderId,
      path,
    ]);
  }

  navigateToChecklist(boatId: number, folderId: number, checkListId: number): Promise<boolean> {
    return this.router.navigate([
      ...this.boatPath,
      boatId,
      'maintenance',
      BoatMaintenanceRoutes.CheckLists,
      folderId,
      BoatMaintenanceChecklistsRoutes.Checklists,
      checkListId,
    ]);
  }

  navigateToChecklistTemplate(boatId: number, folderId: number, templateId: number): Promise<boolean> {
    return this.router.navigate([
      ...this.boatPath,
      boatId,
      'maintenance',
      BoatMaintenanceRoutes.CheckLists,
      folderId,
      BoatMaintenanceChecklistsRoutes.Templates,
      templateId,
    ]);
  }

  navigateToNewChecklist(boatId: number, folderId: number, queryParams: Params): Promise<boolean> {
    return this.router.navigate([
      ...this.boatPath,
      boatId,
      'maintenance',
      BoatMaintenanceRoutes.CheckLists,
      folderId,
      BoatMaintenanceChecklistsRoutes.Checklists,
      'new',
    ], {queryParams});
  }

  navigateToParenRoute(route: ActivatedRoute): Promise<boolean> {
    return this.router.navigate(['..'], {
      relativeTo: route,
    });
  }
}
