import {MixedControlState} from '@controls/seazone-checkbox';

import {UserFleetPermission} from './boats-fleet-permission';

import {
  BoatFleetUserPermissionItemDto,
  BoatFleetUserPermissionsDto,
  DisableUserFleetPermissionDto,
} from '../directories';
import {PaginationDto} from '../response';

export interface BoatsFleetUsersDto {
  readonly models: BoatsFleetUserModelDto[];
  readonly pagination: PaginationDto;
}

export interface BoatsFleetUserModelDto {
  readonly user: BoatsFleetUserDto;
  readonly invitation: number | null;
}

export interface BoatsFleetUser {
  readonly id: number;
  readonly username: string;
  readonly position: string;
  readonly email: string;
}

export interface BoatsFleetUserDto extends BoatsFleetUser {
  readonly image: string;
  readonly isAdmin: boolean;
}

export class BoatFleetUserInfoDto {
  constructor(
    public readonly fleetUserId: number,
    public readonly user: BoatsFleetUser,
    public readonly permissions: UserFleetPermission[],
  ) {
  }
}

export class BoatsFleetUsersPayload {
  constructor(
    public readonly fleet: number,
    public readonly page: number,
    public readonly limit: number,
  ) {
  }
}

export interface BoatFleetUserManageForm {
  readonly id: number;
  readonly email: string;
  readonly position: string;
  readonly permissions: BoatFleetUserManagePermissionsDto[];
  readonly all: boolean;
}

export class BoatFleetUserManageDirectories {
  constructor(
    public readonly permissions: BoatFleetUserManagePermissionsDto[],
    public readonly user: BoatFleetUserInfoDto | null,
  ) {
  }
}

export class BoatFleetUserManagePermissionsDto implements BoatFleetUserPermissionsDto {
  constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly permissions: BoatFleetUserManagePermissionItemDto[],
    public hidden: boolean = false,
    public readonly all: MixedControlState = 'false',
    public readonly check = false,
  ) {
  }
}

export class BoatFleetUserManagePermissionItemDto implements BoatFleetUserPermissionItemDto {
  constructor(
    public readonly id: number,
    public readonly name: UserFleetPermission,
    public readonly description: string,
    public hidden: boolean = false,
    public readonly relatedToPermission: UserFleetPermission[] | null = null,
    public readonly disablePermissions: DisableUserFleetPermissionDto | null = null,
    public readonly priority: number | null = null,
    public check = false,
  ) {
  }
}

export class BoatFleetUserPermissionPayload {
  readonly permissions: UserFleetPermission[];

  constructor(
    permissions: BoatFleetUserManagePermissionsDto[],
  ) {
    this.permissions = permissions.reduce((acc: UserFleetPermission[], perm: BoatFleetUserManagePermissionsDto) => [...acc, ...perm?.permissions.filter(item => item.check)?.map(per => per?.name)], []);
  }
}

export class BoatFleetUserAddPayload extends BoatFleetUserPermissionPayload {
  readonly fleet: number;
  readonly email: string;
  readonly position: string;
  readonly permissions: UserFleetPermission[];

  constructor(
    form: BoatFleetUserManageForm,
    fleet: number,
  ) {
    super(form.permissions);
    this.fleet = fleet;
    this.email = form.email;
    this.position = form.position;
  }
}

export class BoatFleetUserEditPayload extends BoatFleetUserPermissionPayload {
  readonly fleetUser: number;
  readonly email: string;
  readonly position: string;
  readonly permissions: UserFleetPermission[];

  constructor(
    form: BoatFleetUserManageForm,
  ) {
    super(form.permissions);
    this.fleetUser = form.id;
    this.email = form.email;
    this.position = form.position;
  }
}

export class BoatFleetUserPayload {
  constructor(
    public readonly fleet: number,
    public readonly user: number,
  ) {
  }
}

export class BoatFleetUserDeletePayload extends BoatFleetUserPayload {
  constructor(
    fleet: number,
    user: number,
  ) {
    super(fleet, user);
  }
}
