import {BoatDocumentDto} from '../boat-documents';
import {PaginationDto} from '../response';

export enum FleetDocumentsType {
  AllDocuments = 1,
  ExpiringSoon,
  Expired
}

export interface FleetDocumentsFilters {
  readonly search?: string;
  readonly types?: FleetDocumentsType[];
  readonly page?: number;
}

export class FleetDocumentsPayload {
  constructor(
    public readonly fleet: number,
    public readonly search: string,
    public readonly filter: FleetDocumentsType[],
    public readonly page: number,
  ) {
  }
}

export interface FleetBoatDto {
  readonly id: number;
  readonly boatType: string;
  readonly name: string;
  readonly flag: string;
  readonly boatLength: number;
}

export interface FleetDocumentsCountsDto {
  readonly expiringSoon: number;
  readonly expired: number;
}

export interface FleetDocumentsModelDto {
  readonly boat: FleetBoatDto;
  readonly counts: FleetDocumentsCountsDto;
  readonly documents: BoatDocumentDto[];
}

export interface FleetDocumentsDto {
  readonly models: FleetDocumentsModelDto[];
  readonly pagination: PaginationDto;
}
