export class FleetDocumentsAlertsDto {
  constructor(
    public readonly documentAlerts: DocumentAlertsDto,
    public readonly financeAlerts: FinanceAlertsDto,
  ) {
  }
}

export class DocumentAlertsDto {
  constructor(
    public readonly expiredSoon: number,
    public readonly expired: number,
  ) {
  }
}

export class FinanceAlertsDto {
  constructor(
    public readonly negativePettyCash: number,
    public readonly expensesTenDays: number,
    public readonly newOutstandingPayment: number,
  ) {
  }
}

export class FleetDocumentsAlertsPayload {
  constructor(
    public fleet: number,
  ) {
  }
}
