import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {NumberPipe} from '@pipes/number-pipe';
import Chart from 'chart.js';
import ChartDataLabels, {Context} from 'chartjs-plugin-datalabels';

import {PlatformBrowserService} from '@core/modules';
import {htmlDecode} from '@helpers/html-decode';

import {ChartManager} from '../../classes/chart-manager/chart-manager';

export class PieChartOutsideLabel {

  readonly segmentOffset = this.isSmallChart ? 10 : 20;
  readonly fontSize = this.font;

  private readonly additionalIndent = this.isSmallChart ? this.fontSize : 0;

  get color(): string {
    return this.model.backgroundColor;
  }

  get centreAngle(): number {
    const view = this.view;
    return view.startAngle + ((view.endAngle - view.startAngle) / 2);
  }

  get innerRadius(): number {
    return this.view.outerRadius / 2;
  }

  get offset(): number {
    return this.fontSize + 2 + this.segmentOffset;
  }

  get rangeFromCentre(): number {
    return (this.view.outerRadius - this.innerRadius) + this.innerRadius + this.offset;
  }

  get legendPositionX(): number {
    return this.legend.left;
  }

  position: { x: number, y: number } = {
    x: this.view.x + (Math.cos(this.centreAngle) * this.rangeFromCentre),
    y: this.view.y + (Math.sin(this.centreAngle) * this.rangeFromCentre) + this.additionalIndent - this.fontSize * 2,
  };

  start: { x: number, y: number } = {
    x: this.view.x + (Math.cos(this.centreAngle) * this.view.outerRadius),
    y: this.view.y + (Math.sin(this.centreAngle) * this.view.outerRadius),
  };

  rad = this.rangeFromCentre - this.segmentOffset / 2;

  end: { x: number, y: number } = {
    x: this.view.x + (Math.cos(this.centreAngle) * this.rad),
    y: this.view.y + (Math.sin(this.centreAngle) * this.rad) + 24 - this.fontSize * 2,
  };

  title = this.model.label;

  dividedTitle: string[] = this.title.split(' ').reduce((acc, val, index) => {
    if (index === 0) {
      acc.push(val);
      return [...acc];
    }
    const previous = acc[acc.length - 1];
    const text = `${previous} ${val}`;
    const legendPosition = this.legendPositionX - 32;
    const endX = this.end.x;
    const chartWidth = this.width;
    const textWidth = this.measureText(text).actualBoundingBoxRight;
    const isAcceptWidth = endX + textWidth <= (legendPosition === 0 ? chartWidth : legendPosition);
    if (isAcceptWidth) {
      acc[acc.length - 1] = text;
    } else {
      acc.push(val);
    }
    return [...acc];
  }, []);

  get maxTitleLength(): number {
    const maxTitle = this.dividedTitle.reduce((acc, val) => val.length > acc.length ? val : acc);
    return this.measureText(maxTitle).actualBoundingBoxRight;
  }

  constructor(
    private readonly ctx: CanvasRenderingContext2D,
    public readonly value: string,
    private readonly model: any,
    private readonly view: any,
    private readonly legend: any,
    private readonly width: any,
    private readonly font: number,
    private readonly isSmallChart: boolean,
  ) {
  }

  measureText(text: string | any): any {
    if (typeof text === 'object') {
      return {
        width: text.width,
        height: text.height,
      };
    }
    return this.ctx.measureText(text);
  }

}

export class PieLabelPlugin {

  constructor(
    private readonly numberPipe: NumberPipe,
    private readonly currency: string,
    private readonly fontSize: number,
    private readonly isSmallChart = false,
  ) {
  }

  beforeDraw(chart: Chart.ChartPluginsOptions): void {
    const ctx = chart.chart.ctx;
    const chartData = chart.data.datasets[0].data;
    const meta = chart.getDatasetMeta(0);
    if (!meta) {
      return;
    }
    const array: (PieChartOutsideLabel | null)[] = meta.data.map((context, index) => {
      const model = context._model;
      const view = context._view;
      if (model.circumference === 0 || model.circumference > 0.45) {
        return null;
      }
      return new PieChartOutsideLabel(
        ctx,
        `${this.currency} ${this.numberPipe.transform(chartData[index])}`,
        model,
        view,
        chart.legend,
        chart.width,
        this.fontSize,
        this.isSmallChart,
      );
    });

    array.forEach((value, index) => {
      if (!value) {
        return;
      }
      const currentYPosition = value.position.y;
      const lineHeight = value.fontSize;
      const lines = value.dividedTitle.length;
      value.end.y = value.end.y + (lines * lineHeight);
      const currentYEnd = value.end.y;
      const nextValue = array[index + 1];
      if (!nextValue) {
        return;
      }
      const nextYPosition = nextValue && nextValue.position.y || currentYPosition;
      const nextDif = nextYPosition - currentYPosition;
      if (nextDif > 50) {
        return;
      }
      nextValue.position.y = currentYEnd;
      nextValue.end.y = currentYEnd + lineHeight + 8;
    });

    array.forEach((value) => {
      if (!value) {
        return;
      }
      ctx.font = `bold ${value.fontSize}px Roboto, sans-serif`;
      ctx.strokeStyle = 'black';
      ctx.fillStyle = value.color;
      // Draw line
      ctx.beginPath();
      ctx.moveTo(value.start.x, value.start.y);
      ctx.lineWidth = 2;
      ctx.lineTo(value.end.x, value.end.y);
      const titleMetrics = value.measureText(value.title);
      const valueMetrics = value.measureText(value.value);
      const metrics = titleMetrics > valueMetrics ? titleMetrics : valueMetrics;
      if (value.start.x < value.end.x) {
        value.position.x = value.position.x + 10;
        value.end.x = value.position.x - metrics.width / 2 - 1;
      } else {
        value.position.x = value.position.x - 10;
        value.end.x = value.position.x + metrics.width / 2 + 1;
      }
      value.end.x = value.position.x + value.maxTitleLength;
      ctx.strokeStyle = value.color;
      ctx.lineTo(value.end.x, value.end.y);
      value.dividedTitle.forEach((item) => {
        value.position.y = value.position.y + value.fontSize;
        ctx.fillText(item, value.position.x - 14, value.position.y);
      });
      ctx.fillText(value.value, value.position.x - 14, value.position.y + value.fontSize);
      ctx.stroke();
    });
    ctx.restore();
  }
}

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
  providers: [NumberPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PieChartComponent extends ChartManager implements OnInit {

  private get chartPadding(): any {
    if (this.isSmallChart) {
      return {top: 7, right: 15};
    }
    return this.smallViewport ? {top: 50, padding: 50, left: 0, right: 0} : 50;
  }

  private get chartLegendPosition(): 'right' | 'bottom' {
    return this.smallViewport ? 'bottom' : 'right';
  }

  @Input() displayLegend = true;
  @Input() height = '550px';
  @Input() minWidth = '750px';
  @Input() fontSize = 14;
  @Input() isSmallChart = false;

  @ViewChild('pieChartContainer', {static: true}) pieChartContainer: ElementRef;

  constructor(
    protected readonly browserService: PlatformBrowserService,
    protected readonly numberPipe: NumberPipe,
  ) {
    super(browserService, numberPipe, 'pie');
  }

  ngOnInit() {
  }

  @HostListener('window:resize')
  onResize() {
    const chart = this.chart;
    if (!chart) {
      return;
    }
    this.chart.clear();
    const options = chart.config && chart.config.options;
    if (!options) {
      return;
    }
    const legend = options.legend;
    const layout = options.layout;
    if (!legend || !layout) {
      return;
    }
    legend.position = this.chartLegendPosition;
    layout.padding = this.chartPadding;
    this.chart.update();
    this.chartRef.nativeElement.scrollTo({left: 250});
    this.scrollToCenter();
  }

  private scrollToCenter(): void {
    const ref = this.pieChartContainer;
    const width = this.windowWidth;
    if (!this.smallViewport || !ref || !ref.nativeElement || !width) {
      return;
    }
    const halfWidth = width / 5;
    if (halfWidth) {
      ref.nativeElement.scrollLeft = halfWidth;
    }
  }

  protected drawChart(): void {
    if (!this.browserService.isBrowser || !this.currencyBadge) {
      return;
    }
    const ctx = this.chartRef.nativeElement.getContext('2d');
    const gradientStroke = ctx.createLinearGradient(100, 100, 400, 400);
    gradientStroke.addColorStop(0, '#46A6A4');
    gradientStroke.addColorStop(1, 'rgba(70, 166, 164, 0.42)');
    // @ts-ignore
    this.chart = new Chart(ctx, {
      type: 'doughnut',
      plugins: [
        ChartDataLabels,
        new PieLabelPlugin(this.numberPipe, htmlDecode(this.currencyBadge || ''), this.fontSize, this.isSmallChart),
      ],
      data: {
        datasets: [
          {
            data: this.values,
            backgroundColor: this.colors,
            borderColor: '#ffffff',
            borderWidth: 1,
            pointBackgroundColor: '#46A6A4',
          },
        ],
        labels: this.labels,
      },
      // @ts-ignore
      options: {
        cutoutPercentage: 40,
        rotation: this.isSmallChart ? -0.3 * Math.PI : 0.05 * Math.PI,
        layout: {
          padding: this.chartPadding,
        },
        legend: {
          display: this.displayLegend,
          position: this.chartLegendPosition,
          fullWidth: false,
          labels: {
            boxWidth: 16,
            fontSize: 16,
            fontFamily: 'Roboto',
            fontColor: '#4D4E67',
            usePointStyle: true,
          },
          onClick(event) {
            return;
          },
        },
        plugins: {
          datalabels: {
            formatter: (value, context: Context) => {
              const index = context.dataIndex;
              const datasetIndex = context.datasetIndex;
              const meta = context.chart.getDatasetMeta(datasetIndex);
              const item = meta.data[index];
              // @ts-ignore
              const label = item._model.label;
              return `${label}\n${this.addCurrency(value)}`;
            },
            // @ts-ignore
            font: {
              size: this.fontSize,
              family: 'Roboto',
              // @ts-ignore
              style: 'bold',
            },
            anchor: (context: Context) => {
              const index = context.dataIndex;
              const datasetIndex = context.datasetIndex;
              const meta = context.chart.getDatasetMeta(datasetIndex);
              const model = meta.data[index]._model;
              // @ts-ignore
              return model.circumference < 0.45 ? 'end' : 'center';
            },
            offset: (context: Context) => {
              const index = context.dataIndex;
              const datasetIndex = context.datasetIndex;
              const meta = context.chart.getDatasetMeta(datasetIndex);
              const model = meta.data[index]._model;
              // @ts-ignore
              return model.circumference < 0.45 ? 10 : 4;
            },
            align: (context: Context) => {
              const index = context.dataIndex;
              const datasetIndex = context.datasetIndex;
              const meta = context.chart.getDatasetMeta(datasetIndex);
              const model = meta.data[index]._model;
              // @ts-ignore
              return model.circumference < 0.45 ? 'end' : 'center';
            },
            color: (context: Context) => {
              const index = context.dataIndex;
              const datasetIndex = context.datasetIndex;
              const meta = context.chart.getDatasetMeta(datasetIndex);
              const model = meta.data[index]._model;
              const color = model.backgroundColor;
              // @ts-ignore
              return model.circumference < 0.45 ? color : '#ffffff';
            },
            // display: 'auto',
            display: (context: Context) => {
              const index = context.dataIndex;
              const datasetIndex = context.datasetIndex;
              const meta = context.chart.getDatasetMeta(datasetIndex);
              const model = meta.data[index]._model;
              // @ts-ignore
              return model.circumference > 0.45;
            },
            textAlign: 'center',
          },
        },
        tooltips: {
          enabled: true,
          callbacks: {
            // @ts-ignore
            label: (tooltipItem, data) => {
              const index = tooltipItem.index;
              // @ts-ignore
              return data.labels[index];
            },
            afterLabel: (tooltipItem, data) => {
              const datasetIndex = tooltipItem.datasetIndex;
              const index = tooltipItem.index;
              // @ts-ignore
              // @ts-ignore
              const value = data.datasets[datasetIndex].data[index] as any;
              return this.addCurrency(value);
            },
          },
          borderColor: 'rgba(0,0,0,0.1)',
          borderWidth: 1,
          xPadding: 9,
          yPadding: 8,
          backgroundColor: '#FFF',
          bodyFontColor: '#351F6F',
          bodyFontSize: 16,
          displayColors: false,
        },
        scales: {
          yAxes: [
            {
              display: false,
            },
          ],
          xAxes: [
            {
              display: false,
            },
          ],
        },
        responsive: true,
        onHover: () => {
          return;
        },
        maintainAspectRatio: false,
      },
    });
    this.scrollToCenter();
  }
}
