export class PaginationDto {
  constructor(
    public readonly totalCount: number,
    public readonly pageCount: number,
    public readonly currentPage: number,
    public readonly commonCount?: number,
  ) {
  }
}

export class PaginationListDto<T> {
  constructor(
    public readonly models: T[],
    public readonly pagination: PaginationDto,
  ) {
  }
}

export interface PaginationPayload {
  readonly limit: number;
  readonly page: number;
}
