import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {BoatCurrencyModule} from '@modules/boat-currency';
import {NumberPipe} from '@pipes/number-pipe';

import {BarChartComponent} from './components/bar-chart/bar-chart.component';
import {BarVerticalChartComponent} from './components/bar-vertical-chart/bar-vertical-chart.component';
import {LineChartComponent} from './components/line-chart/line-chart.component';
import {PieChartComponent} from './components/pie-chart/pie-chart.component';

@NgModule({
  declarations: [BarChartComponent, LineChartComponent, PieChartComponent, BarVerticalChartComponent],
  imports: [
    CommonModule,
    NumberPipe,
    BoatCurrencyModule,
  ],
  exports: [BarChartComponent, LineChartComponent, PieChartComponent, BarVerticalChartComponent],
})
export class ChartModule {
}
