import {Directive, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {translate} from '@ngneat/transloco';

import {BoatDetailsService} from '@services/boat-details/boat-details.service';

import {NotificationService} from '../../../notification';
import {UserPermissions} from '../../models/permissions.model';
import {PermissionsService} from '../../services/permissions/permissions.service';

@Directive({
  selector: '[appPermissionCheck]',
})
export class PermissionCheckDirective {

  @Input() permissions: UserPermissions | UserPermissions[] | null = null;
  @Output() checked = new EventEmitter<Event>();

  constructor(
    private readonly boatDetailsService: BoatDetailsService,
    private readonly permissionsService: PermissionsService,
    private readonly notificationService: NotificationService,
  ) {
  }

  @HostListener('click', ['$event'])
  onClick(e: Event) {
    const perms = this.permissions;
    if (!perms) {
      this.checked.emit(e);
      return;
    }
    const boatId = this.boatDetailsService.boatId;
    if (!boatId) {
      this.checked.emit(e);
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    const havePermission = this.permissionsService.checkPermissions(boatId, perms);
    if (havePermission) {
      this.checked.emit(e);
      return;
    }
    this.showNoPermissionModal();
  }

  private showNoPermissionModal(): void {
    const msg = translate('errors.noPermissions');
    this.notificationService.error(msg);
  }

}
