import {EquipmentLevel} from '../boat-settings';

export const getChildEquipmentLevel = (level?: EquipmentLevel): EquipmentLevel | null => {
  if (!level) {
    return EquipmentLevel.GROUP;
  }
  if (level === EquipmentLevel.GROUP) {
    return EquipmentLevel.SYSTEM;
  }
  if (level === EquipmentLevel.SYSTEM) {
    return EquipmentLevel.SUB_SYSTEM;
  }
  if (level === EquipmentLevel.SUB_SYSTEM) {
    return EquipmentLevel.PART;
  }
  return null;
};
