import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {TransferHttpService} from '@core/modules/transfer-http/transfer-http.service';
import {httpParamsFromObject} from '@helpers/http-params';
import {
  CharterFinancialDataParams,
  FinancialDataByChartDto,
  FinancialDataDto,
  FinancialDataFilterDto,
} from '@models/financial-data';
import {ResponseDto} from '@models/response';

@Injectable({
  providedIn: 'root',
})
export class FinancialDataService {

  private readonly apiUrl = 'api/boat-finance';

  constructor(private readonly http: TransferHttpService) {
  }

  getFinancialData(payload: FinancialDataFilterDto): Observable<ResponseDto<FinancialDataDto>> {
    return this.getData('get-finance-data-by-filter', payload);
  }

  getFinancialDataByChart(payload: FinancialDataByChartDto): Observable<FinancialDataDto> {
    return this.getData<FinancialDataByChartDto, FinancialDataDto>('get-finance-data-by-chart', payload).pipe(
      map(response => response.data),
    );
  }

  getCharterFinancialData(payload: CharterFinancialDataParams): Observable<any> {
    return this.getData<CharterFinancialDataParams, any>('get-finance-charter-department-pcb', payload).pipe(
      map(response => response.data),
    );
  }

  private getData<T extends object, W>(url: string, payload: T): Observable<ResponseDto<W>> {
    const params = httpParamsFromObject<T>(payload);
    return this.http.get(`${this.apiUrl}/${url}`, {params});
  }

}
